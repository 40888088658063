/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { DateRangePicker } from "rsuite";
import { afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import reportsLineChartData from "../dashboard/data/reportsLineChartData";
import {useTranslation} from "react-i18next";

function AuditTrails() {
  const {t} = useTranslation();
  const { auditTrailcolumns } = data();
  const { predefinedRanges } = reportsLineChartData;
  const [auditTrails, setAuditTrails] = useState([]);
  const [lastMonthDateRange, setLastMonthDateRange] = useState([
    subDays(new Date(), 30).toISOString().slice(0, 10),
    new Date().toISOString().slice(0, 10),
  ]);
  // const [fromDate, setFromDate]= useState(lastMonthDateRange[0]);
  // const [toDate, setToDate ]= useState(lastMonthDateRange[1]);
  const api = useApi();
  const formatDate = (date) => {
    const isoDate = new Date(date).toISOString();
    return isoDate.slice(0, 10); // Extracts only the date part
  };
  const getAuditTrails = (lastMonthDateRange) => {
    if(lastMonthDateRange == null) return;
    setLastMonthDateRange(lastMonthDateRange);
    const data = {
      fromDate : formatDate(lastMonthDateRange[0]),
      toDate : formatDate(lastMonthDateRange[1]),
      userId : ""
    }
    api.post("audit/admin", data)
      .then((response) => {
        if(response.data.error === null) {
          // console.log(response.data)
          const sortedAuditTrails = response.data.auditTrail.sort((a, b) => {
            return new Date(b.act_time) - new Date(a.act_time);
          });
          setAuditTrails(sortedAuditTrails);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('no_permission_message'), { id: "PermissionError" });
          return;
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(lastMonthDateRange !== null ){
      getAuditTrails(lastMonthDateRange);
    }
  },[lastMonthDateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3} textAlign={"center"}>
                <DateRangePicker disabledDate={afterToday()} ranges={predefinedRanges} onChange={(date) => getAuditTrails(date)} format="yyyy-MM-dd" value={Object.keys(lastMonthDateRange).length !== 0 ? [new Date(lastMonthDateRange[0]), new Date(lastMonthDateRange[1])] : []} />
              </MDBox>
            </Grid>
            <DataTable
              table={{ columns : auditTrailcolumns, rows : auditTrails }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              canSearch={true}
              entriesPerPage={true}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AuditTrails;
