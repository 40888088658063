/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import NewUser from "../users/newUser";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import MDButton from "../../../components/MDButton";
import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import PermissionControl from "../../../components/Utils/PermissionControl";
import {useTranslation} from "react-i18next";


function MerchantUsers(props) {
  const {t} = useTranslation();
  const {merchant} = props;
  const { merchatnUsersTablecolumns } = data();

  const [openConfigurator, setOpenConfigurator] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  function updateUserHandler(item) {
    setOpenConfigurator(true);
    setUser(item);
  }

  function handleConfirmDelete(item) {
    return;
  }

  useEffect(() => {
    const modifiedData = merchant.users.map(item => {
      const action = (
          <PermissionControl permission={{ restrict: true, module: "User", subModule: "UpdateMerchantUser" }}>
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => updateUserHandler(item)}>create</Icon>
              {/*<Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => handleConfirmDelete(item)}>delete</Icon>*/}
            </MDBox>
          </PermissionControl>
      );
      return { ...item, action };
    });
    setUsers(modifiedData);
  }, [merchant])

  function addNewUser() {
    setUser({});
    setOpenConfigurator(true);
  }

  return (
      <Grid mt={3} mb={3} padding={2} bgColor="white">
        <Grid container spacing={2} justifyContent="center">
          <Grid container justifyContent="flex-end">
            <PermissionControl permission={{ restrict: true, module: "User", subModule: "CreateMerchantUser" }}>
              <MDButton variant="gradient" onClick={() => addNewUser()} color="info" style={{marginBottom: "12px"}}>
                {t('add_user')}
              </MDButton>
            </PermissionControl>
          </Grid>
          <DataTable
            table={{ columns : merchatnUsersTablecolumns, rows : users }}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
            entriesPerPage={true}
            canSearch={true}
          />
          <NewUser openConfigurator={openConfigurator} setOpenConfigurator={setOpenConfigurator} merchant={merchant} user={user} />
        </Grid>
      </Grid>
  );
}

MerchantUsers.defaultProps = {
  merchant: false
}

MerchantUsers.propTypes = {
  merchant: PropTypes.any.isRequired
}

export default MerchantUsers;
