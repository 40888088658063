/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import MDInput from "../../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import {DatePicker, DateRangePicker} from "rsuite";
import { afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import reportsLineChartData from "../../dashboard/data/reportsLineChartData";
import {FormControl, InputLabel, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";

function TransactionSummary() {
  const { transactionSummarycolumns } = data();
  const { predefinedRanges } = reportsLineChartData;
  const [auditTrails, setAuditTrails] = useState({
    transactionSummary: [],
    totalNumberOfTransactions: 0,
    totalTransactionsAmount: 0
  });
  const [lastMonthDateRange, setLastMonthDateRange] = useState([startOfMonth(addMonths(new Date(), -1)).toISOString().slice(0, 10), endOfMonth(addMonths(new Date(), -1)).toISOString().slice(0, 10)]);
  // const [fromDate, setFromDate]= useState(lastMonthDateRange[0]);
  // const [toDate, setToDate ]= useState(lastMonthDateRange[1]);
  const api = useApi();
  const [selectedCurrency, setSelectedCurrency] = useState("All");
  const formatDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };
  const getTransactionSummary = (lastMonthDateRange) => {
    if(lastMonthDateRange == null) return;
    setLastMonthDateRange(lastMonthDateRange);
    const data = {
      startDate : formatDate(lastMonthDateRange[0]) + ' 00:00:00',
      endDate : formatDate(lastMonthDateRange[1]) + ' 23:59:00'
    }
    api.post("transaction-summary", data)
      .then((response) => {
        if(response.data.data.transactionSummary.length > 0) {
          setAuditTrails(response.data.data);
        } else {
          setAuditTrails(response.data.data);
          toast.error("No Record Found", { id: "NoRecordFound" });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        toast.error("No Record Found", { id: "NoRecordFound" });
      })
  }

  useEffect(() => {
      getTransactionSummary(lastMonthDateRange);
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3} textAlign={"center"}>
                {/*<DatePicker*/}
                {/*    oneTap*/}
                {/*    disabledDate={afterToday()}*/}
                {/*    onChange={(date) => getTransactionSummary(date)}*/}
                {/*    format="MM/dd/yyyy"*/}
                {/*    value={lastMonthDateRange ? lastMonthDateRange : ''}*/}
                {/*/>*/}
                <DateRangePicker
                    disabledDate={afterToday()}
                    ranges={predefinedRanges}
                    onChange={(date) => getTransactionSummary(date)}
                    format="yyyy-MM-dd"
                    value={Object.keys(lastMonthDateRange).length !== 0 ? [new Date(lastMonthDateRange[0]), new Date(lastMonthDateRange[1])] : []}
                />
                <FormControl style={{ marginLeft: '0.75rem' }}>
                  <InputLabel id="currency-select-label">Filter by Currency:</InputLabel>
                    <Select
                        labelId="currency-select-label"
                        id="currency-select"
                        label="Filter by Currency:"
                        value={selectedCurrency}
                        style={{ padding: '0.50rem 4rem' }}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="ZiG">ZiG</MenuItem>
                    </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <DataTable
                table={{
                  columns: transactionSummarycolumns,
                  rows: auditTrails.transactionSummary.filter((row) =>
                      selectedCurrency === "All" ? true : row.currency === selectedCurrency
                  ),
                }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              canSearch={true}
              entriesPerPage={true}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TransactionSummary;
