/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDInput from "../../../components/MDInput";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useRef, useState } from "react";
import useApi from "../../../components/Utils/api";
import MDButton from "../../../components/MDButton";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import PermissionControl from "../../../components/Utils/PermissionControl";


function UserLevelParams() {
  const { handleSubmit, control, reset } = useForm();
  const defaultUserLevelParams = {
    usrLvlCode: '',
    sessionTimeout: '',
    refreshTokenTimeout: '',
    passwdChange: '',
    passwdExpiry: '',
    minPasswd: '',
    passwdHistory: '',
    searchRange: '',
    newUserPasswdExp: ''
  };
  const [userLevelParams, setUserLevelParams] = useState(defaultUserLevelParams);
  const formRef = useRef();
  const api = useApi();
  const getUserLevelParams = (userLevel) => {

    const data = {
      usrLvlCode: userLevel
    };
    api.post("userlevel/browse", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          // setUserLevelParams(response.data.body)
          if (response.data.body == null) {
            const newUserLevelParams = {
              usrLvlCode: userLevel,
              sessionTimeout: '',
              refreshTokenTimeout: '',
              passwdChange: '',
              passwdExpiry: '',
              minPasswd: '',
              passwdHistory: '',
              searchRange: '',
              newUserPasswdExp: ''
            };
            reset(newUserLevelParams);
            setUserLevelParams(newUserLevelParams);
          } else {
            reset(response.data.body);
            setUserLevelParams({...response.data.body, usrLvlCode: userLevel});
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
      });
  }

  const saveUserLevelParams = (data) => {
    // form.preventDefault();
    // const formEntries = new FormData(formRef.current).entries();
    // const formDataObject = Object.fromEntries(formEntries);

    api.post("userlevel/update", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setUserLevelParams(response.data.body);
          toast.success("Parameters updated!")
        }
      })
      .catch(error => {
        if(error.response.data.errors.length){
          error.response.data.errors.map((er) => toast.error(er));
          return;
        }
        reset(defaultUserLevelParams);
        setUserLevelParams(defaultUserLevelParams);
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3}>
          <Grid mt={3} mb={3} padding={2} >
            <MDBox component="form" role="form" onSubmit={handleSubmit(saveUserLevelParams)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl fullWidth style={{ height: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Select User Level</InputLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select User Level"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e); // react-hook-form's onChange
                            getUserLevelParams(e.target.value);
                          }}
                          style={{ height: '100%' }}
                        >
                          <MenuItem value="">-Select user level-</MenuItem>
                          {/*<MenuItem value="ADMIN">Admin</MenuItem>*/}
                          <MenuItem value="MAKER">Admin</MenuItem>
                          <MenuItem value="MERCHANT">Merchant</MenuItem>
                          <MenuItem value="REFERRALID">Organization Admin</MenuItem>
                          <MenuItem value="REPORTING_ADMIN">Reporting Admin</MenuItem>
                          <MenuItem value="SETTLEMENT_ADMIN">Settlement Admin</MenuItem>
                          <MenuItem value="ONBOARDING_ADMIN">Onboarding Admin</MenuItem>
                          <MenuItem value="ONBOARDING_REPORTING_ADMIN">Onboarding & Reporting Admin</MenuItem>
                          <MenuItem value="G_MAKER">Guest Admin</MenuItem>
                        </Select>
                      )}
                      name="usrLvlCode"
                      control={control}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                {/* ... (same process for other fields) ... */}

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="Session Time Out (second/s)" fullWidth readOnly />}
                    name="sessionTimeout"
                    control={control}
                    defaultValue={userLevelParams.sessionTimeout}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                      render={({ field }) => <MDInput {...field} type="text" label="Refresh Token Time Out (second/s)" fullWidth readOnly />}
                      name="refreshTokenTimeout"
                      control={control}
                      defaultValue={userLevelParams.refreshTokenTimeout}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl fullWidth style={{ height: '100%' }}>
                    <InputLabel id="password-change-label">Password Change</InputLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="password-change-label"
                          id="password-change-select"
                          label="Password Change"
                          fullWidth
                          style={{ height: '100%' }}
                        >
                          <MenuItem value="Y">Y</MenuItem>
                          <MenuItem value="N">N</MenuItem>
                        </Select>
                      )}
                      name="passwdChange"
                      control={control}
                      defaultValue={userLevelParams.passwdChange || ''}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="Password Expiry(Day/s)" fullWidth readOnly />}
                    name="passwdExpiry"
                    control={control}
                    defaultValue={userLevelParams.passwdExpiry}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="Minimum Password" fullWidth readOnly />}
                    name="minPasswd"
                    control={control}
                    defaultValue={userLevelParams.minPasswd}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="Password History" fullWidth readOnly />}
                    name="passwdHistory"
                    control={control}
                    defaultValue={userLevelParams.passwdHistory}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="Search Range(Day/s)" fullWidth readOnly />}
                    name="searchRange"
                    control={control}
                    defaultValue={userLevelParams.searchRange}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Controller
                    render={({ field }) => <MDInput {...field} type="text" label="New User Password Expiry(Hrs)" fullWidth readOnly />}
                    name="newUserPasswdExp"
                    control={control}
                    defaultValue={userLevelParams.newUserPasswdExp}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <PermissionControl permission={{ restrict: true, module: "Role", subModule: "Update" }}>
                    <MDButton type="submit" variant="gradient" color="info" style={{ float: 'right' }}>
                      update
                    </MDButton>
                  </PermissionControl>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}


export default UserLevelParams;
