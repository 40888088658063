import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import { binMasterModel, binMasterSchema } from "../../../../models/binMaster";
import ConfiguratorRoot from "../../../../examples/Configurator/ConfiguratorRoot";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import useApi from "../../../../components/Utils/api";
import OutlinedInput from "@mui/material/OutlinedInput";


function BinConfigurator({openConfigurator, setOpenConfigurator, master}) {
  // const [controller] = useMaterialUIController();
  // const {user} = controller;
  const [requiredInfo, setRequiredInfo] = useState({
    bankList : [],
    cardTypeList : [],
    productTypeList : []
  });
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(binMasterSchema)
  });
  useEffect(() => {
    getAllReqInfoMaster();
    if(Object.keys(master).length > 0 ){
      reset({
        binId: master.binId,
        binCode: master.binCode,
        bankCode: master.bankMaster.bankCode,
        cardTypeCode: master.cardTypeMaster.typeCode,
        productTypeCode: master.productTypeMaster.typeCode
      });
    } else {
      reset(binMasterModel);
    }

  }, [master])
  const api = useApi();
  const getAllReqInfoMaster = () => {
    api.get("/master/getReqInfoMaster")
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          setRequiredInfo(response.data.body);
        }
      })
      .catch(error => {

      })
  }
  const createTerminalHandler = (data) => {
    api.post("master/createBinMaster", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          toast.success("Bin master successfully created.");
          setOpenConfigurator(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        console.log(error.response)
        toast.error(error.response.data.body)
      })
  }
  function handleCityChange(e) {
    const state = e.explicitOriginalTarget.getAttribute('data-state');
    const country = e.explicitOriginalTarget.getAttribute('data-country');
    setValue("state", state);
    setValue("country", country);
  }
  function updateTerminalHandler(data) {
    api.post("master/updateBinMaster", data)
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          toast.success("Bin master successfully updated.");
          reset(binMasterModel);
          setOpenConfigurator(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  function submitHandler() {
    if(Object.keys(master).length === 0 ){
      handleSubmit(createTerminalHandler)();
    } else {
      handleSubmit(updateTerminalHandler)();
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{Object.keys(master).length > 0 ? 'Update' : 'New'} Bin Master</MDTypography>
          {/*<MDTypography variant="body2" color="text">*/}
          {/*  {updatedUser?.userId}*/}
          {/*</MDTypography>*/}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(false)}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Grid container spacing={2} justifyContent="center">
        <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
          <Grid item xs={6}>
            <MDInput
              type="text"
              label="Bin"
              name="binCode"
              error={errors.binCode}
              helperText={errors.binCode ? errors.binCode.message : ''}
              fullWidth
              required
              {...register("binCode")}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth style={{ height: '100%' }}>
              <InputLabel id="bank-label">Bank</InputLabel>
              <Controller
                name="bankCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} style={{ height: '100%' }} labelId="bank-label" input={<OutlinedInput label="Bank" />}>
                    <MenuItem value="">- Select Bank -</MenuItem>
                    {requiredInfo?.bankList.map(bank => <MenuItem key={bank.bankCode} value={bank.bankCode}>{bank.bankName}</MenuItem>)}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth style={{ height: '100%' }}>
              <InputLabel id="card-type-label">Card Type</InputLabel>
              <Controller
                name="cardTypeCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} style={{ height: '45px' }} labelId="card-type-label" input={<OutlinedInput label="Card Type" />}>
                    <MenuItem value="">- Select Card Type -</MenuItem>
                    {requiredInfo?.cardTypeList.map(card => <MenuItem key={card.typeCode} value={card.typeCode}>{card.typeName}</MenuItem>)}
                  </Select>
                )}
              />
            </FormControl>

          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth style={{ height: '100%' }}>
              <InputLabel>Product Type</InputLabel>
              <Controller
                name="productTypeCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} style={{ height: '45px' }} labelId="bank-label" input={<OutlinedInput label="Product Type" />}>
                    <MenuItem value="">- Select Product Type -</MenuItem>
                    {requiredInfo?.productTypeList.map(productType => <MenuItem key={productType.typeCode} value={productType.typeCode}>{productType.typeName}</MenuItem>)}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MDButton onClick={() => submitHandler()} variant="gradient" color="info" style={{float : 'right'}}>
              {Object.keys(master).length > 0 ? 'Update' : 'Add'}
            </MDButton>
          </Grid>
        </FormProvider>
      </Grid>
    </ConfiguratorRoot>
  );
}

BinConfigurator.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
  master : {}
}

BinConfigurator.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
  master: PropTypes.object
}

export default BinConfigurator;