/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import toast from "react-hot-toast";
import Icon from "@mui/material/Icon";
import * as React from "react";
import MdrConfigurator from "./mdrConfigurator/mdrConfigurator";
import MDButton from "../../../components/MDButton";
import Papa from 'papaparse';
import PermissionControl from "../../../components/Utils/PermissionControl";

function MdrMaster() {
  const { mdrMasterTableColumns } = data();
  const [masters, setMasters] = useState([]);
    const [master, setMaster] = useState({});
    const [openConfigurator, setOpenConfigurator] = useState(false);
  const api = useApi();

  function updateMasterHandler(item) {
      setMaster(item);
      setOpenConfigurator(true);
  }

  const deleteMdrHandler = (item) => {
      api.delete("master/mdrMaster/"+item.mcId)
          .then(response => {
              if(response.status === 204) {
                  toast.success("MDR deleted for merchant "+item.mid);
                  getMasters();
              }
          })
          .catch(error => {

          })
  }

  const getMasters = () => {
    api.post("master/getMdrMasters")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          const modifiedData = response.data.body.map(item => {
            const action = (
              <MDBox display="flex" alignItems="center" lineHeight={1}>
                  <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "UpdateMdrMaster" }}>
                      <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => updateMasterHandler(item)}>create</Icon>
                  </PermissionControl>
                  <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "DeleteMdrMaster" }}>
                      <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => deleteMdrHandler(item)}>delete</Icon>
                  </PermissionControl>
              </MDBox>
            );
            return { ...item, action };
          });
          setMasters(modifiedData);
        }
      })
      .catch((error) => {
          if (error.response && error.response.status === 403) {
              toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
              return;
          }
        toast.error(error.response.data.body);
      })
  }


  useEffect(() => {
      getMasters();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
            <Grid container justifyContent="flex-end">
                <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "CreateMdrMaster" }}>
                    <MDButton variant="gradient" onClick={() => {
                        setOpenConfigurator(true);
                        setMaster({});
                    }} color="info" style={{marginBottom: "12px"}}>
                        Add Merchant MDR
                    </MDButton>
                </PermissionControl>
            </Grid>
            <DataTable
              table={{ columns : mdrMasterTableColumns, rows : masters }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              canSearch={true}
              entriesPerPage={true}
            />
        </Grid>
      </MDBox>
        <MdrConfigurator openConfigurator={openConfigurator} setOpenConfigurator={setOpenConfigurator} master={master} getMasters={getMasters} />
      <Footer />
    </DashboardLayout>
  );
}

export default MdrMaster;
