import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Additional Imports for Icons and Dialog
import { Tooltip, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Restricted words for password validation
const restrictedWords = ["password", "admin", "welcome", "test", "1234"];

// Validation schema for form fields
const validationSchema = yup.object().shape({
  current_password: yup.string().required("Current Password is required"),
  new_password: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(14, "Password must not exceed 14 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()_+\/~{}[\]:\";'<>?,.\/]).*$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .test(
      "restricted-words",
      "Password should not contain common words like 'password', 'admin', 'welcome', 'test', or '1234'.",
      function (value) {
        if (!value) return true;
        const lowerValue = value.toLowerCase();
        return !restrictedWords.some((word) => lowerValue.includes(word));
      }
    ),
  confirm_new_password: yup
    .string()
    .required("Confirm New Password is required")
    .oneOf([yup.ref("new_password"), null], "Confirm New Password should match New Password"),
});

function ResetPassword() {
  const api = useApi();
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [dialogOpen, setDialogOpen] = useState(false); // State for Dialog visibility
  const { handleSubmit, register, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // Toggle password visibility for fields
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Open Dialog for password criteria
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Close Dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Reset Password Handler
  const resetPassword = (form) => {
    if (form.new_password !== form.confirm_new_password) {
      toast.error("Confirm New Password should be the same as New Password.");
      return;
    }
    const data = {
      current_password: form.current_password,
      new_password: form.new_password,
    };

    api.post("auth/admin/auth-reset-password", data)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          toast.success(response.data.body);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
        } else if (error.response && error.response.data.statusCodeValue === 200) {
          toast.error(error.response.data.body);
        } else if (error.response) {
          toast.error(error.response.data.body?.message || "An error occurred.");
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card color="#fff">
              <MDBox pt={4} pb={3} px={3} width="50%" margin="0 auto">
                <MDBox component="form" role="form" onSubmit={handleSubmit(resetPassword)}>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Current Password"
                      name="current_password"
                      fullWidth
                      {...register("current_password")}
                      error={!!errors.current_password}
                      helperText={errors.current_password?.message || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* Eye Icon for toggling visibility */}
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              style={{
                                background: "transparent",
                                borderRadius: "0",
                                boxShadow: "none",
                                padding: 0,
                                marginRight: "10px",
                              }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      name="new_password"
                      fullWidth
                      {...register("new_password")}
                      error={!!errors.new_password}
                      helperText={errors.new_password?.message || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* Eye Icon for toggling visibility */}
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              style={{
                                background: "transparent",
                                borderRadius: "0",
                                boxShadow: "none",
                                padding: 0,
                                marginRight: "10px",
                              }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>

                            {/* Info Icon */}
                            <Tooltip title="Password Criteria: Click for details" placement="top">
                              <IconButton
                                aria-label="password criteria"
                                style={{
                                  background: "linear-gradient(135deg, #4c9aff 30%, #007aff 90%)",
                                  color: "#ffffff",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "50%",
                                  padding: "0px",
                                  width: "31px",
                                  height: "28px",
                                }}
                                onClick={handleDialogOpen} // Open Dialog on Click
                              >
                                <InfoIcon style={{ fontSize: "18px" }} />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      {...register("confirm_new_password")}
                      label="Confirm New Password"
                      name="confirm_new_password"
                      fullWidth
                      error={!!errors.confirm_new_password}
                      helperText={errors.confirm_new_password?.message || ""}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      Reset Password
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Password Criteria Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="password-criteria-title"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "16px",
            minWidth: "400px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle id="password-criteria-title" style={{ textAlign: "center", fontWeight: "bold" }}>
          Password Criteria
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom style={{ fontSize: "0.875rem" }}>
            Recommended passwords with a length of 8-12 characters.
          </Typography>
          <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
            <ul style={{ paddingLeft: "20px", marginTop: "0", fontSize: "0.75rem" }}>
              <li>At least 1 uppercase letter (A-Z)</li>
              <li>At least 1 lowercase letter (a-z)</li>
              <li>At least 1 number (0-9)</li>
              <li>At least 1 special character (e.g., ! @ # $)</li>
            </ul>
          </Typography>
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ResetPassword;
