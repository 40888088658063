/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import useApi from "../../components/Utils/api";
import data from "../dashboard/components/Projects/data";
import MDBox from "../../components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";


function ResetImei() {
  const [terminals, setTerminals] = useState([]);
  const [terminal, setTerminal] = useState({});
  const { resetTerminalTablecolumns } = data();
  const [openImeiDialog, setOpenImeiDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const api = useApi();

  useEffect(() => {
    getAllTerminals();
  }, []);
    const resetImei = (item) => {
        setOpenImeiDialog(true);
        setTerminal(item);
    }
    const getAllTerminals = () => {
        const data = {
          msisdn: '',
          mid: ''
        }
        api.post("/terminal/browse", data)
            .then(response => {
              if(response.data.statusCodeValue === 200) {
                const modifiedData = response.data.body.map(item => {
                  const action = (
                      <MDBox display="flex" alignItems="center" lineHeight={1}>
                        <Icon title="Reset IMEI" fontSize="small" style={{cursor : 'pointer', marginRight: '5px'}} onClick={() => resetImei(item)}>lock_reset</Icon>
                      </MDBox>
                  );
                  return { ...item, action };
                });
                setTerminals(modifiedData);
              }
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
    }

    const handleImeiResetSubmit = () => {
        const data = {
            tid: terminal.tid
        }
        api.post("/utils/resetimei", data)
            .then(response => {
                if (response.data.statusCodeValue === 200) {
                    setOpenImeiDialog(false);
                    setTerminal({});
                    getAllTerminals();
                    toast.success(response.data.body);
                }
            })
            .catch(error => {
                if(error.response.data.body){
                    toast.error(error.response.data.body);
                }

            })
    }

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={6} mb={3}>
          <Grid container spacing={3} mb={10}>
            <DataTable
                table={{ columns : resetTerminalTablecolumns, rows : terminals }}
                showTotalEntries={true}
                isSorted={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
            />
          </Grid>
        </MDBox>
      <Dialog disableEscapeKeyDown open={openImeiDialog} onClose={() => setOpenImeiDialog(true)}>
          <DialogTitle>Reset Terminal IMEI</DialogTitle>
          <DialogContent>
              <Typography fontSize="medium">Do you really want to reset the IMEI for terminal id <Typography fontSize="medium" fontWeight="bold">{terminal?.tid}</Typography></Typography>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {
                setOpenImeiDialog(false);
                setTerminal({});
              }
              }>Cancel</Button>
              <Button onClick={handleImeiResetSubmit}>Ok</Button>
          </DialogActions>
      </Dialog>
      </DashboardLayout>
  );
}

export default ResetImei;
