import * as yup from 'yup';

const mdrDefaultModel = {
  cardHoldercharger: '',
  merchantMDr: ''
};

const mdrDefaultSchema = yup.object().shape({
  cardHoldercharger: yup
    .string()
    .required('Cardholder is required')
    .matches(/^[0-9]+(\.[0-9]{1,3})?$/, 'Cardholder must be a valid number with up to 3 decimal places'),

  merchantMDr: yup
    .string()
    .required('Merchant MDRs is required')
    .matches(/^[0-9]+(\.[0-9]{1,3})?$/, 'Merchant MDRs must be a valid number with up to 3 decimal places')
});

export {mdrDefaultModel, mdrDefaultSchema};