import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useMaterialUIController } from "../../../context";
import useApi from "../../../components/Utils/api";
import ConfiguratorRoot from "../../../examples/Configurator/ConfiguratorRoot";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { merchantUserSchema, merchantUserModel } from "../../../models/merchantUserSchema";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";


function NewMerchantGroupUser({openConfigurator, setOpenConfigurator, user, merchantGroup, merchantGroups}) {
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(merchantUserSchema)
  });
  const {t} = useTranslation();
  useEffect(() => {
    if(Object.keys(user).length > 0 ){
      delete user.authorities;
      reset(user);
    } else {
      setValue("referenceId", merchantGroup?.referenceId);
      setValue("role", 'REFERRALID');
    }

  }, [merchantGroup, user])
  const api = useApi();
  const createUserHandler = (data) => {
    api.post("admin/createMerchantGroupUser", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          toast.success(t('user_successfully_created'));
          setOpenConfigurator(false);
        } else {
          toast.error(response.data.body);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        console.log(error.response)
        toast.error(error.response.data.body)
      })
  }
  function updateUserHandler(data) {
    api.post("admin/modifyMerchantGroupUser", data)
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          toast.success(t('user_successfully_updated'));
          reset(merchantUserModel);
          setOpenConfigurator(false);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        console.log(error);
      });
  }
  function submitHandler() {
    if(Object.keys(user).length === 0 ){
      handleSubmit(createUserHandler)();
    } else {
      handleSubmit(updateUserHandler)();
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{Object.keys(user).length > 0 ? t('update') : t('new')} {t('user')}</MDTypography>
          {/*<MDTypography variant="body2" color="text">*/}
          {/*  {updatedUser?.userId}*/}
          {/*</MDTypography>*/}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => {
            reset(merchantUserModel);
            setOpenConfigurator(false);
          }
          }
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Grid container spacing={2} justifyContent="center">
        <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
          <Grid item xs={6}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel>{t('reference_id')}</InputLabel>
              <Controller
                name="referenceId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={Object.keys(user).length > 0}
                    style={{height: '100%'}}
                    variant="outlined"
                  >
                    <MenuItem value="-1">- {t('select_reference_company')} -</MenuItem>
                    {merchantGroups.map(group => (
                      <MenuItem key={group.referenceId} value={group.referenceId}>{group.referenceCompanyName}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('username')} name="userId"
                     error={errors.userId}
                     helperText={errors.userId ? errors.userId.message : ''}
                     fullWidth
                     disabled={Object.keys(user).length > 0}
                     {...register("userId")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('first_name')} name="firstName"
                     error={errors.firstName}
                     helperText={errors.firstName ? errors.firstName.message : ''}
                     fullWidth
                     {...register("firstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('last_name')} name="lastName"
                     error={errors.lastName}
                     helperText={errors.lastName ? errors.lastName.message : ''}
                     fullWidth
                     {...register("lastName")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('mobile_no')} name="mobileNo"
                     error={errors.mobileNo}
                     helperText={errors.mobileNo ? errors.mobileNo.message : ''}
                     fullWidth
                     disabled={Object.keys(user).length > 0}
                     {...register("mobileNo")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('email_id')} name="emailId"
                     error={errors.emailId}
                     helperText={errors.emailId ? errors.emailId.message : ''}
                     fullWidth
                     {...register("emailId")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('user_level')} name="role"
                     error={errors.role}
                     helperText={errors.role ? errors.role.message : ''}
                     fullWidth
                     disabled={true}
                     {...register("role")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('department')} name="dept"
                     error={errors.dept}
                     helperText={errors.dept ? errors.dept.message : ''}
                     fullWidth
                     {...register("dept")}
            />
          </Grid>
          <Grid item xs={12}>
            <MDButton onClick={() => submitHandler()} variant="gradient" color="info" style={{float : 'right'}}>
              {Object.keys(user).length > 0 ? t('update') : t('add')}
            </MDButton>
          </Grid>
        </FormProvider>
      </Grid>
    </ConfiguratorRoot>
  );
}

NewMerchantGroupUser.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
  user : {},
  merchantGroup: {},
  merchantGroups : []
}

NewMerchantGroupUser.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
  user: PropTypes.object,
  merchantGroup : PropTypes.object,
  merchantGroups: PropTypes.array
}

export default NewMerchantGroupUser;