import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import { mdrMasterModel, mdrMasterSchema } from "../../../../models/binMaster";
import ConfiguratorRoot from "../../../../examples/Configurator/ConfiguratorRoot";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import useApi from "../../../../components/Utils/api";
import OutlinedInput from "@mui/material/OutlinedInput";


function MdrConfigurator({openConfigurator, setOpenConfigurator, master, getMasters}) {
  // const [controller] = useMaterialUIController();
  // const {user} = controller;
  const [merchants, setMerchants] = useState([]);
  const [defaultMasters, setDefaultMasters] = useState([]);
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(mdrMasterSchema)
  });
  useEffect(() => {
    getAllReqInfoMaster();
    if(Object.keys(master).length > 0 ){
      reset(master);
    } else {
      getDefaultMasters();
      reset(mdrMasterModel);
    }

  }, [master])
  const api = useApi();
  const getAllReqInfoMaster = () => {
    const data = {
      "mid": "",
      "ownerName": "",
      "referenceId": ""
    };
    api.post("/merchant/browse", data)
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          setMerchants(response.data.body);
        }
      })
      .catch(error => {

      })
  }
  const getDefaultMasters = () => {
    api.post("master/getDefaultMdrMaster")
        .then((response) => {
          if(response.data.statusCodeValue === 200) {
            setDefaultMasters(response.data.body[0]);
            reset(response.data.body[0]);
          }
        })
        .catch((error) => {
            if (error.response && error.response.status === 403) {
                toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
                return;
            }
          toast.error(error.response.data.body);
        })
  }
  const createTerminalHandler = (data) => {
    api.post("master/createMdrMaster", data)
      .then((response) => {
        if(response.status === 200){
          toast.success("MDR master successfully created.");
          setOpenConfigurator(false);
          getMasters();
        }
      })
      .catch((error) => {
          if (error.response && error.response.status === 403) {
              toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
              return;
          }
        console.log(error.response);
        if (error.response.status === 406){
          toast.error(error.response.data.body)
        }
      })
  }
  function updateTerminalHandler(data) {
    api.put("master/mdrMaster/"+master.mcId, data)
      .then(response => {
        if(response.status === 200) {
          toast.success("MDR master successfully updated.");
          reset(mdrMasterModel);
          setOpenConfigurator(false);
          getMasters();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  function submitHandler() {
    if(Object.keys(master).length === 0 ){
      handleSubmit(createTerminalHandler)();
    } else {
      handleSubmit(updateTerminalHandler)();
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{Object.keys(master).length > 0 ? 'Update' : 'New'} MDR Master</MDTypography>
          {/*<MDTypography variant="body2" color="text">*/}
          {/*  {updatedUser?.userId}*/}
          {/*</MDTypography>*/}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(false)}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Grid container spacing={2} justifyContent="center">
        <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
          <Grid item xs={6}>
            <MDInput
              type="text"
              label="Card Holder Charge"
              name="cardHoldercharger"
              error={errors.cardHoldercharger}
              helperText={errors.cardHoldercharger ? errors.cardHoldercharger.message : ''}
              fullWidth
              required
              {...register("cardHoldercharger")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput
              type="text"
              label="Merchant MDR"
              name="merchantMDr"
              error={errors.merchantMDr}
              helperText={errors.merchantMDr ? errors.merchantMDr.message : ''}
              fullWidth
              required
              {...register("merchantMDr")}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth style={{ height: '100%' }}>
              <InputLabel id="bank-label">Merchant</InputLabel>
              <Controller
                name="mid"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} style={{ height: '100%' }} labelId="bank-label" input={<OutlinedInput label="Merchant" />}>
                    <MenuItem value="">- Select Merchant -</MenuItem>
                    {merchants.map(merchant => <MenuItem key={merchant.mid} value={merchant.mid}>{merchant.companyName}</MenuItem>)}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <MDButton onClick={() => submitHandler()} variant="gradient" color="info" style={{float : 'right'}}>
              {Object.keys(master).length > 0 ? 'Update' : 'Add'}
            </MDButton>
          </Grid>
        </FormProvider>
      </Grid>
    </ConfiguratorRoot>
  );
}

MdrConfigurator.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
  getMasters: false,
  master : {}
}

MdrConfigurator.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
  getMasters: PropTypes.func,
  master: PropTypes.object
}

export default MdrConfigurator;