import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        console.log("i18n.language -> ", i18n.language);
    };
    useEffect(() => {
        console.log("Current language: ", i18n.language);
    }, [i18n.language]);
    return (<>
        {/* <TextField
            fullWidth
            onChange={changeLanguage}
            value={i18n.language}
            className="!w-[50px]"
            select
            SelectProps={{ IconComponent: () => null }}
            sx={{
                position: 'absolute',
                right: '15%',
                marginTop: '0px',
                height: '30px',
                zIndex: '99999990',
                top: '0',
                width: '50px'
            }}
        >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
        </TextField> */}
        </>
    );
};

export default LanguageSwitcher;
