/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import MDInput from "../../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { DateRangePicker } from "rsuite";
import { afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import reportsLineChartData from "../../dashboard/data/reportsLineChartData";

function CashTransactions() {
  const { cashTransactionsReportsColumns } = data();
  const { predefinedRanges } = reportsLineChartData;
  const [transactions, setTransactions] = useState([]);
  const [lastMonthDateRange, setLastMonthDateRange] = useState([new Date().toISOString().slice(0, 10),  new Date().toISOString().slice(0, 10)]);
  // const [fromDate, setFromDate]= useState(lastMonthDateRange[0]);
  // const [toDate, setToDate ]= useState(lastMonthDateRange[1]);
  const api = useApi();
  const formatDate = (date) => {
    const isoDate = new Date(date).toISOString();
    return isoDate.slice(0, 10); // Extracts only the date part
  };
  const getTransactions = (lastMonthDateRange) => {
    if(lastMonthDateRange == null) return;
    setLastMonthDateRange(lastMonthDateRange);
    const data = {
      fromDate : formatDate(lastMonthDateRange[0]),
      toDate : formatDate(lastMonthDateRange[1]),
    }
    api.post("getcashtransactionSummary", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setTransactions(response.data.body);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        toast.error("No Record Found", { id: "NoRecordFound" });
      })
  }

  useEffect(() => {
    if(lastMonthDateRange !== null){
      getTransactions(lastMonthDateRange);
    }
  },[lastMonthDateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3} textAlign={"center"}>
                <DateRangePicker disabledDate={afterToday()} ranges={predefinedRanges} onChange={(date) => getTransactions(date)} format="yyyy-MM-dd" value={Object.keys(lastMonthDateRange).length !== 0 ? [new Date(lastMonthDateRange[0]), new Date(lastMonthDateRange[1])] : []} />
              </MDBox>
            </Grid>
            <DataTable
              table={{ columns : cashTransactionsReportsColumns, rows : transactions }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CashTransactions;
