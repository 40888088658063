/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { FormControl, InputLabel, Select, TextareaAutosize } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ValidationContext from "../../context/validation";
import MDTextarea from "../../components/MDTextarea";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from '../../components/CustomTabPanel';
import MerchantGroupUsers from "./users";
import PermissionControl from "../../components/Utils/PermissionControl";
import {useTranslation} from "react-i18next";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function MerchantGroupSingle() {
  const {t} = useTranslation();
  const {state} = useLocation();
  const [merchantGroup, setMerchantGroup] = React.useState(state.referenceCompany ? state.referenceCompany : {});
  const {validateInput, errors} = useContext(ValidationContext);
  const [cities, setCities] = React.useState([]);
  const [tabValue, setTabValue] = React.useState(0)
  const [referenceCode, setReferenceCode] = React.useState(0)
  const navigate = useNavigate();
  const formRef = useRef();

  const api = useApi();
  const getCities = () => {
    api.get("utils/citylist")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          let result = response.data.body;
          setCities(result);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        console.log(error.response.data);
      })
  }
  const formValuesHandler = (e) => {
    const {name, value} = e.target;
    validateInput('NAME_BASED', name, value);
    setMerchantGroup({...merchantGroup, [name]: value});
  }
  const cityHandler = (e) => {
  // console.log(e);
    const state = e.explicitOriginalTarget.getAttribute('data-state');
    const country = e.explicitOriginalTarget.getAttribute('data-country');
    setMerchantGroup({...merchantGroup, "state": state, "country": country, "city": e.target.value});
  }
  const updateMerchantGroup = (formDataObject) => {
    api.post("admin/modifyMerchantGroup", formDataObject)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          let result = response.data.body;
          toast.success(t('merchant_group_successfully_updated'));
          navigate("/merchant-groups");
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
      })
  }
  const saveMerchantGroup = (formDataObject) => {
    api.post("admin/createMerchantGroup", formDataObject)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          let result = response.data.body;
          toast.success(t('merchant_group_successfully_added'));
          navigate("/merchant-groups");
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        toast.error(error.response.data.body);
      })
  }
  const saveOrUpdateMerchantGroup = (form) => {
    form.preventDefault();
    const formEntries = new FormData(formRef.current).entries();
    const formDataObject = Object.fromEntries(formEntries);
    if(Object.keys(errors).length === 0){
      if(state.referenceCompany){
        updateMerchantGroup(formDataObject);
      } else {
        saveMerchantGroup(formDataObject);
      }
    } else {
      toast.error(t('you_have_some_errors_in_the_form_please_fix_them_first'))
    }
  };
  useEffect(() => {
      getCities();
    setReferenceCode(generateRandomString());
  }, []);
  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }
  function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';

    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3} >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "10px" }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={t('basic_info')} {...a11yProps(0)} />
                {state.referenceCompany ? <Tab label={t('users')} {...a11yProps(1)} /> : <></>}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
            <CustomTabPanel value={tabValue} index={0}>
              <MDBox component="form" ref={formRef} role="form" onSubmit={(form) => saveOrUpdateMerchantGroup(form)} >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <MDInput
                      type="text"
                      label={t('merchant_group_code')}
                      name="referenceId"
                      value={merchantGroup.referenceId ? merchantGroup.referenceId : referenceCode}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={errors.referenceId}
                      helperText={errors.referenceId ? errors.referenceId : ''}
                      onChange={formValuesHandler}
                      disabled={true}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <MDInput
                      type="text"
                      label={t('merchant_group_name')}
                      name="referenceCompanyName"
                      value={merchantGroup.referenceCompanyName}
                      fullWidth
                      InputLabelProps={{ shrink: merchantGroup.referenceCompanyName && true }}
                      error={errors.referenceCompanyName}
                      helperText={errors.referenceCompanyName ? errors.referenceCompanyName : ''}
                      onChange={formValuesHandler}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <MDInput
                      type="text"
                      label={t('owner_name')}
                      name="ownerName"
                      value={merchantGroup.ownerName}
                      fullWidth
                      InputLabelProps={{ shrink: merchantGroup.ownerName && true }}
                      error={errors.ownerName}
                      helperText={errors.ownerName ? errors.ownerName : ''}
                      onChange={formValuesHandler}
                      required
                    />
                  </Grid>
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Pin Code"*/}
                  {/*    name="pincode"*/}
                  {/*    value={merchantGroup.pincode}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.pincode && true }}*/}
                  {/*    error={errors.pincode}*/}
                  {/*    helperText={errors.pincode ? errors.pincode : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <FormControl fullWidth style={{height: '100%'}}>*/}
                  {/*    <InputLabel id="demo-simple-select-label">City</InputLabel>*/}
                  {/*    <Select*/}
                  {/*      labelId="demo-simple-select-label"*/}
                  {/*      id="demo-simple-select"*/}
                  {/*      label="City"*/}
                  {/*      fullWidth*/}
                  {/*      style={{height: '100%'}}*/}
                  {/*      name="city"*/}
                  {/*      value={merchantGroup.city}*/}
                  {/*      onChange={(e) => cityHandler(e)}*/}
                  {/*    >*/}
                  {/*      <MenuItem value="" >- Select City-</MenuItem>*/}
                  {/*      {cities.map(city => <MenuItem key={city.cityId} data-state={city.state} data-country={city.country} value={city.cityId}>{city.cityCode}</MenuItem>)}*/}
                  {/*    </Select>*/}
                  {/*  </FormControl>*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={6} md={2} lg={1.5}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="State"*/}
                  {/*    name="state"*/}
                  {/*    value={merchantGroup.state}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.state && true }}*/}
                  {/*    error={errors.state}*/}
                  {/*    helperText={errors.state ? errors.state : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*    disabled*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={6} md={2} lg={1.5}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Country"*/}
                  {/*    name="country"*/}
                  {/*    value={merchantGroup.country}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.country && true }}*/}
                  {/*    error={errors.country}*/}
                  {/*    helperText={errors.country ? errors.country : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*    disabled*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Commission On Debit"*/}
                  {/*    name="commissionOnDebit"*/}
                  {/*    value={merchantGroup.commissionOnDebit}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.commissionOnDebit !== "" && true }}*/}
                  {/*    error={errors.commissionOnDebit}*/}
                  {/*    helperText={errors.commissionOnDebit ? errors.commissionOnDebit : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Commission on credit"*/}
                  {/*    name="commissionOnCredit"*/}
                  {/*    value={merchantGroup.commissionOnCredit}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.commissionOnCredit !== "" && true }}*/}
                  {/*    error={errors.commissionOnCredit}*/}
                  {/*    helperText={errors.commissionOnCredit ? errors.commissionOnCredit : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Address 1"*/}
                  {/*    name="address1"*/}
                  {/*    value={merchantGroup.address1}*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.address1 && true }}*/}
                  {/*    error={errors.address1}*/}
                  {/*    helperText={errors.address1 ? errors.address1 : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*    fullWidth*/}
                  {/*    multiline*/}
                  {/*    minRows={3}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Address 2"*/}
                  {/*    name="address2"*/}
                  {/*    value={merchantGroup.address2}*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.address2 && true }}*/}
                  {/*    error={errors.address2}*/}
                  {/*    helperText={errors.address2 ? errors.address2 : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*    fullWidth*/}
                  {/*    multiline*/}
                  {/*    minRows={3}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Address 3"*/}
                  {/*    name="address3"*/}
                  {/*    value={merchantGroup.address3}*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.address3 && true }}*/}
                  {/*    error={errors.address3}*/}
                  {/*    helperText={errors.address3 ? errors.address3 : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*    fullWidth*/}
                  {/*    multiline*/}
                  {/*    minRows={3}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Display Name"*/}
                  {/*    name="displayName"*/}
                  {/*    value={merchantGroup.displayName}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.displayName && true }}*/}
                  {/*    error={errors.displayName}*/}
                  {/*    helperText={errors.displayName ? errors.displayName : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="Sender Id"*/}
                  {/*    name="senderId"*/}
                  {/*    value={merchantGroup.senderId}*/}
                  {/*    fullWidth*/}
                  {/*    readOnly*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.senderId && true }}*/}
                  {/*    error={errors.senderId}*/}
                  {/*    helperText={errors.senderId ? errors.senderId : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="URL APK"*/}
                  {/*    name="urlApk"*/}
                  {/*    value={merchantGroup.urlApk}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.urlApk && true }}*/}
                  {/*    error={errors.urlApk}*/}
                  {/*    helperText={errors.urlApk ? errors.urlApk : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} md={4} lg={3}>*/}
                  {/*  <MDInput*/}
                  {/*    type="text"*/}
                  {/*    label="URL Merchant Portal"*/}
                  {/*    name="urlMerchantPortal"*/}
                  {/*    value={merchantGroup.urlMerchantPortal}*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{ shrink: merchantGroup.urlMerchantPortal && true }}*/}
                  {/*    error={errors.urlMerchantPortal}*/}
                  {/*    helperText={errors.urlMerchantPortal ? errors.urlMerchantPortal : ''}*/}
                  {/*    onChange={formValuesHandler}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} md={12} lg={12} >
                    <PermissionControl permission={{ restrict: true, module: "MerchantGroup", subModule: "Update" }}>
                      <MDButton type="submit" variant="gradient" color="info" style={{float : 'right'}}>
                        {state.referenceCompnay ? t('update') : t('save')}
                      </MDButton>
                    </PermissionControl>
                  </Grid>

                </Grid>
              </MDBox>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <MerchantGroupUsers merchantGroup={merchantGroup} merchantGroups={state.merchantGroups} />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MerchantGroupSingle;
