/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import {isEmpty} from "../../../components/Utils/common";
import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import {FormControl, InputLabel, Select, Typography, Divider} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";


function MerchantBankDetails({merchant, editmode, setMerchant, type}) {
  const {t} = useTranslation();
  const { register, formState: { errors }, control } = useFormContext();
  console.log(errors);
  return (
      <Grid mt={3} mb={3} padding={2} bgColor="white">
        <Divider variant="middle" color="primary" style={{ marginBottom: '40px', marginTop: '-40px' }}>Settlement Details</Divider>
        {errors?.merchantBankDetails?.message ? <Typography style={{ marginBottom: '15px' }} color="red" variant="subtitle2" component="h2">{errors?.merchantBankDetails?.message}</Typography> : <></>}
        <Grid container spacing={2} justifyContent="center">

          <Grid item xs={2}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="currency-simple-select-label">{t('currency')}</InputLabel>
              <Controller
                  name="merchantBankDetails[0].currency"
                  control={control} // make sure to get "control" from useForm()
                  defaultValue=""
                  render={({ field }) => (
                      <Select
                          labelId="currency-simple-select-label"
                          id="currency-simple-select"
                          label={t('currency')}
                          fullWidth
                          {...field}
                          error={errors?.merchantBankDetails?.[0]?.currency}
                          helperText={errors?.merchantBankDetails?.[0]?.currency?.message}
                          style={{height: '100%'}}
                          disabled={!editmode}
                      >
                        <MenuItem value="">-{t('currency')}-</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="ZiG">ZiG</MenuItem>
                      </Select>
                  )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <MDInput
                type="text"
                label={t('settlement_bank_name')}
                name="merchantBankDetails[0].bankName"
                {...register("merchantBankDetails[0].bankName")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[0]?.bankName}
                helperText={errors?.merchantBankDetails?.[0]?.bankName?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <MDInput
                type="text"
                label={t('settlement_account_name')}
                name="merchantBankDetails[0].branch"
                {...register("merchantBankDetails[0].branch")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[0]?.branch}
                helperText={errors?.merchantBankDetails?.[0]?.branch?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
                type="text"
                label={t('settlement_account_number')}
                name="merchantBankDetails[0].accountNumber"
                {...register("merchantBankDetails[0].accountNumber")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[0]?.accountNumber}
                helperText={errors?.merchantBankDetails?.[0]?.accountNumber?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <MDInput
                type="text"
                label={t('branch_code')}
                name="merchantBankDetails[0].bankCode"
                {...register("merchantBankDetails[0].bankCode")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[0]?.bankCode}
                helperText={errors?.merchantBankDetails?.[0]?.bankCode?.message}
            />
          </Grid>

          {/* Repeat for the second currency (ZiG) */}
          <Grid item xs={2}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="currency-simple-select-label">{t('currency')}</InputLabel>
              <Controller
                  name="merchantBankDetails[1].currency"
                  control={control} // make sure to get "control" from useForm()
                  defaultValue=""
                  render={({ field }) => (
                      <Select
                          labelId="currency-simple-select-label"
                          id="currency-simple-select"
                          label={t('currency')}
                          fullWidth
                          {...field}
                          error={errors?.merchantBankDetails?.[1]?.currency}
                          helperText={errors?.merchantBankDetails?.[1]?.currency?.message}
                          style={{height: '100%'}}
                          disabled={!editmode}
                      >
                        <MenuItem value="">-{t('currency')}-</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="ZiG">ZiG</MenuItem>
                      </Select>
                  )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <MDInput
                type="text"
                label={t('settlement_bank_name')}
                name="merchantBankDetails[1].bankName"
                {...register("merchantBankDetails[1].bankName")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[1]?.bankName}
                helperText={errors?.merchantBankDetails?.[1]?.bankName?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <MDInput
                type="text"
                label={t('settlement_account_name')}
                name="merchantBankDetails[1].branch"
                {...register("merchantBankDetails[1].branch")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[1]?.branch}
                helperText={errors?.merchantBankDetails?.[1]?.branch?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
                type="text"
                label={t('settlement_account_number')}
                name="merchantBankDetails[1].accountNumber"
                {...register("merchantBankDetails[1].accountNumber")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[1]?.accountNumber}
                helperText={errors?.merchantBankDetails?.[1]?.accountNumber?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <MDInput
                type="text"
                label={t('branch_code')}
                name="merchantBankDetails[1].bankCode"
                {...register("merchantBankDetails[1].bankCode")}
                fullWidth
                disabled={!editmode}
                error={errors?.merchantBankDetails?.[1]?.bankCode}
                helperText={errors?.merchantBankDetails?.[1]?.bankCode?.message}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" style={{ marginBottom: '20px', marginTop: '20px' }} />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <MDInput type="text" label={t('expected_card_business_per_month')} name="expectedCardBusinessPerMonth"
                     {...register("expectedCardBusinessPerMonth")}
                     fullWidth
                     disabled={!editmode}
                     error={errors?.expectedCardBusinessPerMonth}
                     helperText={errors.expectedCardBusinessPerMonth ? errors.expectedCardBusinessPerMonth.message : ''}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('average_bill_amount')} name="averageBillAmount"
                     {...register("averageBillAmount")}
                     fullWidth
                     disabled={!editmode}
                     error={errors?.averageBillAmount}
                     helperText={errors.averageBillAmount ? errors.averageBillAmount.message : ''}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('turnover_years_1')} name="turnoverYear1"
                     {...register("turnoverYear1")}
                     fullWidth
                     disabled={!editmode}
                     error={errors?.turnoverYear1}
                     helperText={errors.turnoverYear1 ? errors.turnoverYear1.message : ''}
            />
          </Grid>
        </Grid>
      </Grid>
  );
}

MerchantBankDetails.defaultProps = {
  merchant: false,
  editmode: false,
  setMerchant: false,
  type: 'updated'
}

MerchantBankDetails.propTypes = {
  merchant: PropTypes.any,
  editmode: PropTypes.bool,
  setMerchant: PropTypes.func,
  type: PropTypes.string
}

export default MerchantBankDetails;
