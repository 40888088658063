/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDInput from "../../../components/MDInput";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useRef, useState } from "react";
import useApi from "../../../components/Utils/api";
import MDButton from "../../../components/MDButton";
import toast from "react-hot-toast";
import RoleSection from "./RoleSection";
import PermissionControl from "../../../components/Utils/PermissionControl";


function RolesConfiguration() {
  const [rolesConfig, setRolesConfig] = useState(false);
  const formRef = useRef();
  const api = useApi();

  const convertRolesArrayToObject = (obj) => {
    const result = {};

    for (let key in obj) {
      const parts = key.split('-');

      if (!result[parts[0]]) {
        result[parts[0]] = [];
      }
      result[parts[0]].push([parts[1], parts[2], obj[key]]);
    }

    return [result];
  }

  useEffect(() => {
    // console.log("Role Config", rolesConfig);
  },[rolesConfig])
  const getRolesConfig = (userLevel) => {

    const data = {
      usrLvlCode: userLevel
    };
    api.post("getUserLevelPermissions", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          // setUserLevelParams(response.data.body);
          const roleConfigObj = convertRolesArrayToObject(response.data.body);
          setRolesConfig(
            {
              config: roleConfigObj[0],
              usrLvlCode: userLevel
            }
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
      });
  }

  const saveUserLevelParams = (form) => {
    form.preventDefault();
    const formEntries = new FormData(formRef.current).entries();
    const formDataObject = Object.fromEntries(formEntries);
    let duplicateFormDataObj = {
      usrLvlCode : formDataObject.usrLvlCode
    };
    delete formDataObject.usrLvlCode;
    duplicateFormDataObj.permissions = formDataObject;
    api.post("updateUserLevelPermissions", duplicateFormDataObj)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          toast.success(response.data.body.message);
          setRolesConfig(false);
        }
      })
      .catch(error => {
        if(error.response.data.errors.length){
          error.response.data.errors.map((er) => toast.error(er));
        }
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3}>
          <Grid mt={3} mb={3} padding={2} width={'100%'}>
            <MDBox component="form" role="form" ref={formRef} onSubmit={(form) => saveUserLevelParams(form)} >
              <Grid container spacing={2} >
                <Grid item xs={12} md={12} lg={12} justifyContent="center">
                  <FormControl style={{height: '2.8rem', width: '13rem', display: 'block', margin: 'auto'}} >
                    <InputLabel id="demo-simple-select-label">Select User Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select User Level"
                      fullWidth
                      style={{height: '100%'}}
                      value={rolesConfig.usrLvlCode || ''}
                      name="usrLvlCode"
                      onChange={(e) => getRolesConfig(e.target.value)}
                    >
                      <MenuItem value=""> -Select user level-</MenuItem>
                      {/*<MenuItem value="ADMIN">Admin</MenuItem>*/}
                      <MenuItem value="MAKER">Admin</MenuItem>
                      <MenuItem value="MERCHANT">Merchant</MenuItem>
                      <MenuItem value="REFERRALID">Organization Admin</MenuItem>
                      <MenuItem value="REPORTING_ADMIN">Reporting Admin</MenuItem>
                      <MenuItem value="SETTLEMENT_ADMIN">Settlement Admin</MenuItem>
                      <MenuItem value="ONBOARDING_ADMIN">Onboarding Admin</MenuItem>
                      <MenuItem value="ONBOARDING_REPORTING_ADMIN">Onboarding & Reporting Admin</MenuItem>
                      <MenuItem value="G_MAKER">Guest Admin</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {rolesConfig && Object.keys(rolesConfig.config).map(key => (<RoleSection roleSection={rolesConfig.config[key]} sectionName={key} key={key}/>))}

                {rolesConfig &&
                    <PermissionControl permission={{ restrict: true, module: "Permission", subModule: "Update" }}>
                      <Grid item xs={12} md={12} lg={12} >
                        <MDButton type="submit" variant="gradient" color="info" style={{float : 'right'}}>
                          update
                        </MDButton>
                      </Grid>
                    </PermissionControl>
                }

              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}


export default RolesConfiguration;
