import React, { useContext } from "react";
import { useMaterialUIController } from "context";
import jwt_decode from "jwt-decode";
import Tooltip from '@mui/material/Tooltip';

// eslint-disable-next-line react/prop-types
const PermissionControl = ({ permission, children }) => {
    const [controller] = useMaterialUIController();
    const { token  } = controller;
    const checkPermission = () => {
        if (!token || !permission) {
            return { hasPermission: false, isDisabled: true };
        }

        const { permissions } = jwt_decode(token);
        // eslint-disable-next-line react/prop-types
        const permissionKey = `${permission.module}:${permission.subModule}`;

        const hasPermission = permissions.includes(permissionKey);

        return { hasPermission, isDisabled: !hasPermission };
    };

    const { hasPermission, isDisabled } = checkPermission();
    const tooltipTitle = "You don't have the permission to perform this action";
    const wrapperStyle = {
        position: "relative",
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        pointerEvents: isDisabled ? "auto" : "none", // "auto" to block events when disabled
        opacity: isDisabled ? 0.5 : 0,
    };
    if (isDisabled) {
        return (
            <Tooltip title={isDisabled ? tooltipTitle : ""} arrow placement="top">
                <div style={wrapperStyle}>
                    {React.cloneElement(children, {disabled: isDisabled})}
                    {isDisabled && <div style={overlayStyle}></div>}
                </div>
            </Tooltip>
        );
    } else {
        return (
            <React.Fragment>{children}</React.Fragment>
        );
    }
};

export default PermissionControl;
