import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from "prop-types";
import {Chip, ListItemText} from "@mui/material";
import {useTheme} from "@mui/styles";
import {useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import MerchantOfficeDetails from "../tabs/merchantOffficeDetails";
import Typography from "@mui/material/Typography";
import api from "../../../components/Utils/api";
import toast from "react-hot-toast";
import useApi from "../../../components/Utils/api";
import {useTranslation} from "react-i18next";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
function BulkAssignMerchant(props) {
    const {t} = useTranslation();
    const {open, setOpen, merchantGroups, unAssignedMerchants , getUnAssignedMerchants} = props;
    const [merchantGroup, setMerchantGroup] = React.useState('');
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    const api = useApi();

    const handleChange = (event) => {
        setMerchantGroup(event.target.value || '');
    };
    const handleSelectMerchantsChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedMerchants(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    function handleSubmit() {
        if(selectedMerchants.length === 0 ){
            toast.error(t('no_merchant_selected'));
            return;
        } else if(merchantGroup === ''){
            toast.error(t('no_merchant_group_selected'));
            return;
        }
        const data = {
            merchants: selectedMerchants,
            merchantGroup: merchantGroup
        }
        api.post("merchant/assigntoMerchantGroup",data)
            .then(response => {
                if(response.data.statusCodeValue === 200) {
                    toast.success(response.data.body);
                    setOpen(false);
                    getUnAssignedMerchants();
                    setMerchantGroup('');
                    setSelectedMerchants([]);
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>{t('assign_merchants')}</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, width: 300, height: '45px' }}>
                            <InputLabel id="demo-multiple-chip-label">{t('un_assinged_merchants')}</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={selectedMerchants}
                                onChange={handleSelectMerchantsChange}
                                input={<OutlinedInput label={t('un_assinged_merchants')} />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                                style={{ height: '100%' }}
                            >
                                <MenuItem value="">-{unAssignedMerchants.length > 0 ? t('select_merchants') : t('no_unassigned_merchants')}-</MenuItem>
                                {unAssignedMerchants.map((merchant) => (
                                    <MenuItem key={merchant.mid} value={merchant.mid}>
                                        <Checkbox checked={selectedMerchants.indexOf(merchant.mid) > -1} />
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {merchant.companyName}
                                        </Typography>
                                        {/*<ListItemText primary={merchant.companyName} />*/}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: 150, height: '45px' }}>
                            <InputLabel id="demo-dialog-select-label">{t('merchant_group')}</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={merchantGroup}
                                onChange={handleChange}
                                input={<OutlinedInput label={t('merchant_group')} />}
                                style={{ height: '100%' }}
                            >
                                <MenuItem value="">
                                    <em>{t('select_merchant_group')}</em>
                                </MenuItem>
                                {merchantGroups.map(mg => <MenuItem key={mg.referenceId} value={mg.referenceId}>{mg.referenceCompanyName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleSubmit}>{t('ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
BulkAssignMerchant.defaultProps = {
    open: false,
    setOpen: () => {},
    merchantGroups: [],
    unAssignedMerchants: []
}
BulkAssignMerchant.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    merchantGroups: PropTypes.array,
    unAssignedMerchants: PropTypes.array,
    getUnAssignedMerchants: PropTypes.func
}

export default BulkAssignMerchant;