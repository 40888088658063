/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import RuleCreator from "./RuleCreater";
import RuleList from "./RuleList";
import PrioritySetter from "./PrioritySetter";
import useApi from "../../components/Utils/api";
import toast from "react-hot-toast";
import Icon from "@mui/material/Icon";
import PermissionControl from "../../components/Utils/PermissionControl";

function RoutingRules() {
  const [rules, setRules] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [acquirers, setAcquirers] = useState([]);
  const api = useApi();
  const getRules = () => {
    api.get("/routingRule/browse")
      .then(response => {
        const modifiedData = response.data.map(item => {
          const action = (
              <PermissionControl permission={{ restrict: true, module: "RoutingRule", subModule: "Create" }}>
                <MDBox display="flex" alignItems="center" lineHeight={1}>
                  <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => deleteRule(item.ruleID)}>delete</Icon>
                </MDBox>
              </PermissionControl>
          );
          return { ...item, action };
        });
        setRules(modifiedData);
      })
      .catch(error => {
        console.error("Error fetching rules:", error);
      });
  }
  useEffect(() => {
    // Fetch initial rules
    getRules();
    // Fetch initial priorities
    api.get("/fieldPriority/get")
      .then(response => {
        setPriorities(response.data);
      })
      .catch(error => {
        console.error("Error fetching priorities:", error);
      });
    api.get("/acquirers")
      .then(response => {
        setAcquirers(response.data);
      })
      .catch(error => {
        console.error("Error fetching priorities:", error);
      });
  }, []);
  const handleAddRule = (newRule) => {
    let clashingRule = null;

    for (let rule of rules) {
      // Only proceed if both the new rule and existing rule apply to the same field
      if (newRule.conditionField !== rule.conditionField) continue;

      if (newRule.conditionField === 'amount') {
        const newStart = Number(newRule.conditionFromValue);
        const newEnd = Number(newRule.conditionToValue);
        const existingStart = Number(rule.conditionFromValue);
        const existingEnd = rule.conditionToValue ? Number(rule.conditionToValue) : null;

        // Check for overlap
        if (!(newEnd < existingStart || newStart > existingEnd)) {
          clashingRule = rule;
          break;
        }
      } else if (newRule.conditionFromValue === rule.conditionFromValue) { // For MID and BIN
        clashingRule = rule;
        break;
      }
    }

    if (!clashingRule) {
      api.post("/routingRule/add", newRule)
        .then(response => {
          if (response.data) {
            setRules([...rules, newRule]);
            toast.success("Rule successfully added.");
          }
        })
        .catch(error => {
          console.error("Error adding rule:", error);
        });
    } else {
      alert(`This rule clashes with an existing rule: 
            IF ${clashingRule.conditionField.toUpperCase()} IS ${clashingRule.conditionOperator.toUpperCase()} FROM ${clashingRule.conditionFromValue} 
            TO ${clashingRule.conditionToValue} THEN ACQUIRER IS ${clashingRule.acquirer}. Please modify your rule.`);
    }
  };
  const handlePriorityUpdate = (updatedPriorities) => {
    // Update priorities via API
    api.post("/fieldPriority/update", updatedPriorities)
      .then(response => {
        if (response.data.statusCodeValue === 200) {
          setPriorities(updatedPriorities);
          toast.success("Priorities successfully updated.");
        }
      })
      .catch(error => {
        console.error("Error updating priorities:", error);
      });
  }
  const deleteRule = (ruleId) => {
    api.delete(`/routingRule/delete/${ruleId}`)
      .then(response => {
        if (response.data.statusCodeValue === 200) {
          const updatedRules = rules.filter(rule => rule.id !== ruleId);
          setRules(updatedRules);
          toast.success("Rule successfully deleted.");
        }
      })
      .catch(error => {
        console.error("Error deleting rule:", error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} mb={10}>
          <Grid item lg={9}>
            <RuleList rules={rules} />
          </Grid>
          <Grid item lg={3}>
            <RuleCreator onRuleAdd={handleAddRule} acquirers={acquirers} />
            {priorities.length > 0 && <PrioritySetter initialPriorities={priorities} onUpdate={handlePriorityUpdate}></PrioritySetter>}
          </Grid>
        </Grid>
        {/*<Grid container spacing={3}>*/}
        {/*  <RuleList rules={rules} />*/}
        {/*</Grid>*/}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RoutingRules;
