import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useMaterialUIController } from "../../../context";
import useApi from "../../../components/Utils/api";
import ConfiguratorRoot from "../../../examples/Configurator/ConfiguratorRoot";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { terminalSchema, terminalModel } from "../../../models/terminalSchema";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import {useTranslation} from "react-i18next";


function NewTerminal({openConfigurator, setOpenConfigurator, terminal, merchant, onTerminalCloseHandler}) {
  const [controller] = useMaterialUIController();
  const {t} = useTranslation();
  const {user} = controller;
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(terminalSchema)
  });
  useEffect(() => {
    console.log(merchant);
    if(Object.keys(terminal).length > 0 ){
      reset(terminal);
    } else {
      reset(terminalModel);
      setValue("mid", merchant.merchant.mid);
      setValue("msisdn", merchant.merchant.mobileNo);
      setValue("emailtid", merchant.merchant.emailId);

    }

  }, [openConfigurator])
  const api = useApi();
  const createTerminalHandler = (data) => {
    api.post("terminal/create", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          toast.success(t('terminal_created'));
          setOpenConfigurator(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        console.log(error.response)
        toast.error(error.response.data.body)
      })
  }
  function handleCityChange(e) {
    const state = e.explicitOriginalTarget.getAttribute('data-state');
    const country = e.explicitOriginalTarget.getAttribute('data-country');
    setValue("state", state);
    setValue("country", country);
  }
  function updateTerminalHandler(data) {
    api.post("terminal/update", data)
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          toast.success(t('terminal_successfully_updated'));
          reset(terminalModel);
          setOpenConfigurator(false);
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  function submitHandler() {
    if(Object.keys(terminal).length === 0 ){
      handleSubmit(createTerminalHandler)();
    } else {
      handleSubmit(updateTerminalHandler)();
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{Object.keys(terminal).length > 0 ? t('update') : t('new')} {t('terminal')}</MDTypography>
          {/*<MDTypography variant="body2" color="text">*/}
          {/*  {updatedUser?.userId}*/}
          {/*</MDTypography>*/}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => {
            setOpenConfigurator(false);
            onTerminalCloseHandler();
            }
          }
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Grid container spacing={2} justifyContent="center">
        <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
          <Grid item xs={6}>
            <MDInput
              type="text" label={t('terminalTablecolumns.mid')} name="mid"
              error={errors.mid}
              helperText={errors.mid ? errors.mid.message : ''}
              fullWidth
              disabled
              {...register("mid")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('terminalTablecolumns.msisdn')} name="msisdn"
                     error={errors.msisdn}
                     helperText={errors.msisdn ? errors.msisdn.message : ''}
                     fullWidth
                     required
                     {...register("msisdn")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('terminalTablecolumns.tid_username')} name="tid"
                     error={errors.tid}
                     helperText={errors.tid ? errors.tid.message : ''}
                     fullWidth
                     required
                     disabled={Object.keys(terminal).length > 0}
                     {...register("tid")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('terminalTablecolumns.name')} name="name"
                     error={errors.name}
                     helperText={errors.name ? errors.name.message : ''}
                     fullWidth
                     {...register("name")}
            />
          </Grid>

          {/*<Grid item xs={6}>*/}
          {/*  <FormControl fullWidth style={{height: '100%'}}>*/}
          {/*    <InputLabel>City</InputLabel>*/}
          {/*    <Controller*/}
          {/*      name="city"*/}
          {/*      control={control}*/}
          {/*      defaultValue=""*/}
          {/*      render={({ field }) => (*/}
          {/*        <Select*/}
          {/*          {...field}*/}
          {/*          style={{height: '100%'}}*/}
          {/*          onChange={(e) => {*/}
          {/*            field.onChange(e); // Default handler for react-hook-form*/}
          {/*            handleCityChange(e);*/}
          {/*          }}*/}
          {/*          input={<OutlinedInput label="City" />}*/}
          {/*        >*/}
          {/*          <MenuItem value="-1">- Select City -</MenuItem>*/}
          {/*          {merchant?.cityList.map(city => (*/}
          {/*            <MenuItem data-state={city.state} data-country={city.country} key={city.cityId} value={city.cityId}>{city.cityCode}</MenuItem>*/}
          {/*          ))}*/}
          {/*        </Select>*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}

          {/*<Grid item xs={6}>*/}
          {/*  <MDInput type="text" label="State" name="state"*/}
          {/*           error={errors.state}*/}
          {/*           helperText={errors.state ? errors.state.message : ''}*/}
          {/*           fullWidth*/}
          {/*           disabled*/}
          {/*           {...register("state")}*/}
          {/*  />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={6}>*/}
          {/*  <MDInput type="text" label="Country" name="country"*/}
          {/*           error={errors.country}*/}
          {/*           helperText={errors.country ? errors.country.message : ''}*/}
          {/*           fullWidth*/}
          {/*           disabled*/}
          {/*           {...register("country")}*/}
          {/*  />*/}
          {/*</Grid>*/}

          <Grid item xs={6}>
            <MDInput type="text" label={t('terminalTablecolumns.store_name')} name="storename"
                     error={errors.storename}
                     helperText={errors.storename ? errors.storename.message : ''}
                     fullWidth
                     {...register("storename")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('terminalTablecolumns.store_address')} name="storeaddress"
                     error={errors.storeaddress}
                     helperText={errors.storeaddress ? errors.storeaddress.message : ''}
                     fullWidth
                     {...register("storeaddress")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="email" label={t('terminalTablecolumns.email')} name="emailtid"
                     error={errors.emailtid}
                     helperText={errors.emailtid ? errors.emailtid.message : ''}
                     fullWidth
                     {...register("emailtid")}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="devicetype">{t('terminalTablecolumns.deviceType')} *</InputLabel>
              <Controller
                name="deviceType"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} style={{height: '45px'}} label={t('terminalTablecolumns.deviceType')} required labelId="devicetype">
                    <MenuItem value="-1">- {t('terminalTablecolumns.select_device')} -</MenuItem>
                    <MenuItem value="UCUBE_TOUCH">UCUBE_TOUCH</MenuItem>
                    <MenuItem value="SOFTPOS">SOFTPOS</MenuItem>
                    <MenuItem value="Y1000K">Y1000K</MenuItem>
                  </Select>
                )}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MDButton onClick={() => submitHandler()} variant="gradient" color="info" style={{float : 'right'}}>
              {Object.keys(terminal).length > 0 ? t('update') : t('add')}
            </MDButton>
          </Grid>
        </FormProvider>
      </Grid>
    </ConfiguratorRoot>
  );
}

NewTerminal.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
  terminal : {},
  merchant: {}
}

NewTerminal.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
  terminal: PropTypes.object,
  merchant : PropTypes.object,
  onTerminalCloseHandler: PropTypes.func
}

export default NewTerminal;