import PropTypes from "prop-types";
import { useEffect } from "react";

function MDConfirmAlert({ text, confirmFunction, cancelFunction, toast, t }) {

  useEffect(() => {
    document.body.classList.add('confirm-alert');
    return () => {
      document.body.classList.remove('confirm-alert');
    };
  }, [])

  return (
    <>
      <div style={styles.container}>
        <div style={styles.backdrop}></div>
        <div style={styles.toast}>
          <div style={styles.content}>
            {text}
          </div>
          <div style={styles.buttonContainer}>
            <button style={styles.button.error} onClick={() => confirmFunction()}>Yes</button>
            <button style={styles.button.info} onClick={() => !cancelFunction ? toast.dismiss(t.id) : cancelFunction()}>No</button>
          </div>
        </div>
      </div>
    </>
  );
}

MDConfirmAlert.defaultProps = {
  text: '',
  confirmFunction: false,
  cancelFunction: false,
  toast: false,
  t: false
}

MDConfirmAlert.propTypes = {
  text: PropTypes.string,
  confirmFunction: PropTypes.func,
  cancelFunction: PropTypes.func,
  toast: PropTypes.any,
  t: PropTypes.any
}

export default MDConfirmAlert;

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1,
  },
  toast: {
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '11px',
    padding: '20px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    height: '7rem',
    zIndex: 1,
  },
  content: {
    marginBottom: '10px',
    fontWeight: '300',
    fontSize: '1.15rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    info: {
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      marginLeft: '5px',
      cursor: 'pointer',
    },
    error: {
      backgroundColor: 'rgb(232, 43, 43)',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      marginLeft: '5px',
      cursor: 'pointer',
    }
  },
};
