/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import useApi from "../../components/Utils/api";
import { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
import { DateRange } from "@mui/icons-material";
import { useMaterialUIController, setDashboardDataPs, setDefaultCalendar } from "../../context";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { PointOfSaleSharp } from "@mui/icons-material";
import toast from "react-hot-toast";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

function Dashboard() {
  const {t} = useTranslation();
  const { predefinedRanges } = reportsLineChartData;
  const [controller, dispatch] = useMaterialUIController();
  const {dashboardDataPs, defaultCalendarValue } = controller;
  const [selectedCurrency, setSelectedCurrency] = useState("All");
  const [dashboardData, setDashboardData] = useState({
    transactionSummary: [],
    totalNumberOfTransactions: 0,
    totalTransactionsAmount: 0
  });
  const { afterToday } = DateRangePicker;
  const api = useApi();

  const convertTransactionToReport = (originalData) => {
    const labels = [];
    const amounts = [];
    const counts = [];

    originalData.forEach((item) => {
      labels.push(item.date);
      amounts.push((item.amount / 100).toFixed(2));
      counts.push(item.totalNumOfTrans);
    });

    return {
      amountTable: {
        labels: labels,
        datasets: [
          {
            label: t('amount'),
            data: amounts,
            borderColor: "#FF6384",
            backgroundColor: "#FF6384",
            yAxisID: 'y',
          }
        ]
      },
      countTable  : {
        labels : labels,
        datasets: [
          {
            label: t("count"),
            data: counts,
            borderColor: "#fff",
            backgroundColor: "#fff",
            yAxisID: 'y1',
          }
        ]
      }
    };
  }
  const formatDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };



  const getDashboardData = (lastMonthDateRange) => {
    if(lastMonthDateRange == null) return;
    setDefaultCalendar(dispatch, lastMonthDateRange);

    // const data = {
    //   dateRangeIdentifier : "custom_range",
    //   fromDate : formatDate(lastMonthDateRange[0]),
    //   toDate : formatDate(lastMonthDateRange[1])
    // }
    console.log(lastMonthDateRange[0], formatDate(lastMonthDateRange[0]));
    const data = {
      startDate : formatDate(lastMonthDateRange[0]) + ' 00:00:00',
      endDate : formatDate(lastMonthDateRange[1]) + ' 23:59:00'
    }
    api.post("transaction-summary", data)
        .then((response) => {
          if(response.data.data.transactionSummary.length > 0) {
            setDashboardData(response.data.data);
            setDashboardDataPs(dispatch, response.data.data);
          } else {
            setDashboardData(response.data.data);
            setDashboardDataPs(dispatch, response.data.data);
          }
        })
        .catch((error) => {
        })
  }

  useEffect(() => {
    if(dashboardDataPs.transactionSummary === undefined || dashboardDataPs.transactionSummary.length === 0){
      const lastMonthDateRange = [
        subDays(new Date(), 30).toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ];
      getDashboardData(lastMonthDateRange);
    } else {
      setDashboardData(dashboardDataPs);
    }
  },[])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <MDBox mb={1.5}>
            <ComplexStatisticsCard
  color="dark"
  icon="poll"
  title={t('total_transactions_count')}
  count={
    dashboardData
      ? dashboardData.totalNumberOfTransactions // Directly from API response
      : 0
  }
/>

            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                  color="info"
                  icon="attach_money"
                  title={t('total_transactions_value')}
                  count={
                    dashboardData
                        ? (dashboardData.transactionSummary
                            .filter((row) => selectedCurrency === "All" ? true : row.currency === selectedCurrency)
                            .reduce((acc, row) => parseFloat(acc) + parseFloat(row.amount), 0) / 100).toFixed(2)
                        : 0
                  }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}></Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <MDBox mb={1.5} display="flex" flexDirection="column" alignItems="center">
              <DateRangePicker
                  disabledDate={afterToday()}
                  ranges={predefinedRanges}
                  style={{ width: '100%' }}
                  onChange={(date) => getDashboardData(date)} format="yyyy-MM-dd"
                  value={Object.keys(defaultCalendarValue).length !== 0 ? [new Date(defaultCalendarValue[0]), new Date(defaultCalendarValue[1])] : []}
              />
              <FormControl style={{ marginTop: '0.75rem', width: '100%' }}>
                <InputLabel id="currency-select-label">{t('filter_by_currency')}</InputLabel>
                <Select
                    labelId="currency-select-label"
                    id="currency-select"
                    label={t('filter_by_currency')}
                    value={selectedCurrency}
                    style={{ padding: '0.50rem 4rem' }}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="ZiG">ZiG</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="success"*/}
          {/*      icon="store"*/}
          {/*      title="Active Merchants"*/}
          {/*      count={dashboardData ? dashboardData.activeMerchantCount : 0}*/}
          {/*      // percentage={{*/}
          {/*      //   color: "success",*/}
          {/*      //   amount: "+1%",*/}
          {/*      //   label: "than yesterday",*/}
          {/*      // }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="primary"*/}
          {/*      icon="person_add"*/}
          {/*      title="Followers"*/}
          {/*      count="+91"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "",*/}
          {/*        label: "Just updated",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/*<Grid item xs={12} md={12} lg={12}>*/}
            {/*  <MDBox mb={3} textAlign={"center"}>*/}
            {/*    <DateRangePicker disabledDate={afterToday()} ranges={predefinedRanges} onChange={(date) => getDashboardData(date)} format="yyyy-MM-dd" value={Object.keys(defaultCalendarValue).length !== 0 ? [new Date(defaultCalendarValue[0]), new Date(defaultCalendarValue[1])] : []} />*/}
            {/*    <FormControl style={{ marginLeft: '0.75rem' }}>*/}
            {/*      <InputLabel id="currency-select-label">Filter by Currency:</InputLabel>*/}
            {/*      <Select*/}
            {/*          labelId="currency-select-label"*/}
            {/*          id="currency-select"*/}
            {/*          label="Filter by Currency:"*/}
            {/*          value={selectedCurrency}*/}
            {/*          style={{ padding: '0.50rem 4rem' }}*/}
            {/*          onChange={(e) => setSelectedCurrency(e.target.value)}*/}
            {/*      >*/}
            {/*        <MenuItem value="All">All</MenuItem>*/}
            {/*        <MenuItem value="USD">USD</MenuItem>*/}
            {/*        <MenuItem value="ZiG">ZiG</MenuItem>*/}
            {/*      </Select>*/}
            {/*    </FormControl>*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mb={3}>*/}
            {/*    <ReportsBarChart*/}
            {/*      color="info"*/}
            {/*      title="website views"*/}
            {/*      description="Last Campaign Performance"*/}
            {/*      date="campaign sent 2 days ago"*/}
            {/*      chart={reportsBarChartData}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
            {dashboardData ?
              <>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title={t('transactions_value')}
                    description={
                      <>
                      </>
                    }
                    date={t('updated_just_now')}
                    chart={!dashboardData ? [] : convertTransactionToReport(dashboardData.transactionSummary.filter((row) =>
                      selectedCurrency === "All" ? true : row.currency === selectedCurrency
                      )).amountTable}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="info"
                    title={t('transactions_count')}
                    description={
                      <>
                      </>
                    }
                    date={t('updated_just_now')}
                    chart={!dashboardData ? [] : convertTransactionToReport(dashboardData.transactionSummary.filter((row) =>
                        selectedCurrency === "All" ? true : row.currency === selectedCurrency
                    )).countTable}
                  />
                </MDBox>
              </Grid>
              </>
              : <></>}
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mb={3}>*/}
            {/*    <ReportsLineChart*/}
            {/*      color="dark"*/}
            {/*      title="completed tasks"*/}
            {/*      description="Last Campaign Performance"*/}
            {/*      date="just updated"*/}
            {/*      chart={tasks}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
          </Grid>
        </MDBox>
        {/*<MDBox>*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    <Grid item xs={12} md={6} lg={8}>*/}
        {/*      <Projects />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} md={6} lg={4}>*/}
        {/*      <OrdersOverview />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</MDBox>*/}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
