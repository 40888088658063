/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".loading-indicator:before, .license-indicator:before": {
    content: '""',
    background: '#000000cc',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1300,
  },
  ".loading-indicator:after, .license-indicator:after": {
    content: '""',
    position: 'fixed',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 1300,
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  'body.loading-indicator, body.license-indicator,' : {
    position: 'relative',
  },
  'body.loading-indicator .ip, body.license-indicator .licenseMessage' : {
    display: 'unset',
  },
  'body.confirm-alert .toaster-my': {
    inset: '0 !important'
  },
  'body.confirm-alert .toaster-my > div': {
    height: '100%'
  },
  '.licenseMessage': {
    width: '16em',
    position: 'fixed',
    top: '50%',
    height: '8em',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    display: 'none',
    zIndex: 1301,
  },
  '.ip': {
    width: '16em',
    position: 'fixed',
    top: '50%',
    height: '8em',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    display: 'none',
    zIndex: 1301,
  },
  '.ip__track': {
    stroke: 'hsl(var(--hue), 90%, 90%)',
    transition: 'stroke var(--trans-dur)',
  },
  '.ip__worm1, .ip__worm2': {
    animation: 'worm1 2s linear infinite',
  },
  '.ip__worm2': {
    animationName: 'worm2',
  },
  '@media (prefers-color-scheme: dark)': {
    ':root': {
      '--bg': 'hsl(var(--hue), 90%, 5%)',
      '--fg': 'hsl(var(--hue), 90%, 95%)',
    },
    '.ip__track': {
      stroke: 'hsl(var(--hue), 90%, 15%)',
    },
  },
  '@keyframes worm1': {
    from: {
      strokeDashoffset: '0',
    },
    '50%': {
      animationTimingFunction: 'steps(1)',
      strokeDashoffset: '-358',
    },
    '50.01%': {
      animationTimingFunction: 'linear',
      strokeDashoffset: '358',
    },
    to: {
      strokeDashoffset: '0',
    },
  },
  '@keyframes worm2': {
    from: {
      strokeDashoffset: '358',
    },
    '50%': {
      strokeDashoffset: '0',
    },
    to: {
      strokeDashoffset: '-358',
    },
  },
  ".MuiSelect-root.Mui-disabled": {
    backgroundColor: "#f0f2f5 !important",
    color: "#495057 !important",
  },
  ".MuiFormControl-root > .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f2f5 !important",
    color: "#495057 !important",
  },
  "@media print": {
    ".no-print, .no-print *, .material-icons-round": {
      display: "none !important"
    }
  },
  ".MuiDataGrid-columnHeaderTitle": {
    position: "relative",
    textAlign: "left",
    opacity: "0.7",
    background: "transparent",
    color: "#7b809a",
    borderRadius: "none",
    boxShadow: "none",
    fontSize: "0.65rem",
    fontWeight: "700",
    textTransform: "uppercase",
    cursor: "pointer",
    userSelect: "none",
  },
  ".MuiDataGrid-cellContent" : {
    textAlign: "left",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "24px",
    paddingRight: "24px",
    opacity: 1,
    background: "transparent",
    color: "#344767",
    borderRadius: "none",
    boxShadow: "none",
    fontSize: "0.875rem",
    borderBottom: "0.0625rem solid #f0f2f5",
  },
  ".MuiTablePagination-selectLabel, .MuiTablePagination-actions, .MuiTablePagination-displayedRows, .MuiTablePagination-select, .MuiDataGrid-selectedRowCount" : {
    margin: 0,
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: "0.875rem !important",
    fontWeight: "300 !important",
    lineHeight: 1.5,
    letterSpacing: "0.02857em",
    opacity: 1,
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "#7b809a",
  }
};

export default globals;
