import * as yup from 'yup';

const terminalModel = {
  mid: "",
  msisdn: "",
  tid : "",
  city : "",
  country : "",
  emailtid : "",
  deviceType : "",
  state : "",
  storeaddress : "",
  storename : "",
  name : ""
};

const terminalSchema = yup.object().shape({
  mid: yup.string()
      .required('Merchant Id is required.')
      .matches(/^[a-zA-Z0-9-]+$/, 'Invalid Merchant Id format.'),

  msisdn: yup.string()
    .required('Mobile Number is required.')
    .matches(/^[0-9]{10,13}$/, 'Invalid Mobile Number.'),

  tid: yup.string()
    .required('Terminal id is required.')
    .matches(/^[a-zA-Z0-9]{1,8}$/, 'Invalid Terminal id format.'),

  name: yup.string()
    // .required('Name is required.')
    // .matches(/^[a-zA-Z0-9]+[a-zA-Z0-9 ]+$/, 'Invalid Name format.')
    .max(15, 'Name can be at most 15 characters.'),

  city: yup.string()
    .optional()
    .notOneOf(['-1'], 'Please select a valid city.'),

  state: yup.string()
    .optional()
    .max(2, 'State can be at most 2 characters.'),

  country: yup.string()
    .optional()
    .max(2, 'Country can be at most 2 characters.'),

  storename: yup.string(),

  storeaddress: yup.string()
    .optional()
    .max(50, 'Store Address can be at most 50 characters.'),

  emailtid: yup.string()
    .optional()
    .email('Invalid email format.')
    .max(50, 'Email can be at most 50 characters.'),

  deviceType: yup.string()
    .required('Device type is required.')
    .notOneOf(['-1'], 'Please select a valid device type.')
});

export { terminalSchema, terminalModel };