import * as Yup from 'yup';

const binMasterSchema = Yup.object().shape({
  binCode: Yup.string()
    .required('Bin is required')
    .length(6, 'Bin must be exactly 6 characters long'),
  bankCode: Yup.string()
    .required('Bank is required')
    .notOneOf([''], 'Please select a bank'),
  cardTypeCode: Yup.string()
    .required('Card Type is required')
    .notOneOf([''], 'Please select a card type'),
  productTypeCode: Yup.string()
    .required('Product Type is required')
    .notOneOf([''], 'Please select a product type')
});

const mdrMasterSchema = Yup.object().shape({
  cardHoldercharger: Yup.string()
      .required('Card Holder Charge is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Card Holder Charge must be numeric with up to 2 decimal places'),
  merchantMDr: Yup.string()
      .required('Merchant MDR is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Merchant MDR must be numeric with up to 2 decimal places'),
  mid: Yup.string()
      .required('MID is required')
      .notOneOf([''], 'MID is required'),
});

const binMasterModel = {
  binCode: '',
  bankCode: '',
  cardTypeCode: '',
  productTypeCode: ''
};

const mdrMasterModel = {
  mid: '',
  cardHoldercharger: '',
  merchantMDr: '',
};

export {binMasterModel, binMasterSchema, mdrMasterSchema, mdrMasterModel};
