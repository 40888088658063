import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import ConfiguratorRoot from "../../examples/Configurator/ConfiguratorRoot";
import PropTypes from "prop-types";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import useApi from "../../components/Utils/api";
import toast from "react-hot-toast";


function UpdateUser({user, openConfigurator, setOpenConfigurator}) {
  const [updatedUser, setUpdatedUser] = useState(user);
  const api = useApi();

  useEffect(() => {
    setUpdatedUser(user)
  },[user])
  const updateUserFormHandler = (form) => {
    form.preventDefault();
    let updateUserReq = updatedUser;
    delete updateUserReq.authorities;
    if(user.passwd === updatedUser.passwd) {
      updateUserReq.passwd = '';
    }
    api.post("/users/update", updateUserReq)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          toast.success("User successfully updated.");
          setOpenConfigurator(false);
        }
      })
      .catch(() => {
        console.log(error.response);
      })
    console.log(updateUserReq);
  }
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Update User</MDTypography>
          <MDTypography variant="body2" color="text">
            {user?.userId}
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(false)}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form" onSubmit={(form) => updateUserFormHandler(form)} style={{maxWidth: '480px'}}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput type="text" label="Username" name="userId" onChange={(e) => setUpdatedUser({ ...updatedUser, userId: e.target.value })} value={updatedUser?.userId} focused fullWidth disabled />
          </MDBox>
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput type="text" label="Status" name="status" onChange={(e) => setUpdatedUser({ ...updatedUser, userId: e.target.value })} value={updatedUser?.status === "0" ? 'Inactive' : updatedUser?.status === "1" ? 'Active' : updatedUser?.status === "3" ? 'Block' : updatedUser?.status === "7" ? 'New User' : ''} focused fullWidth disabled />
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput type="text" label="First Name" name="first_name" onChange={(e) => setUpdatedUser({ ...updatedUser, firstName: e.target.value })} value={updatedUser?.firstName} focused fullWidth required />
          </MDBox>
          <MDBox flex={1} boxSizing={'border-box'}>
            <MDInput type="text" label="Last Name" name="last_name" onChange={(e) => setUpdatedUser({ ...updatedUser, lastName: e.target.value })} value={updatedUser?.lastName} focused fullWidth required />
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <MDBox flex={4} boxSizing={'border-box'} mr={1}>
            <MDInput type="email" label="Email" name="email" onChange={(e) => setUpdatedUser({ ...updatedUser, emailId: e.target.value })} value={updatedUser?.emailId} focused fullWidth required />
          </MDBox>
          <MDBox flex={1} boxSizing={'border-box'}>
            <MDInput type="text" label="Role" name="role" value={updatedUser?.role} fullWidth disabled focused />
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput type="tel" label="Mobile No" name="mobileNo" onChange={(e) => setUpdatedUser({ ...updatedUser, mobileNo: e.target.value })} value={updatedUser?.mobileNo} fullWidth focused disabled />
          </MDBox>
          <MDBox flex={1} boxSizing={'border-box'}>
            <MDInput type="text" label="Department" name="dept" onChange={(e) => setUpdatedUser({ ...updatedUser, dept: e.target.value })} value={updatedUser?.dept} fullWidth focused required />
          </MDBox>
        </MDBox>
        {/*<MDBox mb={2}>*/}
        {/*  <MDInput type="password" label="Password" name="passwd" onChange={(e) => setUpdatedUser({ ...updatedUser, passwd: e.target.value })} fullWidth />*/}
        {/*</MDBox>*/}

        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" style={{float : 'right'}}>
            update
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

UpdateUser.defaultProps = {
  user: [],
  openConfigurator: false,
  setOpenConfigurator: false
}

UpdateUser.propTypes = {
  user: PropTypes.object,
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func
}

export default UpdateUser;