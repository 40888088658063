import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import MDInput from "../../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { DateRangePicker } from "rsuite";
import { afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import reportsLineChartData from "../../dashboard/data/reportsLineChartData";
import Icon from "@mui/material/Icon";
import * as React from "react";
import BinConfigurator from "./binConfigurator/binConfigurator";
import PermissionControl from "../../../components/Utils/PermissionControl";

function BinMaster() {
  const { binMasterTableColumns } = data();
  const [masters, setMasters] = useState([]);
  const [master, setMaster] = useState({});
  const [openConfigurator, setOpenConfigurator] = useState(false);
  const api = useApi();

  function updateMasterHandler(item) {
    setMaster(item);
    setOpenConfigurator(true);
  }

  const getMasters = () => {
    api.get("master/browseBinMaster")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          const modifiedData = response.data.body.map(item => {
            const action = (
                <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "UpdateBinMasrter" }}>
                  <MDBox display="flex" alignItems="center" lineHeight={1}>
                    <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => updateMasterHandler(item)}>create</Icon>
                  </MDBox>
                </PermissionControl>
            );
            return { ...item, action };
          });
          setMasters(modifiedData);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        toast.error(error.response.data.body);
      })
  }


  useEffect(() => {
      getMasters();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid container justifyContent="flex-end">
            <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "CreateBinMasrter" }}>
              <MDButton variant="gradient" onClick={() => {
                setOpenConfigurator(true);
                setMaster({});
              }} color="info" style={{marginBottom: "12px"}}>
                Add Bin
              </MDButton>
            </PermissionControl>
          </Grid>
          <DataTable
            table={{ columns : binMasterTableColumns, rows : masters }}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
            canSearch={true}
            entriesPerPage={true}
          />
        </Grid>
      </MDBox>
      <BinConfigurator openConfigurator={openConfigurator} setOpenConfigurator={setOpenConfigurator} master={master} />
      <Footer />
    </DashboardLayout>
  );
}

export default BinMaster;
