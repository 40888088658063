/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import ProtectedRoute from "./components/Utils/ProtectedRoute";
import Audittrails from "./layouts/audittrails";
import Users from "./layouts/users";
import TransactionSummary from "./layouts/reports/TransactionSummary";
import TransactionReport from "./layouts/reports/TransactionReport";
import ResetPassword from "./layouts/resetpassword";
import Cover from "./layouts/authentication/reset-password/cover";
import ResetWithOtp from "./layouts/authentication/reset-password/reset-with-otp";
import Merchants from "./layouts/merchants";
import Single from "./layouts/merchants/single";
import ThirtyDaysActive from "./layouts/reports/30daysactive";
import CashTransactions from "./layouts/reports/CashTransactions";
import CardTransactions from "./layouts/reports/CardTransactions";
import TrackEmail from "./layouts/track/TrackEmail";
import TrackSms from "./layouts/track/TrackSms";
import UserLevelParams from "./layouts/web-user-params/user-level-params";
import RolesConfiguration from "./layouts/web-user-params/roles-configuration";
import FirstTime from "./layouts/authentication/firsttime-password/index";
import OnboardingFiles from "./layouts/reports/OnboardingFiles";
import CardSettlementSummary from "./layouts/reports/CardSettlementSummary";
import MerchantGroups from "./layouts/merchant-groups";
import MerchantGroupSingle from "./layouts/merchant-groups/single";
import NewMerchant from "./layouts/merchants/new";
import MdrMaster from "./layouts/masters/MdrMaster";
import MdrDefaultMaster from "./layouts/masters/MdrDefaultMaster";
import BinMaster from "./layouts/masters/BinMaster";
import PosTransactionReport from "./layouts/reports/PosTransactionReport";
import SettlementReport from "./layouts/reports/SettlementReport";
import RoutingRules from "./layouts/routing-rules";
import ResetImei from "./layouts/resetimeipin/resetImei";
import ResetPin from "./layouts/resetimeipin/resetPin";
import MerchantUsers from "./layouts/merchants/tabs/users";
import MerchantAllUsers from "./layouts/merchants/MerchantAllUsers";
import MerchantAllTerminals from "./layouts/merchants/MerchantAllTerminals";
import SettlementBatches from "./layouts/reports/SettlementBatches";
import i18next from './components/Utils/i18next';
const routes = [
  {
    type: "single",
    name: 'dashboard',
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <ProtectedRoute><Dashboard /></ProtectedRoute>,
    doShowInSidebar: true,
    module: "Dashboard",
    subModule: "Admin",
  },
  {
    type: "collapse",
    name: "web_user_parameters",
    key: "web-user-parameters",
    icon: <Icon fontSize="small">web</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "collapse",
        name: "user_level_parameters",
        key: "user-level-parameters",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/user-level-parameters",
        component: <ProtectedRoute><UserLevelParams /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Role",
        subModule: "Browse",
      },
      {
        type: "collapse",
        name: "roles_configuration",
        key: "roles-configuration",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/roles-configuration",
        component: <ProtectedRoute><RolesConfiguration /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Permission",
        subModule: "Browse",
      },
    ]
  },
  {
    type: "collapse",
    name: "merchants",
    key: "merchant-for-admin",
    icon: <Icon fontSize="small">store</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "collapse",
        name: "onboarding_files",
        key: "onboarding-files",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/onboarding-files",
        component: <ProtectedRoute><OnboardingFiles /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "Reports",
      },
      {
        type: "collapse",
        name: "merchant_groups",
        key: "merchant-groups",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/merchant-groups",
        component: <ProtectedRoute><MerchantGroups /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "MerchantGroup",
        subModule: "Browse",
      },
      {
        type: "collapse",
        name: "merchants",
        key: "merchants",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/merchants",
        component: <ProtectedRoute><Merchants /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Merchants",
        subModule: "Browse",
      },
      {
        type: "collapse",
        name: "merchant_users",
        key: "merchant-users",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/merchant-users",
        component: <ProtectedRoute><MerchantAllUsers /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "User",
        subModule: "BrowseMerchantUser",
      },
      {
        type: "collapse",
        name: "merchant_terminals",
        key: "merchant-terminals",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/merchant-terminals",
        component: <ProtectedRoute><MerchantAllTerminals /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Terminal",
        subModule: "Browse",
      },
    ]
  },
  {
    type: "single",
    name: "merchant",
    key: "merchant",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/merchants/:mid",
    component: <ProtectedRoute><Single /></ProtectedRoute>,
    doShowInSidebar: false,
    module: "Merchants",
    subModule: "BrowseMerchant",
  },
  {
    type: "single",
    name: "new_merchant",
    key: "new_merchant",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/merchants/new",
    component: <ProtectedRoute><NewMerchant /></ProtectedRoute>,
    doShowInSidebar: false,
    module: "Merchants",
    subModule: "Create",
  },
  {
    type: "single",
    name: "merchant_groups",
    key: "merchant-groups",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/merchant-group/single",
    component: <ProtectedRoute><MerchantGroupSingle /></ProtectedRoute>,
    doShowInSidebar: false,
    module: "MerchantGroup",
    subModule: "Browse",
  },
  {
    type: "single",
    name: "audit_trails",
    key: "audit-trails",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/audit-trails",
    component: <ProtectedRoute><Audittrails /></ProtectedRoute>,
    doShowInSidebar: true,
    module: "Audit",
    subModule: "Browse",
  },
  {
    type: "single",
    name: "reset_password",
    key: "reset-password",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/reset-password",
    component: <ProtectedRoute><ResetPassword /></ProtectedRoute>,
    doShowInSidebar: false,
    module: "Authentication",
    subModule: "AuthResetPassword",
  },
  {
    type: "single",
    name: "users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <ProtectedRoute><Users /></ProtectedRoute>,
    doShowInSidebar: true,
    module: "User",
    subModule: "BrowseMakerUser",
  },
  {
    type: "single",
    name: "routing_rules",
    key: "routing-rules",
    icon: <Icon fontSize="small">rule</Icon>,
    route: "/routing-rules",
    component: <RoutingRules />,
    doShowInSidebar: true,
    module: "RoutingRule",
    subModule: "Browse",
  },
  {
    type: "collapse",
    name: "reset_activity",
    key: "reset-activity",
    icon: <Icon fontSize="small">pin</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "single",
        name: "reset_imei",
        key: "reset-imei",
        icon: <Icon fontSize="small">pin</Icon>,
        route: "/reset-imei",
        component: <ResetImei/>,
        doShowInSidebar: true,
        module: "Reset",
        subModule: "Imei",
      },
      {
        type: "single",
        name: "reset_pin",
        key: "reset-pin",
        icon: <Icon fontSize="small">pin</Icon>,
        route: "/reset-pin",
        component: <ResetPin />,
        doShowInSidebar: true,
        module: "Reset",
        subModule: "AppPassword",
      },
    ]
  },
  {
    type: "collapse",
    name: "masters",
    key: "masters",
    icon: <Icon fontSize="small">cell_tower</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "collapse",
        name: "mdr_master",
        key: "mdr-master",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/mdr-master",
        component: <ProtectedRoute><MdrMaster /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Masters",
        subModule: "BrowseMdrMaster",
      },
      {
        type: "collapse",
        name: "mdr_default_master",
        key: "mdr--default-master",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/mdr-default-master",
        component: <ProtectedRoute><MdrDefaultMaster /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Masters",
        subModule: "BrowseDefaultMdrMaster",
      },
      {
        type: "collapse",
        name: "bin_master",
        key: "bin-master",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/bin-master",
        component: <ProtectedRoute><BinMaster /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Masters",
        subModule: "BrowseBinMaster",
      },
    ]
  },
  {
    type: "collapse",
    name: "track",
    key: "track",
    icon: <Icon fontSize="small">troubleshoot</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "collapse",
        name: "track_sms",
        key: "track-sms",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/track-sms",
        component: <ProtectedRoute><TrackSms /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Tracking",
        subModule: "Sms",
      },
      {
        type: "collapse",
        name: "track_email",
        key: "track-email",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/track-email",
        component: <ProtectedRoute><TrackEmail /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Tracking",
        subModule: "Email",
      },
    ]
  },
  {
    type: "collapse",
    name: "reports",
    key: "reports",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    doShowInSidebar: true,
    collapse: [
      {
        type: "collapse",
        name: "transaction_summary",
        key: "transaction-summary",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/transaction-summary",
        component: <ProtectedRoute><TransactionSummary /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "TransactionSummary",
      },
      // {
      //   type: "collapse",
      //   name: "Transactions Report",
      //   key: "transaction-report",
      //   icon: <Icon fontSize="small">reports</Icon>,
      //   route: "/transaction-report",
      //   component: <ProtectedRoute><TransactionReport /></ProtectedRoute>,
      //   doShowInSidebar: true,
      //   module: "Reports",
      //   subModule: "TotalTransactions",
      // },
      {
        type: "collapse",
        name: "active_users",
        key: "active-users",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/active-users",
        component: <ProtectedRoute><ThirtyDaysActive /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "ActiveUsers",
      },
      {
        type: "collapse",
        name: "transaction_details",
        key: "transaction-details",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/transaction-details",
        component: <ProtectedRoute><CardTransactions /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "CardTransactions",
      },
      {
        type: "collapse",
        name: "cash_transactions",
        key: "cash-transactions",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/cash-transactions",
        component: <ProtectedRoute><CashTransactions /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "Transactions",
      },
      {
        type: "collapse",
        name: "settlement_summary",
        key: "settlement",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/settlement",
        component: <ProtectedRoute><CardSettlementSummary /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "CardSettlementSummary",
      },
      {
        type: "collapse",
        name: "settlement_batches",
        key: "settlement-batches",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/settlement-batches",
        component: <ProtectedRoute><SettlementBatches /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "CardSettlementSummary",
      },
      {
        type: "collapse",
        name: "pos_transaction_report",
        key: "pos-transaction-report",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/pos-transaction-report",
        component: <ProtectedRoute><PosTransactionReport /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "PosTransactions",
      },
      {
        type: "collapse",
        name: "settlement_report",
        key: "pos-settlement-report",
        icon: <Icon fontSize="small">reports</Icon>,
        route: "/pos-settlement-report",
        component: <ProtectedRoute><SettlementReport /></ProtectedRoute>,
        doShowInSidebar: true,
        module: "Reports",
        subModule: "FTSZLSettlement",
      },
    ],
  },
  {
    type: "single",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/login",
    component: <SignIn />,
    doShowInSidebar: false,
  },
  {
    type: "single",
    name: "forgot_password",
    key: "forgot-password",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/forgot-password",
    component: <Cover />,
    doShowInSidebar: false,
  },
  {
    type: "single",
    name: "Forgot Password",
    key: "first-password",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/first-password",
    component: <FirstTime />,
    doShowInSidebar: false,
  },
  {
    type: "single",
    name: "reset_password",
    key: "reset",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/reset",
    component: <ResetWithOtp />,
    doShowInSidebar: false,
  }
];

export default routes;
