/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MerchantOfficeDetails from "./tabs/merchantOffficeDetails";
import MerchantBankDetails from "./tabs/merchantBankDetails";
import MerchantOtherDetails from "./tabs/merchantOtherDetails";
import MerchantEstablishmentDeclaration from "./tabs/merchantEstablishmentDeclaration";
import MerchantTerminals from "./tabs/terminals";
import MerchantUsers from "./tabs/users";
import MDAvatar from "../../components/MDAvatar";
import Icon from "@mui/material/Icon";
import MDConfirmAlert from "../../components/MDConfirmAlert";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { merchantSchema } from "../../models/merchantModel";
import CustomTabPanel from '../../components/CustomTabPanel';
import PermissionControl from "../../components/Utils/PermissionControl";
import usePermission from "../../components/Utils/usePermission";
import {useTranslation} from "react-i18next";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function Single() {
  const {t} = useTranslation();
  const {state} = useLocation();
  const [merchant, setMerchant] = React.useState({});
  const [merchantInner, setMerchantInner] = React.useState({});
  const [merchantbk, setMerchantbk] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [innervalue, setInnerValue] = React.useState(0);
  const [editMode, setEditMode] = React.useState(false);
  const permission = usePermission();
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(merchantSchema),
    defaultValues: merchantInner
  });
  const [requiredInfo, setRequiredInfo] = React.useState({
    cityList : [],
    referenceCompany : [],
    microAtm : []
  })

  const handleChange = (event, newValue) => {
    if (editMode === true && (JSON.stringify(merchant) !== JSON.stringify(merchantbk))) {
      toast.custom((t) => (
        <MDConfirmAlert
          text={t('unsaved_changes_message')}
          toast={toast}
          t={t}
          confirmFunction={() => {
            toast.dismiss(t.id);
            updateMerchant();
            setValue(newValue);
            if(state != null && state.companyId){
              getSingleMerchant(state.companyId);
            }
          }}
          cancelFunction={() => {
            toast.dismiss(t.id);
            if(state != null && state.companyId){
              getSingleMerchant(state.companyId);
            }
            setEditMode(false);
            setValue(newValue);
          }}
        />
      ), {
        duration: Infinity,
      });
      return;
    }
    setValue(newValue);
    setEditMode(false);
  };
  function convertNullToEmpty(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === '') {
        obj[key] = '';
      } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        // Recursive call if the value is another object
        convertNullToEmpty(obj[key]);
      }
    }
    return obj;
  }

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };
  const api = useApi();
  const getSingleMerchant = (companyId) => {
    const data = {
      companyId: companyId
    };
    api.post("merchant/single",data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          let result = response.data.body;
          convertNullToEmpty(result);
          setMerchant(result);
          setMerchantbk(result);
          setRequiredInfo({
            cityList : result.cityList,
            referenceCompany : result.referenceCompany,
            microAtm : result.microAtm
          });
          // result.merchant.mccCode = result.merchant.merchantCategoryCode.merchantCode;
          reset(result.merchant);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
        console.log(error.response.data);
      })
  }
  const approveMerchant = () => {
    api.put("merchant/"+merchant.merchant.mid+"/approve")
        .then((response) => {
          if(response.data.statusCodeValue === 200) {
            toast.success(response.data.body.message);
            getSingleMerchant(merchant.merchant.companyId);
          } else {
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        })
  }
  const formSubmit = () => {
    handleSubmit(updateMerchant)();
  }
  const updateMerchant = (data) => {
    let formData = new FormData();

    if (data.supportingDocuments && data.supportingDocuments.length > 0) {
      data.supportingDocuments.forEach((file, index) => {
        for (let i = 0; i < file.length; i++) {
          formData.append('supportingDocuments', file[i]);
        }
      });
    }
    data.merchantBankDetails = data.merchantBankDetails.filter(bankdetails => bankdetails.currency !== ""
        && bankdetails.bankName !== ""
        && bankdetails.bankCode !== ""
        && bankdetails.branch !== ""
        && bankdetails.accountNumber !== "");
    delete data.supportingDocuments;
    formData.append('merchantMaster', JSON.stringify(data));
    api.post("merchant/update", formData)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          setEditMode(false);
          toast.success(response.data.body.message);
          if(state != null && state.companyId){
            getSingleMerchant(state.companyId);
          }
          setEditMode(false);
          // window.location.reload();
        }
      })
      .catch((error)  => {
        cancelEditMode();
      });
  }
  const cancelEditMode = () => {
    if(state != null && state.companyId){
      getSingleMerchant(state.companyId);
    }
    setEditMode(false);
  }
  useEffect(() => {
    if(state != null && state.companyId){
      getSingleMerchant(state.companyId);
    }
  }, [state]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={t('basic_info')} {...a11yProps(0)} />
                {permission("Terminal", "Browse") ? (
                    <Tab label={t('terminals')} {...a11yProps(1)} />
                ) : (
                    <></>
                )}
                <Tab label={t('users')} {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomTabPanel value={value} index={0}>
              <Grid container justifyContent="flex-end">
                {merchant.merchant != undefined && merchant?.merchant.status === "2" && <MDButton variant="outlined" color="info" onClick={() => approveMerchant()} style={{marginBottom: "12px", marginRight: "10px"}}>
                  <Icon fontSize="lg" style={{ marginRight: "2px" }}>check_circle</Icon>  {t('approve')}
                </MDButton>}
                {editMode === false &&
                    <PermissionControl permission={{ restrict: true, module: "Merchants", subModule: "Update" }}>
                    <MDButton variant="outlined" color="info" onClick={() => setEditMode(true)} style={{marginBottom: "12px"}}>
                      <Icon fontSize="lg">mode-edit</Icon> {t('edit')}
                    </MDButton>
                    </PermissionControl>
                }
                {editMode === true && (
                  <>
                    <MDButton variant="outlined" color="info" onClick={() => cancelEditMode()} style={{marginRight: "24px", marginBottom: "12px"}}>
                      {t('cancel')}
                    </MDButton>
                    <MDButton variant="outlined" color="info" onClick={() => formSubmit()} style={{marginBottom: "12px"}}>
                      {t('save')}
                    </MDButton>
                  </>
                )}
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={innervalue}
                  onChange={handleInnerChange}
                  aria-label="Basic info inner tabs"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab
                    sx={{
                      fontSize: {
                        xxl: '1rem',
                        lg: '0.9rem',
                        md: '0.9rem',
                        sm: '0.8rem',
                        xs: '0.6rem'
                      }
                    }}
                    label={t('merchant_office_details')}
                    {...a11yProps(0)} />
                  <Tab
                    sx={{
                      fontSize: {
                        xxl: '1rem',
                        lg: '0.9rem',
                        md: '0.9rem',
                        sm: '0.8rem',
                        xs: '0.6rem'
                      }
                    }}
                    label={t('merchant_bank_details')} {...a11yProps(1)} />
                  <Tab
                    sx={{
                      fontSize: {
                        xxl: '1rem',
                        lg: '0.9rem',
                        md: '0.9rem',
                        sm: '0.8rem',
                        xs: '0.6rem'
                      }
                    }}
                    label={t('other_details')} {...a11yProps(2)} />
                  {/*<Tab*/}
                  {/*  sx={{*/}
                  {/*    fontSize: {*/}
                  {/*      xxl: '1rem',*/}
                  {/*      lg: '0.9rem',*/}
                  {/*      md: '0.9rem',*/}
                  {/*      sm: '0.8rem',*/}
                  {/*      xs: '0.6rem'*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  label="MERCHANT ESTABLISHMENT DECLARATION" {...a11yProps(3)} />*/}
                </Tabs>
              </Box>
              <Grid item xs={12} md={12} lg={12}>
                <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, requiredInfo, control }}>
                  <CustomTabPanel value={innervalue} index={0} style={{backgroundColor: 'white'}}>
                    <MerchantOfficeDetails merchant={merchant} editmode={editMode} setMerchant={setMerchant} type="update" />
                  </CustomTabPanel>
                  <CustomTabPanel value={innervalue} index={1} style={{backgroundColor: 'white'}}>
                    <MerchantBankDetails merchant={merchant} editmode={editMode} setMerchant={setMerchant} />
                  </CustomTabPanel>
                  <CustomTabPanel value={innervalue} index={2} style={{backgroundColor: 'white'}}>
                    <MerchantOtherDetails merchant={merchant} editmode={editMode} setMerchant={setMerchant} />
                  </CustomTabPanel>
                  {/*<CustomTabPanel value={innervalue} index={3} style={{backgroundColor: 'white'}}>*/}
                  {/*  <MerchantEstablishmentDeclaration merchant={merchant} editmode={editMode} setMerchant={setMerchant} />*/}
                  {/*</CustomTabPanel>*/}
                </FormProvider>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <MerchantTerminals merchant={merchant} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <MerchantUsers merchant={merchant} />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Single;
