// ValidationProvider.jsx
import React, { useState } from 'react';
import ValidationContext from './index';

// eslint-disable-next-line react/prop-types
const ValidationProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  const validateInput = (type, name, value) => {
    let updatedErrors = { ...errors };
    switch (type) {
      case 'NAME_BASED':
        switch (name) {
          case "referenceId":
            if (!/^[a-zA-Z0-9]{0,5}$/.test(value)) {
              updatedErrors.referenceId = 'Invalid Reference Code';
            } else {
              delete updatedErrors.referenceId;  // remove the error if it's valid now
            }
            break;
          case "referenceCompanyName":
            if (!/^[a-zA-Z0-9!@#$&()-`.+,/" ]*$/.test(value) || value.length > 50) {
              updatedErrors.referenceCompanyName = 'Please Enter Valid Reference Company Name';
            } else {
              delete updatedErrors.referenceCompanyName;  // remove the error if it's valid now
            }
            break;
          case "ownerName":
            if (value === "" || !/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 20) {
              updatedErrors.ownerName = 'Invalid Owner Name';
            } else {
              delete updatedErrors.ownerName;  // remove the error if it's valid now
            }
            break;
          case "pincode":
            if (!/^[0-9]{6}$/.test(value)) {
              updatedErrors.pincode = 'Invalid Pin Code';
            } else {
              delete updatedErrors.pincode;  // remove the error if it's valid now
            }
            break;
          case "commissionOnDebit":
            if (!/^[0-9]+(\.[0-9]{0,2})?%?$/.test(value)) {
              updatedErrors.commissionOnDebit = 'Invalid Commission On Debit Value';
            } else {
              delete updatedErrors.commissionOnDebit;  // remove the error if it's valid now
            }
            break;
          case "commissionOnCredit":
            if (!/^[0-9]+(\.[0-9]{0,2})?%?$/.test(value)) {
              updatedErrors.commissionOnCredit = 'Invalid Commission On Credit Value';
            } else {
              delete updatedErrors.commissionOnCredit;  // remove the error if it's valid now
            }
            break;
          case "senderId":
            if (!/^[0-9]{6}$/.test(value)) {
              updatedErrors.senderId = 'Invalid Sender Id';
            } else {
              delete updatedErrors.senderId;  // remove the error if it's valid now
            }
            break;
          case "mid":
            if (value === "" || !/^[a-zA-Z0-9]{15}$/.test(value)) {
              updatedErrors.mid = 'Invalid Merchant Id';
            } else {
              delete updatedErrors.mid;
            }
            break;

          case "companyName":
            if (!value || !/^[a-zA-Z0-9!@#$&()-`.+,/\"]*$/.test(value) || value.length > 50) {
              updatedErrors.companyName = 'Invalid Registered Business Name';
            } else {
              delete updatedErrors.companyName;
            }
            break;

          case "typeEstablishment":
            if (value === "" || value === "-1") {
              updatedErrors.typeEstablishment = 'Please select a valid Type of Entity';
            } else {
              delete updatedErrors.typeEstablishment;
            }
            break;

          case "address":
          case "addressRes":
            if (value === "" || value.length > 200) {
              updatedErrors[name] = 'Address length exceeds the limit';
            } else {
              delete updatedErrors[name];
            }
            break;

          case "pinCode":
            if (!/^[0-9]{6}$/.test(value)) {
              updatedErrors.pinCode = 'Invalid Zip Code';
            } else {
              delete updatedErrors.pinCode;
            }
            break;

          case "mccCode":
            if (value === "-1" || value === "") {
              updatedErrors.mccCode = 'Please select a valid Merchant Category Code';
            } else {
              delete updatedErrors.mccCode;
            }
            break;
          case "abn":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 30) {
              updatedErrors.abn = 'Invalid ABN';
            } else {
              delete updatedErrors.abn;
            }
            break;

          case "acn":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 30) {
              updatedErrors.acn = 'Invalid ACN';
            } else {
              delete updatedErrors.acn;
            }
            break;

          case "establishmentYrs":
            if (!/^[0-9]{4}$/.test(value)) {
              updatedErrors.establishmentYrs = 'Invalid Year. Please use the format XXXX';
            } else {
              delete updatedErrors.establishmentYrs;
            }
            break;

          case "saleTaxNo":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 10) {
              updatedErrors.saleTaxNo = 'Invalid Sales Tax No';
            } else {
              delete updatedErrors.saleTaxNo;
            }
            break;
          case "authorizedPerson":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 20) {
              updatedErrors.authorizedPerson = 'Invalid Authorized Person Name';
            } else {
              delete updatedErrors.authorizedPerson;
            }
            break;

          case "mobileNo":
            if (value === "" || !/^[0-9]{10,13}$/.test(value)) {
              updatedErrors.mobileNo = 'Invalid Mobile Number';
            } else {
              delete updatedErrors.mobileNo;
            }
            break;

          case "email":
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (value === "" || !emailPattern.test(value)) {
              updatedErrors.email = 'Invalid Email';
            } else {
              delete updatedErrors.email;
            }
            break;
          case "faxNo":
            if (!/^[0-9]{1,13}$/.test(value)) {
              updatedErrors.faxNo = 'Invalid FAX number';
            } else {
              delete updatedErrors.faxNo;
            }
            break;

          case "officeNo":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 13) {
              updatedErrors.officeNo = 'Invalid Office Number';
            } else {
              delete updatedErrors.officeNo;
            }
            break;

          case "tinNo":
            if (!/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value) || value.length > 10) {
              updatedErrors.tinNo = 'Invalid TIN number';
            } else {
              delete updatedErrors.tinNo;
            }
            break;

          case "groupMid":
            if (value === "" || !/^[a-zA-Z0-9]{15}$/.test(value)) {
              updatedErrors.groupMid = 'Invalid Group MID';
            } else {
              delete updatedErrors.groupMid;
            }
            break;
          case "microAtmStatus":
            if (value === "-1") {
              updatedErrors.microAtmStatus = 'Please select a valid Master Type';
            } else {
              delete updatedErrors.microAtmStatus;
            }
            break;

          case "walletBalance":
            if (!/^[0-9]+(\.[0-9]{1,10})?$/.test(value) || value.length > 10) {
              updatedErrors.walletBalance = 'Invalid Wallet Balance';
            } else {
              delete updatedErrors.walletBalance;
            }
            break;

          case "walletAccountNo":
            if (value !== "" && !/^[a-zA-Z0-9]{9,32}$/.test(value)) {
              updatedErrors.walletAccountNo = 'Invalid Wallet Account Number';
            } else {
              delete updatedErrors.walletAccountNo;
            }
            break;
        }
        break;
      case 'TYPE_BASED':
        break;
    }
    setErrors(updatedErrors);
  };

  return (
    <ValidationContext.Provider value={{ validateInput, errors, setErrors }}>
      {children}
    </ValidationContext.Provider>
  );
};

export default ValidationProvider;
