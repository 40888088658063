import React, { useState } from "react";
import { Card, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Typography, InputAdornment } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import useApi from "components/Utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";

// Restricted words for password validation
const restrictedWords = ["password", "admin", "welcome", "test", "1234"];

// Password validation schema
const validationSchema = yup.object().shape({
  new_password: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(30, "Password must not exceed 30 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()_+\/~{}[\]:\";'<>?,.\/]).*$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .test(
      "restricted-words",
      "Password should not contain common words like 'password', 'admin', 'welcome', 'test', or '1234'.",
      function (value) {
        if (!value) return true;
        const lowerValue = value.toLowerCase();
        return !restrictedWords.some((word) => lowerValue.includes(word));
      }
    ),
  confirm_new_password: yup
    .string()
    .required("Confirm New Password is required")
    .oneOf([yup.ref("new_password"), null], "Confirm New Password should match New Password"),
});

function FirstTime() {
  const navigate = useNavigate();
  const api = useApi();
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [dialogOpen, setDialogOpen] = useState(false); // State for Dialog visibility

  // Initialize useForm with validation schema
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema), // Connect the validation schema with the form
    mode: "onChange", // Trigger validation on change
  });

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Open Dialog for password criteria
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Close Dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Form submission handler
  const formHandler = (data) => {
    const { new_password, confirm_new_password } = data;

    if (new_password !== confirm_new_password) {
      toast.error("Confirm New Password should be the same as New Password.");
      return;
    }

    const requestData = {
      new_password,
    };

    api.post("auth/admin/first-password", requestData)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          toast.success(response.data.body);
          navigate("/login", { state: requestData });
        } else {
          toast.error(response.data.body);
        }
      })
      .catch((error) => {
        if (error.response.data.body) {
          toast.error(error.response.data.body);
        }
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(formHandler)}>
            <MDBox mb={4}>
              {/* New Password Input with Info and Eye Icons */}
              <MDInput
                type={showPassword ? "text" : "password"}
                label="New Password"
                name="new_password"
                variant="standard"
                fullWidth
                required
                {...register("new_password")} // Register field for validation
                error={!!errors.new_password} // Show error state
                helperText={errors.new_password?.message} // Show error message below the field
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* Eye Icon for toggling visibility */}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        style={{
                          background: "transparent",
                          borderRadius: "0",
                          boxShadow: "none",
                          padding: 0,
                          marginRight: "10px",
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>

                      {/* Info Icon */}
                      <Tooltip title="Password Criteria: Click for details" placement="top">
                        <IconButton
                          aria-label="password criteria"
                          style={{
                            background: "linear-gradient(135deg, #4c9aff 30%, #007aff 90%)",
                            color: "#ffffff",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                            padding: "0px",
                            width: "31px",
                            height: "28px",
                          }}
                          onClick={handleDialogOpen} // Open Dialog on Click
                        >
                          <InfoIcon style={{ fontSize: "18px" }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox mb={4}>
              {/* Confirm New Password Input without Icons */}
              <MDInput
                type="password"
                label="Confirm New Password"
                variant="standard"
                name="confirm_new_password"
                fullWidth
                required
                {...register("confirm_new_password")} // Register field for validation
                error={!!errors.confirm_new_password} // Show error state
                helperText={errors.confirm_new_password?.message} // Show error message below the field
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>

        {/* Password Criteria Dialog */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="password-criteria-title"
          PaperProps={{
            style: {
              borderRadius: "16px",
              padding: "16px",
              minWidth: "400px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <DialogTitle id="password-criteria-title" style={{ textAlign: "center", fontWeight: "bold" }}>
            Password Criteria
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom style={{ fontSize: "0.875rem" }}>
              Recommended passwords with a length of 8-12 characters.
            </Typography>
            <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
              <ul style={{ paddingLeft: "20px", marginTop: "0", fontSize: "0.75rem" }}>
                <li>At least 1 uppercase letter (A-Z)</li>
                <li>At least 1 lowercase letter (a-z)</li>
                <li>At least 1 number (0-9)</li>
                <li>At least 1 special character (e.g., ! @ # $)</li>
              </ul>
            </Typography>
          </DialogContent>
        </Dialog>
      </Card>
    </BasicLayout>
  );
}

export default FirstTime;
