/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import useApi from "../../../../components/Utils/api";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import {useEffect} from "react";

function Cover() {
  const api = useApi();
  const navigate = useNavigate();
  const { state } = useLocation();


  useEffect(() => {
    if (state !== null && state.hasOwnProperty("username")){
      const form = {
        preventDefault: () => console.log("fake prevent default"),
        target: {
          username: {
            value: state.username
          }
        }
      }
      formHandler(form);
    }
  }, [state])
  const formHandler = (form) => {
    form.preventDefault();
    // console.log(form.target.username.value);
    const data = {
      username: form.target.username.value
    }

    api.post("auth/admin/forgot-password", data)
      .then(response => {
        if(response.data.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/reset", { state: data });
          }, 2000)
        }
      })
      .catch(error => {
        if(error.response.data.message){
          toast.error(error.response.data.message)
        }
      })
  }


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive a sms in maximum 60 seconds on your registered mobile number.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(form) => formHandler(form)}>
            <MDBox mb={4}>
              <MDInput type="test" label="Username" name="username" variant="standard" fullWidth required />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
