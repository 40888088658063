/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import BulkAssignMerchant from "./bulk-assign";
import PermissionControl from "../../components/Utils/PermissionControl";
import {useTranslation} from "react-i18next";

function Merchants() {
    const {t} = useTranslation();
  const { merchantTablecolumns } = data();
  const [merchants, setMerchants] = useState([]);
  const [unAssignedMerchants, setUnAssignedMerchants] = useState([]);
  const [merchantGroups, setMerchantGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const api = useApi();
  const getMerchants = () => {
    const data = {
      "ownerName": "",
      "mid": "",
      "referenceId": ""
    };
    api.post("merchant/browse",data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setMerchants(response.data.body);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
          if (error.response && error.response.status === 403) {
              toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
              return;
          }
        console.log(error.response.data);
      })
  }
    const getMerchantGroups = () => {
        api.get("admin/browseMerchantGroup")
            .then((response) => {
                if(response.data.statusCodeValue === 200) {
                    setMerchantGroups(response.data.body);
                } else {
                    toast.error(response.data.error);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
                    return;
                }
                console.log(error.response.data);
            })
    }
    const getUnAssignedMerchants = () => {
        const data = {
            "ownerName": "",
            "mid": "",
            "referenceId": "RC000000000"
        };
        api.post("merchant/browse",data)
            .then((response) => {
                if(response.data.statusCodeValue === 200) {
                    setUnAssignedMerchants(response.data.body);
                } else {
                    toast.error(response.data.error);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
                    return;
                }
                console.log(error.response.data);
            })
    }

  useEffect(() => {
    getMerchants();
    getUnAssignedMerchants();
    getMerchantGroups();
  },[]);

  const rowHandler = (row, pageRow)  => {
    // console.log("triggered", pageRow)
    navigate(`/merchants/${pageRow.original.mid}`, {
      state: pageRow.original
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid container justifyContent="flex-end">
              <PermissionControl permission={{ restrict: true, module: "Merchants", subModule: "AssignToGroup" }}>
                  <MDButton variant="gradient" onClick={() => setOpen(true)} color="info" style={{marginRight: "24px", marginBottom: "12px"}}>
                      {t('assign_to_merchant_group')}
                  </MDButton>
              </PermissionControl>
              <PermissionControl permission={{ restrict: true, module: "Merchants", subModule: "Create" }}>
                <MDButton variant="gradient" onClick={() => navigate('/merchants/new')} color="info" style={{marginRight: "24px", marginBottom: "12px"}}>
                    {t('add_merchant')}
                </MDButton>
              </PermissionControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <DataTable
              table={{ columns : merchantTablecolumns, rows : merchants }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
              onRowClicked={rowHandler}
            />
          </Grid>
        </Grid>
      </MDBox>
        <BulkAssignMerchant setOpen={setOpen} open={open} unAssignedMerchants={unAssignedMerchants} merchantGroups={merchantGroups} getUnAssignedMerchants={getUnAssignedMerchants} />
      <Footer />
    </DashboardLayout>
  );
}

export default Merchants;
