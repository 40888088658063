import * as yup from 'yup';
import i18next from "../components/Utils/i18next";
const merchantUserModel = {
  groupMid: "",
  role: "",
  userId : "",
  firstName : "",
  lastName : "",
  emailId : "",
  mobileNo : "",
  dept : ""
};

const merchantUserSchema = yup.object().shape({
  userId: yup.string()
    .required(() => i18next.t('please_enter_valid_username'))
    .min(6, i18next.t('username_should_least_characters'))
    .max(15, i18next.t('username_should_exceed_characters'))
    .matches(/^[0-9A-Za-z ]{1,30}$/, i18next.t('invalid_username_format')),

  firstName: yup.string()
    .required(() => i18next.t('please_enter_first_name'))
    .max(20, i18next.t('first_name_exceed_characters'))
    .matches(/^[0-9A-Za-z ]{1,30}$/, i18next.t('first_name_invalid')),

  lastName: yup.string()
    .required(() => i18next.t('please_enter_last_name'))
    .max(20, i18next.t('last_name_exceed_characters'))
    .matches(/^[0-9A-Za-z ]{1,30}$/, i18next.t('last_name_invalid')),

  mobileNo: yup.string()
    .required(() => i18next.t('mobile_number_required'))
    .matches(/^[0-9]{9,13}$/, i18next.t('mobile_number_should_between')),

  emailId: yup.string()
    .email(() => i18next.t('email_invalid'))
    .max(30, i18next.t('email_exceed')),

  dept: yup.string(),

  role: yup.string().required(() => i18next.t('role_required')),

  groupMid: yup.string()
    .when('usrLvlCode', {
      is: 'MERCHANT',
      then: yup.string()
        .required(() => i18next.t('group_mid_required'))
        .matches(/^[a-zA-Z0-9]{15}$/, i18next.t('invalid_group_mid')),
    }),
});

export { merchantUserSchema, merchantUserModel };