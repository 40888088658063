import axios from 'axios';
import {useMaterialUIController} from "../../context";

const baseUrl = process.env.REACT_APP_BASE_URL;
const api = axios.create({
    baseURL: baseUrl+'/api/adminPortal/v1/', // Replace this with your API's base URL
});

// List of URLs that should not include the authorization header
const excludeAuthorizationUrls = [
    'auth/admin/authenticate',
    'auth/admin/forgot-password',
    'auth/admin/reset-password',
    'auth/admin/refresh',
];

const useApi = () => {
    const [controller] = useMaterialUIController();
    const {
        token,
    } = controller;

    const setAuthToken = () => {
        if (token && token !== 'undefined') {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete api.defaults.headers.common['Authorization'];
        }
    };

    setAuthToken();

    // Add a request interceptor to add a loading class to the body when the request starts
    api.interceptors.request.use((config) => {
        document.body.classList.add('loading-indicator');

        // Check if the URL should exclude the authorization header
        if (excludeAuthorizationUrls.some(url => config.url.includes(url))) {
            delete config.headers['Authorization'];
        }

        return config;
    }, (error) => {
        document.body.classList.remove('loading-indicator');
        return Promise.reject(error);
    });

    // Add a response interceptor to remove the loading class when the request ends
    api.interceptors.response.use((response) => {
        if (response.status === 406 && response.data.error === "license_expired"){
            document.body.classList.add('license-indicator');
        }
        document.body.classList.remove('loading-indicator');
        return response;
    }, (error) => {
        if (error.response.status === 406 && error.response.data.error === "license_expired"){
            document.body.classList.add('license-indicator');
        }
        document.body.classList.remove('loading-indicator');
        return Promise.reject(error);
    });

    return api;
};

export default useApi;
