/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import useApi from "../../components/Utils/api";
import data from "../dashboard/components/Projects/data";
import MDBox from "../../components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";


function ResetPin() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const { mposUsersTablecolumns } = data();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const api = useApi();

  useEffect(() => {
      getAllMposUsers();
  }, []);
    const resetAppPassword = (item) => {
        setOpenPasswordDialog(true);
        setUser(item);
    }
    const getAllMposUsers = () => {
        const data = {
            userId: '',
            groupMid: '',
            referenceId: '',
            mobileNo: ''
        }
        api.post("/submerchant/browse", data)
            .then(response => {
              if(response.data.statusCodeValue === 200) {
                const modifiedData = response.data.body.map(item => {
                  const action = (
                      <MDBox display="flex" alignItems="center" lineHeight={1}>
                        <Icon title="Reset App Password" fontSize="small" style={{cursor : 'pointer'}} onClick={() => resetAppPassword(item)}>lock_reset</Icon>
                      </MDBox>
                  );
                  return { ...item, action };
                });
                  setUsers(modifiedData);
              }
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
    }

    const handlePasswordResetSubmit = () => {
        const data = {
            userId: user.userId
        }
        api.post("/utils/resetapppassword", data)
            .then(response => {
                if (response.data.statusCodeValue === 200) {
                    toast.success(response.data.body);
                    setOpenPasswordDialog(false);
                    setUser({});
                    getAllMposUsers();
                }
            })
            .catch(error => {
                if(error.response.data.body){
                    toast.error(error.response.data.body);
                }

            })
    }

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={6} mb={3}>
          <Grid container spacing={3} mb={10}>
            <DataTable
                table={{ columns : mposUsersTablecolumns, rows : users }}
                showTotalEntries={true}
                isSorted={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
            />
          </Grid>
        </MDBox>
      <Dialog disableEscapeKeyDown open={openPasswordDialog} onClose={() => setOpenPasswordDialog(true)}>
          <DialogTitle>Reset App Password</DialogTitle>
          <DialogContent>
              <Typography fontSize="medium">Do you really want to reset the App password for user <Typography fontSize="medium" fontWeight="bold">{user?.userId}</Typography></Typography>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {
                setOpenPasswordDialog(false);
                setUser({});
              }
              }>Cancel</Button>
              <Button onClick={handlePasswordResetSubmit}>Ok</Button>
          </DialogActions>
      </Dialog>
      </DashboardLayout>
  );
}

export default ResetPin;
