import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const LogoutTimer = ({ onLogout, onSessionExtend }) => {
    let inactivityTimer;
    let inactivityAlertTimer;

    const resetTimer = () => {
        clearTimeout(inactivityTimer);
        clearTimeout(inactivityAlertTimer);
        inactivityTimer = setTimeout(() => {
            // Trigger logout action after 15 minutes of inactivity
            onLogout();
        }, 15 * 60 * 1000); // 15 minutes in milliseconds
        inactivityAlertTimer = setTimeout(() => {
            const userDecision = window.confirm('You are going to logout due to inactivity. Do you want to continue?');
            if (userDecision) {
                // onSessionExtend();
                resetTimer();
            } else {
                onLogout();
            }
        }, 14.5 * 60 * 1000);
    };

    const handleUserActivity = () => {
        resetTimer();
    };

    useEffect(() => {
        // Set up initial timer
        resetTimer();

        // Add event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        // Clean up event listeners on component unmount
        return () => {
            clearTimeout(inactivityTimer);
            clearTimeout(inactivityAlertTimer);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [onLogout, inactivityTimer, inactivityAlertTimer]);

    return <></>; // Placeholder component; you can use this in your layout
};

LogoutTimer.propTypes = {
    onLogout: PropTypes.func.isRequired,
    onSessionExtend: PropTypes.func.isRequired,
};

export default LogoutTimer;
