import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import PropTypes from "prop-types";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";

const RuleList = ({ rules }) => {
  const {ruleTableColumns} = data();
  return (
    <DataTable
      table={{ columns : ruleTableColumns, rows : rules }}
      showTotalEntries={true}
      isSorted={true}
      noEndBorder
      canSearch={true}
      entriesPerPage={true}
    />
  );
  // return (
  //   <List>
  //     {rules.map((rule, index) => (
  //       <ListItem key={index}>
  //         <Typography>
  //           IF {rule.conditionField.toUpperCase()} IS
  //           {rule.conditionOperator.toUpperCase() === 'BETWEEN' ?
  //             ` FROM ${rule.conditionFromValue} TO ${rule.conditionToValue} ` :
  //             ` ${rule.conditionFromValue} `}
  //           THEN ACQUIRER IS {rule.acquirer}
  //         </Typography>
  //       </ListItem>
  //     ))}
  //   </List>
  // )
}

RuleList.propTypes = {
  rules : PropTypes.any
}

export default RuleList;