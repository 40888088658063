/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TableContainer from "@mui/material/TableContainer";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import MDInput from "../../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import {DatePicker, DateRangePicker} from "rsuite";
import { afterToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import reportsLineChartData from "../../dashboard/data/reportsLineChartData";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport
} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {styled} from "@mui/material/styles";

function CardSettlementSummary() {
  const { cardSettlementSummaryTableColumns } = data();
  const { predefinedRanges } = reportsLineChartData;
  const [summary, setSummary] = useState([]);
  const [lastMonthDateRange, setLastMonthDateRange] = useState(new Date());
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const handleSettleAll = () => {
    const merchantsToSettle = rowSelectionModel.map((merchantRow) => {
      if (summary[merchantRow].status === 'AUTHORIZED' || summary[merchantRow].status === 'COMPLETED') return {
        merchantId : summary[merchantRow].merchantId,
        settlementAmount : summary[merchantRow].settlementAmount
      };
    })
    const data = {
        settlementSummary : merchantsToSettle,
        date : formatDate(lastMonthDateRange),
        currency: summary[rowSelectionModel[0]].currency
    }
    api.post("settlements", data)
        .then((response) => {
          if (response.data.data.status === "INITIATED") {
            toast.success("Settlement initiated successfully. Batch id is "+response.data.data.batchId, { id: 'SuccessfullyInitated' });
            if(lastMonthDateRange !== null){
                getCardSettlementSummary(lastMonthDateRange);
                setRowSelectionModel([]);
            }
          } else {
            toast.error("Something wrong happened, couldn't inititate settlement.", {id: "NotSuccessfullyInitated"});
          }
        })
        .catch((error) => {
            if (error.response && error.response.status === 500) {
                toast.error(error.response.data.error.message, { id: "FiveHundred" });
                return;
            }
          console.log("error", error)
        })
  };
  // const [fromDate, setFromDate]= useState(lastMonthDateRange[0]);
  // const [toDate, setToDate ]= useState(lastMonthDateRange[1]);
  const api = useApi();
    const formatDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
    };
  const getCardSettlementSummary = (lastMonthDateRange) => {
    if(lastMonthDateRange == null) return;
    setLastMonthDateRange(lastMonthDateRange);
    const data = {
      date : formatDate(lastMonthDateRange)
    }
    api.post("settlements/summary", data)
        .then((response) => {
          if (response.data.data.settlementSummary.length > 0) {
            const modifiedData = response.data.data.settlementSummary.map((item, index) => {
              // const action = (
              //     <MDBox display="flex" alignItems="center" lineHeight={1}>
              //       <MDButton color="info" variant="info">Settle</MDButton>
              //     </MDBox>
              // );
              // return { ...item, action, id: index };
              return { ...item, id: index };
            });
            setSummary(modifiedData);
          } else {
            toast.error("No Record Found", { id: "NoRecordFound" });
            setSummary([])
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Check if a toast with the same message is already on the screen
            toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
            return;
          }
          // Check if a toast with the same message is already on the screen
          toast.error("No Record Found", { id: "NoRecordFound" });
        });
  }
  useEffect(() => {
    if(lastMonthDateRange !== null){
      getCardSettlementSummary(lastMonthDateRange);
    }
  },[lastMonthDateRange]);
  function CustomToolbar() {
    return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          {rowSelectionModel.length > 0 ? <Button
              onClick={handleSettleAll}
          >
            Settle All
          </Button> : <></>}
        </GridToolbarContainer>
    );
  }
    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-virtualScroller': {
            overflowX: 'scroll !important',
        },
        '& .MuiDataGrid-cellContent': {
            paddingLeft: '2px',
        },
    });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12} sx={{ width: "100%" }}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3} textAlign={"center"}>
                <DatePicker
                    oneTap
                    disabledDate={afterToday()}
                    onChange={(date) => getCardSettlementSummary(date)}
                    format="yyyy-MM-dd"
                    value={lastMonthDateRange ? lastMonthDateRange : ''}
                />
              </MDBox>
            </Grid>
            {/*<DataTable*/}
            {/*  table={{ columns : cardSettlementSummaryTableColumns, rows : summary }}*/}
            {/*  showTotalEntries={true}*/}
            {/*  isSorted={true}*/}
            {/*  noEndBorder*/}
            {/*  entriesPerPage={true}*/}
            {/*  canSearch={true}*/}
            {/*/>*/}
            {/*<TableContainer sx={{ boxShadow: "none", width: '100%' }}>*/}
            <StyledDataGrid
                autoHeight
                style={{ backgroundColor: 'white',  }}
                rows={summary}
                columns={cardSettlementSummaryTableColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                checkboxSelection
                isRowSelectable={(params) => params.row.status === 'AUTHORIZED' || params.row.status === 'COMPLETED'}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  toolbar: {
                    csvOptions: {
                      filename: 'settlement-summary',
                      delimiter: ',',
                      utf8WithBom: true,
                    },
                    printOptions: {
                      hideFooter: true,
                      hideToolbar: true,
                      includeCheckboxes: true,
                    }
                  }
                }}
            />
            {/*</TableContainer>*/}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CardSettlementSummary;
