// PasswordUtility.js

// Import Yup for schema validation
import * as yup from 'yup';

// Define the common words to be restricted
const restrictedWords = ["password", "admin", "welcome", "test", "1234"];

class PasswordUtility {
  // Method to get the validation schema for password
  static getPasswordValidationSchema() {
    return yup.object().shape({
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(12, "Password must not exceed 12 characters") // Updated to 8-12 characters
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()_+\/~{}[\]:\";'<>?,.\/]).*$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .test(
          "restricted-words",
          "Password should not contain common words like 'password', 'admin', 'welcome', 'test', or '1234'.",
          (value) => {
            if (!value) return true;
            const lowerValue = value.toLowerCase();
            return !restrictedWords.some(word => lowerValue.includes(word));
          }
        ),
      otp: yup.string().required("OTP is required").matches(/^[0-9]{4}$/, "OTP must be a 4-digit number"),
    });
  }

  // Utility method to validate the password directly (if needed)
  static validatePassword(password) {
    const schema = this.getPasswordValidationSchema();
    return schema.validate({ password })
      .then(() => ({ isValid: true, message: "" }))
      .catch(err => ({ isValid: false, message: err.errors[0] }));
  }
}

export default PasswordUtility;
