import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, IconButton, ListItemSecondaryAction, CardContent } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

function PrioritySetter({ initialPriorities, onUpdate }) {
  const [priorities, setPriorities] = useState(initialPriorities);

  useEffect(() => {
      setPriorities(initialPriorities);
  }, [initialPriorities])

  const move = (index, direction) => {
    const newPriorities = [...priorities];
    let temp = newPriorities[index];

    // return;
    temp.priority = temp.priority + direction;
    newPriorities[index] = newPriorities[index + direction];
    newPriorities[index].priority = newPriorities[index].priority - direction;
    newPriorities[index + direction] = temp;
    console.log(index, newPriorities);
    setPriorities(newPriorities);
    onUpdate(newPriorities);  // Pass updated priorities for saving to backend
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
          Condition Field Priority
        </Typography>
        <List className="priority-list">
        {priorities.map((field, index) => (
          <ListItem key={field.id}>
            <ListItemText primary={
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {field.conditionField}
            </Typography>} sx={{ fontSize: 12 }} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => move(index, -1)}
                disabled={index === 0}
                size="small"
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => move(index, 1)}
                disabled={index === priorities.length - 1}
                size="small"
              >
                <ArrowDownwardIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      </CardContent>
    </Card>
  );
}

PrioritySetter.propTypes = {
  initialPriorities : PropTypes.any,
  onUpdate: PropTypes.func
}

export default PrioritySetter;
