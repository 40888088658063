import { useCallback } from 'react';

const useAllowOnlyNumber = () => {
    const handleKeyDown = useCallback((event) => {
        const allowedKeys = [
            'Backspace',
            'Tab',
            'ArrowLeft',
            'ArrowRight',
            'Delete',
            'Enter',
            'Home',
            'End',
        ];
        const isNumber = /^[0-9]$/.test(event.key);
        const isAllowedKey = allowedKeys.includes(event.key);

        if (!isNumber && !isAllowedKey) {
            event.preventDefault();
        }
    }, []);

    return handleKeyDown;
}

export default useAllowOnlyNumber;
