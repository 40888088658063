import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useApi from "../../../components/Utils/api";
import { useNavigate } from "react-router-dom";
import {QontoConnector, QontoStepIcon, ColorlibConnector, ColorlibStepIcon} from "./stepper";
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StepContent } from "@mui/material";
import MerchantOfficeDetails from "../tabs/merchantOffficeDetails";
import MerchantBankDetails from "../tabs/merchantBankDetails";
import MerchantOtherDetails from "../tabs/merchantOtherDetails";
import { useContext } from "react";
import ValidationContext from "../../../context/validation";
import { merchantSchemaStep1, merchantSchemaStep2, merchantSchemaStep3 } from "../../../models/merchantModel";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";


function NewMerchant() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const api = useApi();
  const [activeStep, setActiveStep] = React.useState(0);
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(activeStep === 0 ? merchantSchemaStep1 : activeStep === 1 ? merchantSchemaStep2 : activeStep === 2 ? merchantSchemaStep3 : {})
  });
  const [merchant, setMerchant] = React.useState({ merchantFiles: []});
  const [requiredInfo, setRequiredInfo] = React.useState({
    mcclist : [],
    cityList : [],
    referenceCompany : [],
    microAtm : []
  })

  const onSubmit = data => {
    console.log(activeStep);
    if(activeStep < 2 && Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      saveMerchant(data);
    }
  };
  const saveMerchant = (data) => {
    let formData = new FormData();

    // Append files
    if (data.supportingDocuments && data.supportingDocuments.length > 0) {
      data.supportingDocuments.forEach((file, index) => {
        for (let i = 0; i < file.length; i++) {
          formData.append('supportingDocuments', file[i]);
        }
      });
    }
    data.merchantBankDetails = data.merchantBankDetails.filter(bankdetails => bankdetails.currency !== ""
        && bankdetails.bankName !== ""
        && bankdetails.bankCode !== ""
        && bankdetails.branch !== ""
        && bankdetails.accountNumber !== "");
    delete data.supportingDocuments;
    // Append MerchantMaster object as a JSON string
    data.mccCode = "1234";
    data.areaCode = "1234";
    formData.append('merchantMaster', JSON.stringify(data));

    api.post("merchant/create", formData)
      .then(response => {
        if(response.data.statusCodeValue === 200){
          toast.success(response.data.body.message);
          navigate("/merchants/single", {
            state: {
              companyId: response.data.body.companyId
            }
          })
        } else if(response.data.statusCodeValue === 409) {
          toast.error(response.data.body);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  const handleNext = () => {
    handleSubmit(onSubmit)();
  };
  const formValuesHandler = (e) => {
    const {name, value} = e.target;
    validateInput('NAME_BASED', name, value);
    setMerchant({...merchant, merchant : {...merchant.merchant , [name]: value}});
  }
  const getAllRequiredInfo = () => {
    api.get("merchant/getNewMerchReqInfo")
      .then(response => {
        if(response.data.statusCodeValue === 200){
          setRequiredInfo(response.data.body);
        }
      })
      .catch(error => {

      })
  }
  React.useEffect(() => {
    getAllRequiredInfo();
  }, [])
  const handleBack = () => {
    console.log(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const steps = [
    {
      label: t('merchant_office_details'),
      component: <MerchantOfficeDetails merchant={merchant} setMerchant={setMerchant} editmode={true} type="new" errors={errors} register={register}/>
    },
    {
      label: t('merchant_bank_details'),
      component: <MerchantBankDetails merchant={merchant} setMerchant={setMerchant} editmode={true} type="new"/>
    },
    {
      label: t('other_details'),
      component: <MerchantOtherDetails merchant={merchant} setMerchant={setMerchant} editmode={true} type="new"/>
    }
  ];


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel style={{ background: 'unset', boxShadow: 'unset' }} activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                       sx={{
                         '& .MuiStepLabel-label': {
                           color: '#504d4d !important'
                         },
                         '& .MuiStepLabel-label.Mui-active': {
                           color: '#000 !important'
                         },
                         '& .MuiStepLabel-label.Mui-completed': {
                           color: '#000 !important'
                         }
                       }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
            <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, requiredInfo, control }}>
            {steps.map((step, index) => (
              <Box sx={{ p: 3 }} key={step.label} style={{ display: activeStep === index ? 'block' : 'none' }}>
                {step.component}
              </Box>
            ))}
            </FormProvider>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('back')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? t('finish') : t('next')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewMerchant;
