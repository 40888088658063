import * as yup from 'yup';
import i18next from 'i18next';
const optionalWithPattern = (name, pattern, message) => {
  return yup.string().test(name, message, value => {
    if (!value) return true;  // allow empty
    return pattern.test(value);
  });
};
let merchantSchema;
let merchantSchemaStep3;
let merchantSchemaStep2;
let merchantSchemaStep1;
i18next.on('loaded', (loaded) => {
    merchantSchemaStep3 = yup.object().shape({
        amountPerTrans: yup.string(),
        allowedTotAmtPerDay: yup.string(),
        allowedTransPerDay: yup.string(),
        supportingDocuments: yup.array()
            .required(() => i18next.t('at_least_one_file_is_required'))
            .of(
                yup.mixed()
                    .test('fileSize', () => i18next.t('file_size_should_not_exceed_5mb'), (file) => {
                        return file[0] && file[0].size <= 5 * 1024 * 1024;
                    })
                    .test('fileType', () => i18next.t('unsupported_file_type'), (file) => {
                        const acceptedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                        return file[0] && acceptedTypes.includes(file[0].type);
                    })
            )
            .ensure(),
        bankFee: yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, () => i18next.t('invalid_bank_fee_only_numbers_and_up_to_two_decimal_places_are_allowed'))
            .required(() => i18next.t('bank_fee_is_required')),
        issuerFee: yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, () => i18next.t('invalid_issuer_fee_only_numbers_and_up_to_two_decimal_places_are_allowed'))
            .required(() => i18next.t('issuer_fee_is_required'))
    });
    merchantSchemaStep2 = yup.object().shape({
        // merchantFrequency: yup.string(),
        // bankName: yup.string()
        //   .required(() => i18next.t('settlement_bank_name_is_required')),
        // bankerBranchName: yup.string().required(() => i18next.t('settlement_account_name_is_required')),
        // accountNo: yup.string().required(() => i18next.t('settlement_account_name_is_required')),
        // ifscCode: yup.string(),
        expectedCardBusinessPerMonth: yup.string().required(() => i18next.t('expected_card_business_error')),
        currency: yup.string(),
        merchantBankDetails: yup.array().of(
            yup.object().shape({
                bankName: yup.string(),
                bankCode: yup.string(),
                branch: yup.string(),
                accountNumber: yup.string(),
                currency: yup.string().oneOf(['USD', 'ZiG', ''], () => i18next.t('currency_must_be_usd_or_zig'))
            })
        ).test('unique-currency', () => i18next.t('duplicate_currency_not_allowed'), (value) => {
            const currenciesFilter = value.filter(item => item.currency !== "");
            const currencies = currenciesFilter.map(item => item.currency);
            console.log(currencies);
            if (currencies.length === 0 ) return true;
            return new Set(currencies).size === currencies.length;
        }),
        averageBillAmount: yup.string(),
        turnoverYear1: yup.string()
    });

    merchantSchemaStep1 = yup.object().shape({
        referenceId: yup.string().required(() => i18next.t('reference_company_is_required')),

        referenceCompanyName: optionalWithPattern("referenceCompanyName", /^[a-zA-Z0-9!@#$&()-`.+,/" ]*$/, () => i18next.t('please_enter_valid_reference_company_name'))
            .max(50, () => i18next.t('please_enter_valid_reference_company_name')),

        ownerName: yup.string()
            .required(() => i18next.t('owner_name_is_required'))
            .matches(/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/, () => i18next.t('invalid_owner_name'))
            .max(20, () => i18next.t('invalid_owner_name')),
        mid: yup.string(),

        companyName: yup.string()
            .required(() => i18next.t('registered_business_name_is_required'))
            .max(50, () => i18next.t('invalid_registered_business_name')),

        typeEstablishment: yup.string()
            .required(() => i18next.t('type_of_establishment_is_required'))
            .notOneOf(['', '-1'], () => i18next.t('please_select_a_valid_type_of_entity')),

        address: yup.string()
            .max(200, () => i18next.t('address_length_exceeds_the_limit')),

        addressRes: yup.string()
            .max(200, () => i18next.t('address_length_exceeds_the_limit')),

        // pinCode: yup.string()
        //     .required('Zip code is required.')
        //   .test('authorizedPerson', 'Invalid zip code', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .matches(/^[0-9]{6}$/, 'Invalid Zip Code'),
        //
        // mccCode: yup.string()
        //   .required('Please enter a valid Merchant Category Code'),
        //
        // abn: yup.string()
        //   .test('abn', 'Invalid ABN', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(30, 'Max length of ABN should be 30.'),
        //
        // acn: yup.string()
        //   .test('acn', 'Invalid ACN', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(30, 'Max length of ACN should be 30.'),

        establishmentYrs: yup.string()
            .required(() => i18next.t('please_enter_years_of_establishment')),

        saleTaxNo: yup.string()
            .max(9, () => i18next.t('sales_tax_length_should_not_be_greater_than_9'))
            .min(8, () => i18next.t('sales_tax_length_should_not_be_less_than_8')),

        authorizedPerson: yup.string()
            .required(() => i18next.t('authorized_person_name_required'))
            .max(20, () => i18next.t('max_name_length_is_20')),

        mobileNo: yup.string()
            .required(() => i18next.t('mobile_number_is_required'))
            .matches(/^[0-9]{9,13}$/, () => i18next.t('invalid_mobile_number')),

        emailId: yup.string()
            .required(() => i18next.t('email_is_required'))
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, () => i18next.t('invalid_email')),

        // faxNo: yup.string()
        //   .test('faxNo', 'Invalid FAX number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[0-9]{1,13}$/.test(value);
        //   }),

        officeNo: yup.string()
            .required(() => i18next.t('office_number_required'))
            .test('officeNo', () => i18next.t('invalid_office_number'), value => {
                if (!value) return true;  // allow empty
                return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
            })
            .max(13, () => i18next.t('invalid_office_number')),

        // tinNo: yup.string()
        //   .test('tinNo', 'Invalid TIN number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(10, 'Invalid TIN number'),
        //
        // groupMid: yup.string()
        //   .optional()
        //     .max(15, 'Group MID should be max 15 characters.'),

        microAtmStatus: yup.string()
            .notOneOf(['-1'], () => i18next.t('please_select_a_valid_master_type')),

        // walletBalance: yup.string()
        //   .test('walletBalance', 'Invalid Wallet Balance', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[0-9]+(\.[0-9]{1,10})?$/.test(value);
        //   })
        //   .max(10, 'Invalid Wallet Balance'),
        //
        // walletAccountNo: yup.string()
        //   .test('walletAccountNo', 'Invalid Wallet Account Number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[a-zA-Z0-9]{9,32}$/.test(value);
        //   }),
    });

    merchantSchema = yup.object().shape({
        referenceId: optionalWithPattern("referenceId", /^[a-zA-Z0-9]{0,5}$/, () => i18next.t('invalid_reference_code')),

        referenceCompanyName: optionalWithPattern("referenceCompanyName", /^[a-zA-Z0-9!@#$&()-`.+,/" ]*$/, () => i18next.t('please_enter_valid_reference_company_name'))
            .max(50, () => i18next.t('please_enter_valid_reference_company_name')),

        ownerName: yup.string()
            .required(() => i18next.t('owner_name_is_required'))
            .max(50, i18next.t('invalid_owner_name')),

        // pincode: optionalWithPattern("ownerName", /^[0-9]{6}$/, 'Invalid Pin Code'),

        commissionOnDebit: optionalWithPattern("commissionOnDebit", /^[0-9]+(\.[0-9]{0,2})?%?$/, i18next.t('invalid_commission_on_debit_value')),

        commissionOnCredit: optionalWithPattern("commissionOnCredit", /^[0-9]+(\.[0-9]{0,2})?%?$/, i18next.t('invalid_commission_on_credit_value')),

        senderId: optionalWithPattern("senderId", /^[0-9]{6}$/, i18next.t('invalid_sender_id')),

        mid: yup.string(),

        companyName: yup.string()
            .required(() => i18next.t('registered_business_name_is_required'))
            .max(50, i18next.t('invalid_registered_business_name')),

        typeEstablishment: yup.string()
            .notOneOf(['', '-1'], i18next.t('please_select_a_valid_type_of_entity')),

        address: yup.string()
            .max(200, i18next.t('address_length_exceeds_the_limit')),

        addressRes: yup.string()
            .max(200, i18next.t('address_length_exceeds_the_limit')),

        // pinCode: yup.string()
        // .optional()
        //   .max(6, 'Zip Code should be max 6 characters.'),
        //
        // mccCode: yup.string()
        //   .notOneOf(['', '-1'], 'Please select a valid Merchant Category Code'),
        //
        // abn: yup.string()
        //   .test('abn', 'Invalid ABN', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(30, 'Max length of ABN should be 30.'),
        //
        // acn: yup.string()
        //   .test('acn', 'Invalid ACN', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(30, 'Max length of ACN should be 30.'),

        establishmentYrs: yup.string()
            .required(() => i18next.t('please_enter_years_of_establishment')),

        saleTaxNo: yup.string()
            .max(9, i18next.t('sales_tax_length_should_not_be_greater_than_9'))
            .min(8, i18next.t('sales_tax_length_should_not_be_less_than_8')),

        authorizedPerson: yup.string()
            .test('authorizedPerson', i18next.t('invalid_authorized_person_name'), value => {
                if (!value) return true;  // allow empty
                return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
            })
            .max(20, i18next.t('max_name_length_is_20')),

        mobileNo: yup.string()
            .required(() => i18next.t('mobile_number_is_required'))
            .matches(/^[0-9]{10,13}$/, i18next.t('invalid_mobile_number')),

        emailId: yup.string()
            .required(() => i18next.t('email_is_required'))
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, i18next.t('invalid_email')),

        // faxNo: yup.string()
        //   .test('faxNo', 'Invalid FAX number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[0-9]{1,13}$/.test(value);
        //   }),

        officeNo: yup.string()
            .test('officeNo', i18next.t('invalid_office_number'), value => {
                if (!value) return true;  // allow empty
                return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
            })
            .max(13, i18next.t('invalid_office_number')),

        // tinNo: yup.string()
        //   .test('tinNo', 'Invalid TIN number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/.test(value);
        //   })
        //   .max(10, 'Invalid TIN number'),
        //
        // groupMid: yup.string()
        //   .optional()
        //   .max(15, 'Group MID should be max 15 characters.'),

        microAtmStatus: yup.string()
            .notOneOf(['-1'], i18next.t('please_select_a_valid_master_type')),

        // walletBalance: yup.string()
        //   .test('walletBalance', 'Invalid Wallet Balance', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[0-9]+(\.[0-9]{1,10})?$/.test(value);
        //   })
        //   .max(10, 'Invalid Wallet Balance'),
        //
        // walletAccountNo: yup.string()
        //   .test('walletAccountNo', 'Invalid Wallet Account Number', value => {
        //     if (!value) return true;  // allow empty
        //     return /^[a-zA-Z0-9]{9,32}$/.test(value);
        //   }),
        merchantBankDetails: yup.array().of(
            yup.object().shape({
                bankName: yup.string(),
                bankCode: yup.string(),
                branch: yup.string(),
                accountNumber: yup.string(),
                currency: yup.string().oneOf(['USD', 'ZiG', ''], i18next.t('currency_must_be_usd_or_zig'))
            })
        ).test('unique-currency', i18next.t('duplicate_currency_not_allowed'), (value) => {
            const currenciesFilter = value.filter(item => item.currency !== "");
            const currencies = currenciesFilter.map(item => item.currency);
            console.log(currencies);
            if (currencies.length === 0 ) return true;
            return new Set(currencies).size === currencies.length;
        }),
        // bankName: yup.string()
        //   .required(() => i18next.t('settlement_account_name_is_required'))
        //   .matches(/^([a-zA-Z0-9]+ )+[a-zA-Z0-9]+$|^[a-zA-Z0-9]+$/, i18next.t('invalid_account_name')),
        bankFee: yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, i18next.t('invalid_bank_fee_only_numbers_and_up_to_two_decimal_places_are_allowed'))
            .required(() => i18next.t('bank_fee_is_required')),
        issuerFee: yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, i18next.t('invalid_issuer_fee_only_numbers_and_up_to_two_decimal_places_are_allowed'))
            .required(() => i18next.t('issuer_fee_is_required')),
        supportingDocuments: yup.array()
            .optional()
            .of(
                yup.mixed()
                    .test('fileSize', i18next.t('file_size_should_not_exceed_5mb'), (file) => {
                        console.log(file[0]);
                        if (file.length === 0) return true;
                        return file[0] && file[0].size <= 5 * 1024 * 1024;
                    })
                    .test('fileType', i18next.t('unsupported_file_type'), (file) => {
                        if (file.length === 0) return true;
                        const acceptedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                        return file[0] && acceptedTypes.includes(file[0].type);
                    })
            )
            .ensure()
    });
});

export { merchantSchema, merchantSchemaStep1, merchantSchemaStep2, merchantSchemaStep3 };

