/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { setLoggedIn, setToken, setUser, useMaterialUIController, setLicenseMessage, setRefreshToken } from "../../../context";
import useApi from "../../../components/Utils/api";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {isLoggedIn} = controller;
  const { t } = useTranslation();
  const api = useApi();
  const navigate = useNavigate();

  const login = (form) => {
    form.preventDefault();
    const data = {
      username: form.target.username.value,
      passwd: form.target.password.value
    }
    api.post('auth/admin/authenticate', data)
      .then(response => {
        if(response.data.token) {


          let newUser = response.data.user;
          if(newUser.authStatus === "7"){
            let passwdExpiry = new Date(newUser.newUsrPasswdExp);
            const currentDate = new Date();
            // console.log(passwdExpiry, currentDate, passwdExpiry < currentDate);
            if (passwdExpiry < currentDate) {
              toast.error("Your password has been already expired. Please contact the admin.")
            } else {
              const timeDifference = passwdExpiry - currentDate;

              const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
              // console.log("Welcome "+newUser.firstName+` , Please reset your password, your default password will be expired in ${days} days, ${hours} hours, ${minutes} minutes`)

              toast.success("Welcome "+newUser.firstName+` , Please reset your password to Proceed`);
                            setLoggedIn(dispatch, true);
              setUser(dispatch, response.data.user);
              setToken(dispatch, response.data.token);
              setRefreshToken(dispatch, response.data.refreshToken);
              setLicenseMessage(dispatch, response.data.license);
              navigate("/first-password");
           
            }
          } else if(newUser.authStatus === "1"){
            toast.success("Welcome "+response.data.user.firstName);
            setLoggedIn(dispatch, true);
            setUser(dispatch, response.data.user);
            setToken(dispatch, response.data.token);
            setRefreshToken(dispatch, response.data.refreshToken);
            setLicenseMessage(dispatch, response.data.license);
            navigate("/dashboard");
          }

        }
      })
      .catch(error => {
        const response = error.response;
        if(response) toast.error(response.data.message);
        if (response.data.message == "Your Password has been Expired. Please change it."){
          console.log("no password change required.")
          navigate("/forgot-password", {
            state: {
              username: data.username
            }
          });
        } else {
          console.log("no password change required.")
        }
      });
  }

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t('sign_in')}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(values) => login(values)}>
            <MDBox mb={2}>
              <MDInput type="text" label={t('username')} name="username" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label={t('password')} name="password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} name="remember_me" onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{t('remember_me')}
              </MDTypography>
              <MDBox component="a" href="/forgot-password" style={{marginLeft: 'auto', alignSelf: 'fle3x-start'}}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  {t('forgot_password')}
                </MDTypography>
              </MDBox>

            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {t('sign_in')}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
