/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { useNavigate } from "react-router-dom";
import PermissionControl from "../../components/Utils/PermissionControl";
import {useTranslation} from "react-i18next";

function MerchantGroups() {
  const { merchantGroupTablecolumns } = data();
  const {t} = useTranslation();
  const [merchants, setMerchants] = useState([]);
  const navigate = useNavigate();
  const api = useApi();
  const getMerchantGroups = () => {
    api.get("admin/browseMerchantGroup")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setMerchants(response.data.body);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
          return;
        }
      })
  }

  useEffect(() => {
    getMerchantGroups();
  },[]);

  const rowHandler = (row, pageRow)  => {
    // console.log("triggered", pageRow)
    navigate("/merchant-group/single", {
      state: {
        referenceCompany: pageRow.original,
        merchantGroups: merchants
      }
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <PermissionControl permission={{ restrict: true, module: "MerchantGroup", subModule: "Create" }}>
              <MDButton color="info" size="medium" style={{ marginLeft: 'auto', display: 'block' }} variant="contained" onClick={() => navigate("/merchant-group/single", { state: { merchantGroups: merchants }  })}>{t('new')}</MDButton>
            </PermissionControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <DataTable
              table={{ columns : merchantGroupTablecolumns, rows : merchants }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
              onRowClicked={rowHandler}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MerchantGroups;
