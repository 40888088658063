import React, { useState } from 'react';
import { Card, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Typography, InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "../../components/BasicLayout";

// Password utility for validation schema
import PasswordUtility from '../../../passwordutility';

// Background image
import bgImage from "assets/images/bg-reset-cover.jpeg";
import useApi from "../../../../components/Utils/api";

function ResetWithOtp() {
  const api = useApi();
  const { state } = useLocation();
  const navigate = useNavigate();

  // State for dialog visibility
  const [dialogOpen, setDialogOpen] = useState(false);
  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Use the validation schema from PasswordUtility
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(PasswordUtility.getPasswordValidationSchema()), // Use schema from PasswordUtility
    defaultValues: {
      username: state?.username || "",
      password: "",
      otp: "",
    },
  });

  // Open the dialog when the user clicks on the InfoIcon
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Close the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Submit form handler
  const formSubmit = () => {
    handleSubmit(formHandler)();
  }

  // Handle form submission to the API
  const formHandler = (form) => {
    if (state === null) {
      toast.error("Session Expired! Please initiate a reset request first.");
      navigate("/forgot-password");
      return;
    }
    const data = {
      username: form.username,
      password: form.password,
      otp: form.otp,
    }

    // API call to reset password
    api.post("auth/admin/reset-password", data)
      .then(response => {
        if (response.data.statusCodeValue === 200) {
          toast.success(response.data.body.message);
          navigate("/login");
        } else {
          if (response.data.body.message) {
            toast.error(response.data.body.message, { id: "RESET_PASSWORD_ERROR" });
          }
        }
      })
      .catch(error => {
        if (error.response.data.body.message) {
          toast.error(error.response.data.body.message)
        }
      })
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive a SMS in maximum 60 seconds on your registered mobile number.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
            <MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="Username"
                  name="username"
                  variant="standard"
                  error={Boolean(errors.username)} // Retain error state styling (optional)
                  helperText={errors.username ? errors.username.message : ''} // Show error message if there is an error
                  fullWidth
                  {...register("username")}
                  disabled
                  focused
                />
              </MDBox>
              <MDBox mb={4} style={{ position: 'relative' }}>
                {/* Password Input Field with Eye Icon and Info Icon on Right */}
                <MDInput
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  label="Password"
                  name="password"
                  variant="standard"
                  error={Boolean(errors.password)} // Enable error state styling for password field
                  helperText={errors.password ? errors.password.message : ''} // Show error message below the field
                  fullWidth
                  {...register("password")}
                  required
                  InputProps={{
                    style: { position: 'relative', color: 'inherit' },
                    // Place both Eye and Info Icons on the right end
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* Eye Icon Button (Now on the right) */}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          style={{
                            background: 'transparent', // Remove background color
                            borderRadius: '0', // Remove border radius
                            boxShadow: 'none', // Remove box shadow
                            padding: 0,
                            marginRight: '10px', // Add margin to separate icons
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>

                        {/* Info Icon Button (Now on the left) */}
                        <Tooltip title="Password Criteria: Click for details" placement="top">
  <IconButton
    aria-label="password criteria"
    style={{
      background: 'linear-gradient(135deg, #4c9aff 30%, #007aff 90%)', // Gradient background for glossy effect
      color: '#ffffff', // White icon color
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Light shadow for a subtle 3D effect
      borderRadius: '50%', // Circular shape to match the uploaded image
      padding: '0px', // Remove additional padding
      width: '31px', // Increased width for a bigger look
      height: '28px', // Increased height for a bigger look
    }}
    onClick={handleDialogOpen}
  >
    <InfoIcon style={{ fontSize: '18px' }} /> {/* Increased font size of the InfoIcon */}
  </IconButton>
</Tooltip>


                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="password"
                  label="OTP"
                  name="otp"
                  variant="standard"
                  error={Boolean(errors.otp)} // Retain error state styling
                  helperText={errors.otp ? errors.otp.message : ''} // Show error message if there is an error
                  fullWidth
                  {...register("otp")}
                  required
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton onClick={() => formSubmit()} variant="gradient" color="info" fullWidth>
                  Reset
                </MDButton>
              </MDBox>
            </MDBox>
          </FormProvider>
        </MDBox>
      </Card>

      {/* Password Criteria Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="password-criteria-title"
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '16px',
            minWidth: '400px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <DialogTitle id="password-criteria-title" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Password Criteria
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom style={{ fontSize: '0.875rem' }}>
            Recommended passwords with a length of 8-12 characters.
          </Typography>
          <Typography variant="body2" style={{ fontSize: '0.75rem' }}>
            <ul style={{ paddingLeft: '20px', marginTop: '0', fontSize: '0.75rem' }}>
              <li>At least 1 uppercase letter (A-Z)</li>
              <li>At least 1 lowercase letter (a-z)</li>
              <li>At least 1 number (0-9)</li>
              <li>At least 1 special character (e.g., ! @ # $)</li>
            </ul>
          </Typography>
        </DialogContent>
      </Dialog>
    </BasicLayout>
  );
}

export default ResetWithOtp;
