/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";
import jwt_decode from 'jwt-decode';
// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setLoggedIn,
  setUser,
  setToken,
  setDashboardDataPs,
  setDefaultCalendar,
  setLicenseMessage,
  setLicenseMessageShown, setRefreshToken
} from "context";
import toast from "react-hot-toast";
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import LogoutTimer from "../../components/Utils/LogoutTimer";
import useApi from "../../components/Utils/api";
import {useTranslation} from "react-i18next";
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor, token, user, refreshToken } = controller;
  const location = useLocation();
  const api = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const collapseName = location.pathname.replace("/", "");

  // State to track the open status of collapsible sections
  const [openSections, setOpenSections] = useState({});
  const [openSection, setOpenSection] = useState("");
 

  useEffect(() => {
    if (!token) return;
    const interval = setInterval(checkTokenExpiry, 5000);
    return () => clearInterval(interval);
  }, []);
  const onSessionExtend = () => {
    if (isRefreshingToken) {
      return;
    }

    setIsRefreshingToken(true);

    const data = {
      refreshToken: refreshToken
    }
    api.post('auth/admin/refresh', data)
        .then(response => {
          if(response.data.token) {
            console.log(response.data.token);
            setToken(dispatch, response.data.token);
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          // Reset the flag to allow future refreshes
          setIsRefreshingToken(false);
        });
  }
  const checkTokenExpiry = () => {
    if (!token && !refreshToken) {
      navigate("/login");
      return;
    }
    try {
      const { exp } = jwt_decode(refreshToken);
      if (Date.now() >= exp * 1000) {
        // Token is expired, clear the token from localStorage and force logout
        toast("Session Expired! Please login again.")
        logout();
      } else {
        const materialState = JSON.parse(localStorage.getItem('materialUIState'));
        const { exp } = jwt_decode(materialState.token);
        // console.log("TokenExpiry? ",exp * 1000, "DateNow? ",Date.now(), "ShouldRefresh? ",Date.now() >= exp * 1000);
        if (Date.now() >= exp * 1000) {
          onSessionExtend();
        }
      }
    } catch (e) {
      console.log(e);
      // Handle any other errors here
    }
  };

  // Function to toggle the open status of a collapsible section
  const handleToggleSection = (sectionKey) => {
    // setOpenSections((prevOpenSections) => ({
    //   ...prevOpenSections,
    //   [sectionKey]: !prevOpenSections[sectionKey],
    // }));
    if (openSection === sectionKey) {
      setOpenSection(""); // If it's already open, close it.
    } else {
      setOpenSection(sectionKey); // Open the new section.
    }
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const logout = () => {
    setToken(dispatch, "");
    setRefreshToken(dispatch, "");
    setUser(dispatch, []);
    setLoggedIn(dispatch, false);
    setDashboardDataPs(dispatch, {});
    setDefaultCalendar(dispatch, {});
    setLicenseMessage(dispatch, null);
    setLicenseMessageShown(dispatch, false);
    navigate("/login");
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  useEffect(() => {
    routes.forEach(route => {
      if (route.type === "collapse" && route.collapse) {
        route.collapse.forEach(nestedRoute => {
          if (nestedRoute.route === location.pathname) {
            setOpenSection(route.key);
          }
        });
      }
    });
  }, [location.pathname, routes]);

  const renderRoutes = useMemo(() =>
    routes.map(
      ({ type, name, icon, title, noCollapse, key, href, route, doShowInSidebar, collapse, module, subModule }) => {
        if(key === "users" && user.role === "MAKER") return;
        // Check if this route should be visible based on permissions
        if (token && module && subModule) {
          const permissionKey = `${module}:${subModule}`;
          const {permissions} = jwt_decode(token);
          // console.log(permissionKey);
          // If permission does not exist, return
          if (!permissions.includes(permissionKey)) {
            return null;
          }
        }
        // Check for child permissions
        if (token && type === "collapse" && collapse) {
          const hasChildPermissions = collapse.some(({ module: childModule, subModule: childSubModule }) => {
            if (!childModule || !childSubModule) {
              return true;  // No permission needed for this child route
            }
            const childPermissionKey = `${childModule}:${childSubModule}`;
            const {permissions} = jwt_decode(token);
            return permissions.includes(childPermissionKey);
          });

          // If no child route has permissions, return null for the whole parent collapsible route
          if (!hasChildPermissions) {
            return null;
          }
        }

        let returnValue;
        if (!doShowInSidebar) return null;
        if (type === "collapse") {
          // If the route has nested routes (collapse), check if it's open
          // const isCollapsed = !!openSections[key];
          const isCollapsed = openSection === key;

          returnValue = (
            <div key={key} className="collapsible">
              {/* Render the collapsible header */}
              <div className="collapsible-click" onClick={() => handleToggleSection(key)}>
                <SidenavCollapse
                  name={t(name)}
                  icon={icon}
                  active={key === collapseName}
                  nocollapse={noCollapse}
                  open={isCollapsed}
                  hasChildren={true}
                />

              </div>

              {/* Render the nested routes (if the collapsible section is open) */}
              {isCollapsed && (
                <List sx={{ paddingLeft: "10px", paddingTop: "5px" }}>
                  {collapse.map(({ name: nestedName, icon: nestedIcon, key: nestedKey, route: nestedRoute, module: nestedModule, subModule: nestedSubModule }) => {

                    // Check if this nested route should be visible based on permissions
                    if (token && nestedModule && nestedSubModule) {
                      const nestedPermissionKey = `${nestedModule}:${nestedSubModule}`;
                      const {permissions} = jwt_decode(token);
                      // If permission does not exist, return
                      if (!permissions.includes(nestedPermissionKey)) {
                        return null;
                      }
                    }

                    return (
                      <NavLink key={nestedKey} to={nestedRoute} >
                        <SidenavCollapse
                          name={"- " + t(nestedName)}
                          // icon={nestedIcon}
                          active={nestedKey === collapseName}
                          sx={{ paddingTop: "16px" }}
                        />
                      </NavLink>
                    );
                  })}
                </List>
              )}
            </div>
          );
        } else if (type === "single") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <NavLink key={key} to={route}>
              <SidenavCollapse name={t(name)} icon={icon} active={key === collapseName} />
            </NavLink>
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }

        return returnValue;
      }
    ), [routes, openSection, user.role, collapseName, darkMode, whiteSidenav, transparentSidenav, textColor,t]
  );


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      className="no-print"
    >
      <MDBox className="no-print" pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="12rem" />}
          {/*<MDBox*/}
          {/*  width={!brandName && "100%"}*/}
          {/*  sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}*/}
          {/*>*/}
          {/*  <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>*/}
          {/*    {brandName}*/}
          {/*  </MDTypography>*/}
          {/*</MDBox>*/}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          onClick={() => logout()}
          fullWidth
        >
          lOG OUT
        </MDButton>
      </MDBox>
      <LogoutTimer  onLogout={logout} token={token} onSessionExtend={onSessionExtend} />
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
