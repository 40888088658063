import { useContext } from "react";
import { useMaterialUIController } from "context";
import jwt_decode from "jwt-decode";

const usePermission = () => {
    const [controller] = useMaterialUIController();
    const { token } = controller;

    const checkPermission = (module, subModule) => {
        if (!token || !module || !subModule) {
            return false;
        }

        const { permissions } = jwt_decode(token);
        const permissionKey = `${module}:${subModule}`;
        return permissions.includes(permissionKey);
    };

    return checkPermission;
};

export default usePermission;
