import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useMaterialUIController } from "../../../context";
import useApi from "../../../components/Utils/api";
import ConfiguratorRoot from "../../../examples/Configurator/ConfiguratorRoot";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { merchantUserSchema, merchantUserModel } from "../../../models/merchantUserSchema";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import {useTranslation} from "react-i18next";


function NewUser({openConfigurator, setOpenConfigurator, user, merchant}) {
  const {t} = useTranslation();
  const [userLevelMaters, setUserLevelMasters] = useState([]);
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(merchantUserSchema)
  });
  useEffect(() => {
    getAllUsrLevelMasters();
    if(Object.keys(user).length > 0 ){
      delete user.authorities;
      reset(user);
    } else {
      if (merchant.merchant !== undefined) {
        let mobileNo = merchant.merchant.mobileNo;
        if (mobileNo.startsWith("263")) {
          mobileNo = "0" + mobileNo.substring(3);
        }
        if (mobileNo.startsWith("61")) {
          mobileNo = "0" + mobileNo.substring(2);
        }
        setValue("groupMid", merchant.merchant.mid);
        setValue("mobileNo", mobileNo);
        setValue("emailId", merchant.merchant.emailId);
      }
    }

  }, [merchant, user])
  const api = useApi();
  const getAllUsrLevelMasters = () => {
    api.get("utils/userLevelMasters")
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          setUserLevelMasters(response.data.body);
        }
      })
      .catch(error => {

      });
  }
  const createUserHandler = (data) => {
    api.post("merchantuser/create", data)
      .then((response) => {
        if(response.data.statusCodeValue === 200){
          toast.success(t('user_successfully_created'));
          setOpenConfigurator(false);
          window.location.reload();
        } else {
          toast.error(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error.response)
        toast.error(error.response.data.body)
      })
  }
  function updateUserHandler(data) {
    api.post("merchantuser/update", data)
      .then(response => {
        if(response.data.statusCodeValue === 200) {
          toast.success(t('user_successfully_updated'));
          reset(merchantUserModel);
          setOpenConfigurator(false);
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  function submitHandler() {
    if(Object.keys(user).length === 0 ){
      handleSubmit(createUserHandler)();
    } else {
      handleSubmit(updateUserHandler)();
    }
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{Object.keys(user).length > 0 ? t('update') : t('new')} {t('user')}</MDTypography>
          {/*<MDTypography variant="body2" color="text">*/}
          {/*  {updatedUser?.userId}*/}
          {/*</MDTypography>*/}
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => {
            reset(merchantUserModel);
            setOpenConfigurator(false);
          }
          }
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <Grid container spacing={2} justifyContent="center">
        <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
          <Grid item xs={6}>
            <MDInput
              type="text" label="MID" name="groupMid"
              error={errors.groupMid}
              helperText={errors.groupMid ? errors.groupMid.message : ''}
              fullWidth
              disabled
              {...register("groupMid")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('username')} name="userId"
                     error={errors.userId}
                     helperText={errors.userId ? errors.userId.message : ''}
                     fullWidth
                     {...register("userId")}
                     inputProps={{
                       autoCapitalize: 'words', // or 'words' for capitalizing each word
                     }}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('first_name')} name="firstName"
                     error={errors.firstName}
                     helperText={errors.firstName ? errors.firstName.message : ''}
                     fullWidth
                     {...register("firstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('last_name')} name="lastName"
                     error={errors.lastName}
                     helperText={errors.lastName ? errors.lastName.message : ''}
                     fullWidth
                     {...register("lastName")}
            />
          </Grid>

          <Grid item xs={6}>
            <MDInput type="text" label={t('mobile_no')} name="mobileNo"
                     error={errors.mobileNo}
                     helperText={errors.mobileNo ? errors.mobileNo.message : ''}
                     fullWidth
                     {...register("mobileNo")}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('email_id')} name="emailId"
                     error={errors.emailId}
                     helperText={errors.emailId ? errors.emailId.message : ''}
                     fullWidth
                     {...register("emailId")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel>{t('user_level')}</InputLabel>
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{height: '100%'}}
                    input={<OutlinedInput label={t('user_level')} />}
                  >
                    <MenuItem value="-1">- {t('select_user_level')} -</MenuItem>
                    {userLevelMaters.map(userLevel => (
                      <MenuItem key={userLevel.usrLvlCode} value={userLevel.usrLvlCode}>{userLevel.usrLvlCode}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <MDInput type="text" label={t('department')} name="dept"
                     error={errors.dept}
                     helperText={errors.dept ? errors.dept.message : ''}
                     fullWidth
                     {...register("dept")}
            />
          </Grid>
          <Grid item xs={12}>
            <MDButton onClick={() => submitHandler()} variant="gradient" color="info" style={{float : 'right'}}>
              {Object.keys(user).length > 0 ? t('update') : t('add')}
            </MDButton>
          </Grid>
        </FormProvider>
      </Grid>
    </ConfiguratorRoot>
  );
}

NewUser.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
  user : {},
  merchant: {}
}

NewUser.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
  user: PropTypes.object,
  merchant : PropTypes.object
}

export default NewUser;