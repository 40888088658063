/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState, forwardRef} from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout, setLicenseMessageShown } from "context";
import Snackbar from "@mui/material/Snackbar";
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, licenseMessage, licenseMessageShown } = controller;
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
    }
    const onClose = () => {
        setOpen(false);
        setLicenseMessageShown(dispatch, true);
    };
    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [pathname]);
    useEffect(() => {
        if(licenseMessage !== null && !licenseMessageShown && !open) {
            setOpen(true);
        }
    }, [licenseMessage]);

  return (
    <MDBox
      style={{padding: '0 24px 24px 24px'}}
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
        <Snackbar
            open={open}
            TransitionComponent={SlideTransition}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={onClose} severity="warning" sx={{ width: '100%' }}>
                {licenseMessage}
            </Alert>
        </Snackbar>
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
