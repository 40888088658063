/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import Icon from "@mui/material/Icon";
import ConfiguratorRoot from "../../examples/Configurator/ConfiguratorRoot";
import Divider from "@mui/material/Divider";
import UpdateUser from "./updateUser";
import NewUser from "./newUser";
import toast from "react-hot-toast";
import MDConfirmAlert from "../../components/MDConfirmAlert";
import {useTranslation} from "react-i18next";

function Users() {
  const {t} = useTranslation();
  const { userTablecolumns } = data();
  const [users, setUsers] = useState([]);
  const [openConfigurator, setOpenConfigurator] = useState(false);
  const [openNewUserConfigurator, setOpenNewUserConfigurator] = useState(false);
  const [updateUser, setUpdateUser] = useState({});
  const [deleteUser, setDeleteUser] = useState({});
  const api = useApi();

  const updateUserHandler = (user) => {
    setUpdateUser(user);
    setOpenConfigurator(true);
  };

  const handleDeleteItem = () => {
    api.get("/users/delete?id="+deleteUser.id)
    .then((response) => {
      if(response.data.statusCodeValue === 200) {
        toast.success('Item deleted successfully!', {
          icon: '🗑️',
          duration: 3000,
        });
      }

    });
  };

  const handleConfirmDelete = (user) => {
    setDeleteUser(user);
    toast.custom((t) => (
      <MDConfirmAlert
        text="Do you want to delete this user?"
        toast={toast}
        t={t}
        confirmFunction={() => handleDeleteItem()}
      />
    ), {
      duration: Infinity,
    });
  };
  const getUsers = () => {
    api.get("users/browse")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          // console.log(response.data);
          const modifiedData = response.data.body.map(item => {
            const action = (
              <MDBox display="flex" alignItems="center" lineHeight={1}>
                <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => updateUserHandler(item)}>create</Icon>
                <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => handleConfirmDelete(item)}>delete</Icon>
              </MDBox>
            );
            if(item.role==='MAKER'){
              item.role = 'ADMIN';
            }
            return { ...item, action };
          });
          setUsers(modifiedData);
        } else {
          toast.error(response.data.statusCode);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(openConfigurator === false && openNewUserConfigurator === false) {
      getUsers();
    }
  },[openConfigurator, openNewUserConfigurator]);

  useEffect(() => {
      getUsers();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDButton color="info" size="medium" variant="contained" onClick={() => setOpenNewUserConfigurator(true)}>New User</MDButton>
            </MDBox>
            <DataTable
              table={{ columns : userTablecolumns, rows : users }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
            />
          </Grid>
        </Grid>
      </MDBox>
      <UpdateUser user={updateUser} openConfigurator={openConfigurator} setOpenConfigurator={setOpenConfigurator} />
      <NewUser openConfigurator={openNewUserConfigurator} setOpenConfigurator={setOpenNewUserConfigurator} />
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
