/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import i18next from '../../../../../components/Utils/i18next';
import {Chip} from "@mui/material";

export default function data() {

  function formatNumber(n) {
    let parts = n.toString().split(".");

    // Apply comma as thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Ensure three decimal places
    if (parts[1]) {
      if (parts[1].length === 1) {
        parts[1] += "0"; // Add two zeros if there's only one digit
      } else if (parts[1].length === 2) {
        parts[1] += ""; // Add one zero if there are two digits
      } else if (parts[1].length > 3) {
        parts[1] = parts[1].substr(0, 3); // Truncate to three digits if longer
      }
      // No else needed for exactly three digits
    } else {
      parts[1] = "00"; // Add ".000" if there's no decimal part
    }

    return parts.join(".");
  }
  function readableFormatDate(dateStr){
    const dateObj = new Date(dateStr);

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    const period = dateObj.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds} ${period}`;
    return formattedDate;
  }
  function maskSensitiveInfo(message) {
    // Mask OTP
    message = message.replace(/\b(OTP\s*for\s*forgot\s*password\s*:\s*)\d+\b/gi, '$1****');

    // Mask Password
    message = message.replace(/\b(Password\s*:\s*)\S+\b/g, '$1******');

    return message;
  }
  function formatDate(inputDate) {
    // Parse the input date
    const dateObject = new Date(inputDate);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }

    // Format the date part (YYYY-MM-DD)
    const formattedDate = dateObject.toISOString().split('T')[0];

    // Check if the input date includes time
    if (inputDate.includes(':')) {
      // Format the date with time part (YYYY-MM-DD HH:MM:SS)
      const formattedDateTime = dateObject.toISOString().replace('T', ' ').split('.')[0];
      return formattedDateTime;
    }

    return formattedDate;
  }

  return {
    auditTrailcolumns: [
      { Header: () => i18next.t("auditTrailcolumns.userId"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.userName"), accessor: "userName", width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.module"), accessor: "module", width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.subModule"), accessor: "subModule", width: "10%", align: "left" },
      { Header: i18next.t("auditTrailcolumns.actTime"), accessor: row => formatDate(row.actTime), width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.actDesc"), accessor: "actDesc", width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.reqIp"), accessor: "reqIp", width: "10%", align: "left" },
      { Header: () => i18next.t("auditTrailcolumns.userLevel"), accessor: "userLevel", width: "10%", align: "left" },
    ],
    transactionSummarycolumns: [
      { Header: i18next.t("transactionSummarycolumns.date"), accessor: row => formatDate(row.date), width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.merchantName"), accessor: "merchantName", width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.cardAcceptorCode"), accessor: "cardAcceptorCode", width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.totalNumOfTrans"), accessor: "totalNumOfTrans", width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.currency"), accessor: "currency", width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.amount"), accessor: row => formatNumber(row.amount / 100), width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.status"), accessor: "status", width: "10%", align: "left" },
      { Header: i18next.t("transactionSummarycolumns.merchantId"), accessor: "merchantId", width: "10%", align: "left" },
    ],
    transactionReportcolumns: [
      { Header: () => i18next.t("transactionReportcolumns.merchantId"), accessor: "merchantId", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.terminalId"), accessor: "terminalId", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.rrn"), accessor: "rrn", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.stan"), accessor: "stan", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.transactionAmount"), accessor: row => formatNumber(row.transactionAmount), width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.type"), accessor: "type", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.status"), accessor: "status", width: "10%", align: "left" },
      { Header: () => i18next.t("transactionReportcolumns.transactionDate"), accessor: row => formatDate(row.transactionDate), width: "10%", align: "left" },
    ],
    userTablecolumns: [
      { Header: () => i18next.t("userTablecolumns.user_id"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.firstName"), accessor: "firstName", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.lastName"), accessor: "lastName", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.mobileNo"), accessor: "mobileNo", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.emailId"), accessor: "emailId", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.role"), accessor: "role", width: "10%", align: "left" },
      { Header: () => i18next.t("userTablecolumns.action"), accessor: "action", width: "10%", align: "left" },
    ],
    merchantTablecolumns: [
      { Header: () => i18next.t("merchantTablecolumns.mid"), accessor: "mid", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantTablecolumns.companyName"), accessor: "companyName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantTablecolumns.ownerName"), accessor: "ownerName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantTablecolumns.organization"), accessor: "referenceId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantTablecolumns.status"), accessor: "status", Cell: ({cell}) => {
        return cell.value === "1" ? <Chip label="Active" variant="outlined" color="success" /> : cell.value === "3" ? <Chip label="Block" variant="outlined" color="error" /> : cell.value === "2" ? <Chip label="Approval Pending" variant="outlined" color="warning" /> : <Chip label="In Active" variant="outlined" color="secondary" />;
        }, width: "10%", align: "left" },
    ],
    merchantGroupTablecolumns: [
      { Header: () => i18next.t("merchantGroupTablecolumns.referenceId"), accessor: "referenceId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantGroupTablecolumns.referenceCompanyName"), accessor: "referenceCompanyName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantGroupTablecolumns.ownerName"), accessor: "ownerName", width: "10%", align: "left" },
      { Header: i18next.t("merchantGroupTablecolumns.status"), accessor: row => row.status === "1" ? 'Active' : row.status === "3" ? 'Block' : 'In Active', width: "10%", align: "left" },
    ],
    terminalTablecolumns: [
      { Header: () => i18next.t("terminalTablecolumns.mid"), accessor: "mid", width: "10%", align: "left" },
      { Header: () => i18next.t("terminalTablecolumns.msisdn"), accessor: "msisdn", width: "10%", align: "left" },
      { Header: () => i18next.t("terminalTablecolumns.tid"), accessor: "tid", width: "10%", align: "left" },
      { Header: () => i18next.t("terminalTablecolumns.deviceType"), accessor: "deviceType", width: "10%", align: "left" },
      { Header: () => i18next.t("terminalTablecolumns.name"), accessor: "name", width: "10%", align: "left" },
      { Header: i18next.t("terminalTablecolumns.status"), accessor: row => row.status === "1" ? 'Active' : row.status === "3" ? 'Block' : 'In Active', width: "10%", align: "left" },
      { Header: () => i18next.t("terminalTablecolumns.action"), accessor: "action", width: "10%", align: "left" }
    ],
    allTerminalTablecolumns: [
      { Header: () => i18next.t("allTerminalTablecolumns.mid"), accessor: "mid", width: "10%", align: "left" },
      { Header: () => i18next.t("allTerminalTablecolumns.msisdn"), accessor: "msisdn", width: "10%", align: "left" },
      { Header: () => i18next.t("allTerminalTablecolumns.tid"), accessor: "tid", width: "10%", align: "left" },
      { Header: () => i18next.t("allTerminalTablecolumns.deviceType"), accessor: "deviceType", width: "10%", align: "left" },
      { Header: () => i18next.t("allTerminalTablecolumns.name"), accessor: "name", width: "10%", align: "left" },
      { Header: i18next.t("allTerminalTablecolumns.status"), accessor: row => row.status === "1" ? 'Active' : row.status === "3" ? 'Block' : 'In Active', width: "10%", align: "left" },
    ],
    resetTerminalTablecolumns: [
      { Header: () => i18next.t("resetTerminalTablecolumns.msisdn"), accessor: "msisdn", width: "10%", align: "left" },
      { Header: () => i18next.t("resetTerminalTablecolumns.imei"), accessor: "imei", width: "10%", align: "left" },
      { Header: () => i18next.t("resetTerminalTablecolumns.imsi"), accessor: "imsi", width: "10%", align: "left" },
      { Header: () => i18next.t("resetTerminalTablecolumns.tid"), accessor: "tid", width: "10%", align: "left" },
      { Header: () => i18next.t("resetTerminalTablecolumns.deviceType"), accessor: "deviceType", width: "10%", align: "left" },
      { Header: i18next.t("resetTerminalTablecolumns.status"), accessor: row => row.status === "1" ? 'Active' : row.status === "3" ? 'Block' : 'In Active', width: "10%", align: "left" },
      { Header: () => i18next.t("resetTerminalTablecolumns.action"), accessor: "action", width: "10%", align: "left" }
    ],
    mposUsersTablecolumns: [
      { Header: () => i18next.t("mposUsersTablecolumns.userId"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("mposUsersTablecolumns.firstName"), accessor: "firstName", width: "10%", align: "left" },
      { Header: () => i18next.t("mposUsersTablecolumns.lastName"), accessor: "lastName", width: "10%", align: "left" },
      { Header: () => i18next.t("mposUsersTablecolumns.mobileNo"), accessor: "mobileNo", width: "10%", align: "left" },
      { Header: () => i18next.t("mposUsersTablecolumns.status"), accessor: row => row.status === "1" ? 'Active' : row.status === "3" ? 'Block' : 'In Active', width: "10%", align: "left" },
      { Header: () => i18next.t("mposUsersTablecolumns.action"), accessor: "action", width: "10%", align: "left" }
    ],
    merchatnUsersTablecolumns: [
      { Header: () => i18next.t("merchatnUsersTablecolumns.user_id"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.firstName"), accessor: "firstName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.lastName"), accessor: "lastName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.mobileNo"), accessor: "mobileNo", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.emailId"), accessor: "emailId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.authorities[0].authority"), accessor: "authorities[0].authority", width: "10%", align: "left" },
      { Header: () => i18next.t("merchatnUsersTablecolumns.action"), accessor: "action", width: "10%", align: "left" },
    ],
    merchantAllUsersTablecolumns: [
      { Header: () => i18next.t("merchantAllUsersTablecolumns.userId"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.firstName"), accessor: "firstName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.groupMid"), accessor: "groupMid", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.lastName"), accessor: "lastName", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.mobileNo"), accessor: "mobileNo", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.emailId"), accessor: "emailId", width: "10%", align: "left" },
      { Header: () => i18next.t("merchantAllUsersTablecolumns.authorities[0].authority"), accessor: "authorities[0].authority", width: "10%", align: "left" },
    ],
    thirtydaysActiveTablecolumns: [
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.userId"), accessor: "userId", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.tid"), accessor: "tid", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.fname"), accessor: "fname", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.lname"), accessor: "lname", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.mobNo"), accessor: "mobNo", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.gmid"), accessor: "gmid", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.st"), accessor: "st", width: "10%", align: "left" },
      { Header: () => i18next.t("thirtydaysActiveTablecolumns.ldt"), accessor: "ldt", width: "10%", align: "left" },
    ],
    cashTransactionsReportsColumns: [
      { Header: () => i18next.t("cashTransactionsReportsColumns.merchantId"), accessor: "merchantId", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.posId"), accessor: "posId", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.transactionNumber"), accessor: "transactionNumber", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.retrievalreferencenumber"), accessor: "retrievalreferencenumber", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.username"), accessor: "username", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.txamount"), accessor: row => formatNumber(row.txamount), width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.transactionType"), accessor: "transactionType", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.transactionDate"), accessor: "transactionDate", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.statusDesc"), accessor: "statusDesc", width: "10%", align: "left" },
      { Header: () => i18next.t("cashTransactionsReportsColumns.reason"), accessor: "reason", width: "10%", align: "left" },
    ],
    cardTransactionsReportsColumns: [
      { Header: () => i18next.t("cardTransactionsReportsColumns.createdTime"), accessor: 'createdTime', width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.merchantTransactionId"), accessor: "merchantTransactionId", width: "10%", align: "left" },
      { Header: i18next.t("cardTransactionsReportsColumns.amountInCents"), accessor: row => formatNumber(row.amountInCents / 100), width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.currency"), accessor: "currency", width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.status"), accessor: 'status', width: "10%", align: "left" },
      { Header: i18next.t("cardTransactionsReportsColumns.isQrPayment"), accessor: row => row.isQrPayment ? 'Yes' : 'No', width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.paymentMethodType"), accessor: "paymentMethodType", width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.paymentType"), accessor: "paymentType", width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.description"), accessor: "description", width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.merchantId"), accessor: "merchantId", width: "10%", align: "left" },
      { Header: () => i18next.t("cardTransactionsReportsColumns.paymentId"), accessor: "paymentId", width: "10%", align: "left" },

    ],
    emailTablecolumns: [
      { Header: () => i18next.t("emailTablecolumns.emailid"), accessor: "emailid", width: "20%", align: "left" },
      { Header: i18next.t("emailTablecolumns.maildate"), accessor: row => formatDate(row.maildate), width: "20%", align: "left" },
      { Header: () => i18next.t("emailTablecolumns.email"), accessor: "email", width: "50%", align: "left" },
      { Header: () => i18next.t("emailTablecolumns.response"), accessor: "response", width: "10%", align: "left" }
    ],
    smsTablecolumns: [
      { Header: () => i18next.t("smsTablecolumns.mobileNo"), accessor: "mobileNo", width: "10%", align: "left" },
      { Header: i18next.t("smsTablecolumns.otpDate"), accessor: row => formatDate(row.otpDate), width: "10%", align: "left" },
      { Header: i18next.t("smsTablecolumns.sms"), accessor: row => maskSensitiveInfo(row.sms), width: "10%", align: "left", cell: ({cell}) => (<div style={{ whiteSpace: 'pre-wrap' }}>{cell.value}</div>) },
      { Header: i18next.t("smsTablecolumns.status"), accessor: row => row.status === "1" ? 'Sent': 'Not Sent', width: "10%", align: "left" }
    ],
    onboardingFilesTableColumns: [
      { Header: () => i18next.t("onboardingFilesTableColumns.mbfFileName"), accessor: "mbfFileName", width: "10%", align: "left" },
      { Header: () => i18next.t("onboardingFilesTableColumns.mbfTotalRecords"), accessor: "mbfTotalRecords", width: "10%", align: "left" },
      { Header: () => i18next.t("onboardingFilesTableColumns.brfFileName"), accessor: "", width: "10%", align: "left" },
      { Header: () => i18next.t("onboardingFilesTableColumns.brfTotalRecords"), accessor: "brfTotalRecords", width: "10%", align: "left" },
      { Header: () => i18next.t("onboardingFilesTableColumns.totalProcessedRecords"), accessor: "totalProcessedRecords", width: "10%", align: "left" }
    ],
    setllementBatchesColumns: [
      { Header: () => i18next.t("setllementBatchesColumns.batchId"), accessor: "batchId", width: "10%", align: "left" },
      { Header: i18next.t("setllementBatchesColumns.date"), accessor: row => formatDate(row.date), width: "10%", align: "left" },
      { Header: () => i18next.t("setllementBatchesColumns.status"), accessor: "status", width: "10%", align: "left" },
      { Header: () => i18next.t("setllementBatchesColumns.action"), accessor: "action", width: "10%", align: "left" },
    ],
    cardSettlementSummaryTableColumns: [
      { headerName: i18next.t("cardSettlementSummaryTableColumns.cardAcceptorCode"), field: "cardAcceptorCode", flex: 1, align: "left", minWidth: 200 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.merchantName"), field: "merchantName", flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.totalNumberOfTransactions"), field: "totalNumberOfTransactions", flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.transactionAmount"), field: "transactionAmount", valueGetter: params => formatNumber(params.row.transactionAmount), flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.issuerFee"), field: "issuerFee", valueGetter: params => formatNumber(params.row.issuerFee), flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.bankFee"), field: "bankFee", valueGetter: params => formatNumber(params.row.bankFee), flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.settlementFee"), field: "settlementFee", valueGetter: params => formatNumber(params.row.settlementFee ), flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.settlementAmount"), field: "settlementAmount", valueGetter: params => formatNumber(params.row.settlementAmount), flex: 1, align: "left", minWidth: 150 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.currency"), field: "currency", flex: 1, align: "left", minWidth: 100 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.status"), field: "status", renderCell: params => {
            let val = "";
            if (params.row.status === "SETTLEMENT_INITIATED") {
              val = <Chip variant="outlined" color="primary" label="Initiated" />;
            } else if (params.row.status === "AUTHORIZED" || params.row.status === "COMPLETED" ) {
              val = <Chip variant="outlined" color="warning" label="Pending" />;
            } else if (params.row.status === "FAILED" ) {
              val = <Chip variant="outlined" color="error" label="Failed" />;
            } else if (params.row.status === "SETTLED" ) {
              val = <Chip variant="outlined" color="success" label="Settled" />;
            }
            return val;
          },
        flex: 1, align: "left", minWidth: 250 },
      { headerName: i18next.t("cardSettlementSummaryTableColumns.merchantId"), field: "merchantId", flex: 1, align: "left", minWidth: 320 },
      // { headerName: "Action", field: "action", flex: 1, align: "left", renderCell: (params) => params.row.action },
    ],
    mdrMasterTableColumns: [
      { Header: i18next.t("mdrMasterTableColumns.mid"), accessor: "mid", width: "10%", align: "left" },
      { Header: i18next.t("mdrMasterTableColumns.companyName"), accessor: "companyName", width: "10%", align: "left" },
      { Header: i18next.t("mdrMasterTableColumns.cardHoldercharger"), accessor: "cardHoldercharger", width: "10%", align: "left" },
      { Header: i18next.t("mdrMasterTableColumns.merchantMDr"), accessor: "merchantMDr", width: "10%", align: "left" },
      // { Header: "Status", accessor: row => row.status === '0' || row.status === '2' ? 'In Active' : 'Active', width: "10%", align: "left" },
      { Header: i18next.t("mdrMasterTableColumns.action"), accessor: "action", width: "10%", align: "left" },
    ],
    binMasterTableColumns: [
      { Header: i18next.t("binMasterTableColumns.binCode"), accessor: "binCode", width: "10%", align: "left" },
      { Header: i18next.t("binMasterTableColumns.bankMaster.bankName"), accessor: row => row.bankMaster.bankName, width: "10%", align: "left" },
      { Header: i18next.t("binMasterTableColumns.cardTypeMaster.typeName"), accessor: row => row.cardTypeMaster.typeName, width: "10%", align: "left" },
      { Header: i18next.t("binMasterTableColumns.productTypeMaster.typeName"), accessor: row => row.productTypeMaster.typeName, width: "10%", align: "left" },
      { Header: i18next.t("binMasterTableColumns.action"), accessor: "action", width: "10%", align: "left" },
    ],
    posTransactionReportsColumns: [
      { Header: i18next.t("posTransactionReportsColumns.transactionDate"), accessor: row => row[0].transactionDate, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.pan"), accessor: row => row[0].pan, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.deviceType"), accessor:row => row[2].deviceType, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.merchantId"), accessor: row => row[0].merchantId, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.posId"), accessor: row => row[0].posId, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.amount"), accessor: row => row[0].amount === null ? '00.000' : formatNumber(row[0].amount), width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.retrievalreferencenumber"), accessor: row => row[0].retrievalreferencenumber, width: "10%", align: "left" },
      // { Header: "Card Holder Surcharge (%)", accessor:row => row[0].cardSurchagePercentage, width: "10%", align: "left" },
      // { Header: "Card Holder Surcharge Amount", accessor:row => row[0].cardSurchageAmount, width: "10%", align: "left" },
      // { Header: "Merchant Service Fee (%)", accessor: row => row[0].msfPercentage, width: "10%", align: "left" },
      // { Header: "Merchant Service Fee Amount", accessor: row => row[0].msfAmount, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.mobileRefNo"), accessor: row => row[0].mobileRefNo, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.companyName"), accessor: row => row[1].companyName, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.responsecode"), accessor: row => row[0].responsecode, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.status"), accessor: row => row[0].responsecode === "00" ? 'Success' : 'Fail', width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.reversal_status"), accessor: row => row[0].status === '01' ? 'Void Transaction' : '', width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.refund_status"), accessor: row => row[0].status === '02' ? 'Refund Transaction' : '', width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.appName"), accessor: row => row[0].authid, width: "10%", align: "left" },
      // { Header: "Invoice No", accessor: row => row.invoiceNo, width: "10%", align: "left" },
      { Header: i18next.t("posTransactionReportsColumns.appName"), accessor: row => row[0].appName, width: "10%", align: "left" },
    ],
    settlementReportsColumns: [
      { Header: i18next.t("settlementReportsColumns.transactionDate"), accessor: row => row[0].transactionDate, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.pan"), accessor: row => row[0].pan, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.retrievalreferencenumber"), accessor: row => row[0].retrievalreferencenumber, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.merchantId"), accessor: row => row[0].merchantId, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.posId"), accessor: row => row[0].posId, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.amount"), accessor: row => row[0].amount === null ? '00.000' : formatNumber(row[0].amount), width: "10%", align: "left" },
      // { Header: "Issuer Name", accessor: row => row[0].issuerName, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.companyName"), accessor: row => row[1].companyName, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.accountNo"), accessor: row => row[1].accountNo, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.bankName"), accessor: row => row[1].bankName, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.cardSurchagePercentage"), accessor: row => row[0].cardSurchagePercentage, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.cardSurchageAmount"), accessor: row => formatNumber(row[0].cardSurchageAmount), width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.msfPercentage"), accessor: row => row[0].msfPercentage, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.msfAmount"), accessor: row => formatNumber(row[0].msfAmount), width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.merchantSettlementAmount"), accessor: row => formatNumber(row[0].merchantSettlementAmount), width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.posMachineType"), accessor: row => row[1].posMachineType, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.appName"), accessor: row => row[0].appName, width: "10%", align: "left" },
      // { Header: "Branch Name", accessor: row => row[1].bankerBranchName, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.responsecode"), accessor: row => row[0].responsecode === '00' ? 'Success' : 'Fail', width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.reversal_status"), accessor: row => row[0].status === "01" ? 'Void Transaction' : '', width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.authid"), accessor: row => row[0].authid, width: "10%", align: "left" },
      { Header: i18next.t("settlementReportsColumns.invoiceNo"), accessor: row => row[0].invoiceNo, width: "10%", align: "left" },
    ],
    ruleTableColumns: [
      { Header: i18next.t("ruleTableColumns.ruleID"), accessor: "ruleID", width: "10%", align: "left" },
      { Header: i18next.t("ruleTableColumns.conditionField"), accessor: rule => 'IF '+rule.conditionField.toUpperCase()+' IS '+ (rule.conditionOperator.toUpperCase() === 'BETWEEN' ? ' FROM '+rule.conditionFromValue+' TO '+rule.conditionToValue  : rule.conditionFromValue)
          +' THEN ACQUIRER IS '+rule.acquirer.name, width: "10%", align: "left" },
      { Header: i18next.t("ruleTableColumns.action"), accessor: "action", width: "10%", align: "left" }
    ],
  };
}
