import PropTypes from "prop-types";
import NewUser from "../../users/newUser";
import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import React from "react";

function RoleSection(props) {
  const {roleSection, sectionName} = props;
  const handleSwitchChange = (event, name) => {
    const hiddenInput = document.getElementById(`${name}`);
    hiddenInput.value = event.target.checked;
  };

  return(
    <>
      <Grid item xs={12} md={12} lg={12} >
        <FormControl component="fieldset" variant="standard">
          <FormLabel style={{marginBottom: "0.6rem", fontSize: "1.05rem" }} component="legend">{sectionName}</FormLabel>
          <FormGroup row>
            {roleSection.map((section, key) => (<>
              <FormControlLabel
                control={
                  <Switch defaultChecked={section[2]} onChange={e => handleSwitchChange(e, `${sectionName}-${section[0]}-${section[1]}`)} />
                }
                label={section[0]}
                style={{
                  border: "1px solid rgba(204, 204, 204, 0.2)",
                  borderRadius: "8px",
                  padding: "0 8px",
                  boxShadow: "0px 10px 20px 16px rgba(204, 204, 204, 0.1)",
                  marginLeft: key === 0 ? '8px': ''
                }}
                key={`${sectionName}-${section[0]}-${section[1]}`}
              />
              <input
              type="hidden"
              id={`${sectionName}-${section[0]}-${section[1]}`}
              name={`${sectionName}-${section[0]}-${section[1]}`}
              value={section[2]}
              />
            </>))}

          </FormGroup>
          {/*<FormHelperText>Be careful</FormHelperText>*/}
        </FormControl>
      </Grid>
    </>
  );
}

RoleSection.defaultProps = {
  roleSection: [],
  sectionName: ''
}

RoleSection.propTypes = {
  roleSection: PropTypes.array.isRequired,
  sectionName: PropTypes.string.isRequired
}

export default React.memo(RoleSection);