import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Typography,
  CardContent
} from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "../../components/MDButton";
import PermissionControl from "../../components/Utils/PermissionControl";

const RuleCreator = ({ onRuleAdd, acquirers }) => {
  const [conditionField, setConditionField] = useState('');
  const [conditionOperator, setConditionOperator] = useState('');
  const [conditionFromValue, setConditionFromValue] = useState('');
  const [conditionToValue, setConditionToValue] = useState('');
  const [acquirer, setAcquirer] = useState('');
  const [priority, setPriority] = useState('');

  const handleAddRule = () => {
    const newRule = {
      conditionField,
      conditionOperator,
      conditionFromValue,
      conditionToValue,
      acquirer,
      priority
    };
    onRuleAdd(newRule);
  };
  useEffect(() => {
    if (conditionField === 'amount'){
      setConditionOperator('between');
    } else {
      setConditionOperator('equal');
    }
  },[conditionField]);

  return (
    <>
      <Card variant="outlined" style={{ marginBottom: '10px' }}>
        <CardContent>
          <Typography sx={{ fontSize: 16, marginBottom: '15px' }} color="text.primary" gutterBottom>
            Add Rule
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} lg={6}>
              <FormControl variant="outlined" fullWidth style={{height: '100%'}}>
                <InputLabel id="condition-field">Condition Field</InputLabel>
                <Select
                  value={conditionField}
                  onChange={(e) => setConditionField(e.target.value)}
                  label="Condition Field"
                  labelId="condition-field"
                  fullWidth
                  style={{height: '100%'}}
                >
                  <MenuItem value="">Select Condition Field</MenuItem>
                  <MenuItem value="amount">Amount</MenuItem>
                  <MenuItem value="bin">BIN</MenuItem>
                  <MenuItem value="mid">MID</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl variant="outlined" fullWidth style={{height: '100%'}}>
                <InputLabel id="condition-operator">Condition Operator</InputLabel>
                <Select
                  value={conditionOperator}
                  onChange={(e) => setConditionOperator(e.target.value)}
                  label="Condition Operator"
                  labelId="condition-operator"
                  fullWidth
                  style={{height: '100%'}}
                  disabled
                >
                  <MenuItem value="">Select Condition</MenuItem>
                  <MenuItem value="greater">Greater Than</MenuItem>
                  <MenuItem value="lesser">Lesser Than</MenuItem>
                  <MenuItem value="equal">Equal To</MenuItem>
                  <MenuItem value="between">Between</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={conditionField === 'amount' ? 6 : 12}>
              <TextField
                type="text"
                value={conditionFromValue}
                onChange={(e) => setConditionFromValue(e.target.value)}
                label={conditionField === 'amount' ? 'From' : 'Value'}
                fullWidth
              />
            </Grid>
            {conditionField === 'amount' && <Grid item xs={6} lg={6}>
              <TextField
                variant="outlined"
                type="text"
                value={conditionToValue}
                onChange={(e) => setConditionToValue(e.target.value)}
                label="To"
                fullWidth
              />
            </Grid> }
            <Grid item xs={6} lg={12}>
              <FormControl variant="outlined" fullWidth style={{height: '100%'}}>
                <InputLabel id="acquirer">Acquirer</InputLabel>
                <Select
                  value={acquirer}
                  onChange={(e) => setAcquirer(e.target.value)}
                  label="Acquirer"
                  labelId="acquirer"
                  fullWidth
                  style={{height: '100%'}}
                >
                  <MenuItem value="">Select Acquirer</MenuItem>
                  {acquirers.map( acquirer => <MenuItem key={acquirer.id} value={acquirer.id}>{acquirer.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <PermissionControl permission={{ restrict: true, module: "RoutingRule", subModule: "Create" }}>
                <MDButton
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  onClick={handleAddRule}
                  color="secondary"
                  style={{float: 'right'}}
                >
                  Add Rule
                </MDButton>
              </PermissionControl>
              {/*<Button variant="contained" color="primary" onClick={handleAddRule} style={{float: 'right'}}>*/}
              {/*  Add Rule*/}
              {/*</Button>*/}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
RuleCreator.propTypes = {
  onRuleAdd: PropTypes.any,
  acquirers: PropTypes.array
}
export default RuleCreator;