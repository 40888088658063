/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../examples/Tables/DataTable";
import data from "../dashboard/components/Projects/data";
import useApi from "../../components/Utils/api";
import MDInput from "../../components/MDInput";
import toast from "react-hot-toast";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import BulkAssignMerchant from "./bulk-assign";
import {useTranslation} from "react-i18next";

function MerchantAllTerminals() {
    const {t} = useTranslation();
  const { allTerminalTablecolumns } = data();
  const [merchants, setMerchants] = useState([]);
  const api = useApi();
  const getMerchants = () => {
    const data = {
        mid: "",
        msisdn: ""
    };
    api.post("terminal/browse",data)
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setMerchants(response.data.body);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
          if (error.response && error.response.status === 403) {
              toast.error(t('you_do_not_have_permission_to_perform_this_action'), { id: "PermissionError" });
              return;
          }
        console.log(error.response.data);
      })
  }

  useEffect(() => {
    getMerchants();
  },[]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          {/*<Grid container justifyContent="flex-end">*/}
          {/*    <MDButton variant="gradient" onClick={() => navigate('/merchants/new')} color="info" style={{marginRight: "24px", marginBottom: "12px"}}>*/}
          {/*        Add Merchant*/}
          {/*    </MDButton>*/}
          {/*</Grid>*/}
          <Grid item xs={12} lg={12}>
            <DataTable
              table={{ columns : allTerminalTablecolumns, rows : merchants }}
              showTotalEntries={true}
              isSorted={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
            />
          </Grid>
        </Grid>
      </MDBox>
       <Footer />
    </DashboardLayout>
  );
}

export default MerchantAllTerminals;
