import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "@mui/material/Table";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import useApi from "../../../components/Utils/api";
import MDInput from "../../../components/MDInput";
import toast from "react-hot-toast";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdrDefaultModel, mdrDefaultSchema } from "../../../models/MdrDefaultMaster";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import PermissionControl from "../../../components/Utils/PermissionControl";

function MdrDefaultMaster() {
  const [masters, setMasters] = useState([]);
  const { register, handleSubmit, reset, formState: { errors }, trigger, watch, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(mdrDefaultSchema),
  });
  const api = useApi();

  function updateMasterHandler(data) {
    api.post("master/updateDefaultMdrMaster", data)
      .then(response => {
        if(response.data.statusCodeValue === 200){
          toast.success(response.data.body);
        }
      })
      .catch(error => {
        toast.error(error.response.data.body);
      })
  }
const submitHandler = () => {
    handleSubmit(updateMasterHandler)();
}
  const getMasters = () => {
    api.post("master/getDefaultMdrMaster")
      .then((response) => {
        if(response.data.statusCodeValue === 200) {
          setMasters(response.data.body[0]);
          reset(response.data.body[0]);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        toast.error(error.response.data.body);
      })
  }
  useEffect(() => {
      getMasters();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <FormProvider {...{ register, handleSubmit, reset, formState: { errors }, trigger, watch, control }}>
            <Grid item xs={6}>
              <MDInput
                type="text" label="Cardholder Surcharge (%)" name="cardHoldercharger"
                error={errors.cardHoldercharger}
                helperText={errors.cardHoldercharger ? errors.cardHoldercharger.message : ''}
                fullWidth
                {...register("cardHoldercharger")}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="text" label="Merchant Service Fee (%)" name="merchantMDr"
                error={errors.merchantMDr}
                helperText={errors.merchantMDr ? errors.merchantMDr.message : ''}
                fullWidth
                {...register("merchantMDr")}
              />
            </Grid>
          </FormProvider>
          <Grid container justifyContent="flex-end" style={{ marginTop: '10px'}}>
            <PermissionControl permission={{ restrict: true, module: "Masters", subModule: "UpdateDefaultMdrMaster" }}>
              <MDButton variant="gradient" onClick={() => submitHandler()} color="info" style={{marginBottom: "12px"}}>
                Update
              </MDButton>
            </PermissionControl>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MdrDefaultMaster;
