/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import './components/Utils/i18next';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import ValidationProvider from './context/validation/ValidationProvider';
import CacheBuster from "react-cache-buster";
import packageInfo from '../package.json';
const { version } = packageInfo;


const container = document.getElementById("app");
const root = createRoot(container);
const isProduction = process.env.NODE_ENV === 'production';
root.render(
    <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <ValidationProvider>
            <App />
          </ValidationProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </CacheBuster>
);
