import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import {isEmpty} from "../../../components/Utils/common";

import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import NewUser from "../../users/newUser";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Controller } from 'react-hook-form';
import {useTranslation} from "react-i18next";
import useAllowOnlyNumber from "../../../hooks/useAllowOnlyNumber";


function MerchantOfficeDetails({merchant, editmode, setMerchant, type}) {
  const {t} = useTranslation();
  const handleKeyDown = useAllowOnlyNumber();
  const [referenceCompany, setReferenceCompany] = useState("");
  const [masterType, setMasterType] = useState("");
  const [merchantCode, setMerchantCode] = useState("");
  const { register, formState: { errors }, requiredInfo, control } = useFormContext();

  useEffect(() => {
    if(merchant?.merchant?.referenceId) {
      setReferenceCompany(merchant?.merchant?.referenceId);
      setMasterType(merchant?.merchant?.microAtmStatus);
      // setMerchantCode(merchant?.merchant?.merchantCategoryCode.merchantCode);
    }
  }, [merchant]);

  // console.log("ref: ", merchant?.merchant?.referenceId);
  return (
      <Grid mt={3} mb={3} padding={2}>
        <Grid container spacing={2} justifyContent="center">
          {type === "update" ? <Grid item xs={3}>
            <MDInput
              type="text"
              label={t('transactionSummarycolumns.cardAcceptorCode')}
              name="mid"
              error={errors?.mid}
              helperText={errors.mid ? errors.mid.message : ''}
              {...register("mid")}
              fullWidth
              disabled={type === "update"}
            />
          </Grid> : <></>}
          <Grid item xs={3}>
            <MDInput type="text" label={t('registered_business_name')} name="companyName"
                     error={errors.companyName}
                     helperText={errors.companyName ? errors.companyName.message : ''}
                     disabled={!editmode}
                     fullWidth
                     {...register("companyName")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="demo-simple-select-label">{t('type_of_entity')}</InputLabel>
              <Controller
                name="typeEstablishment"
                control={control} // make sure to get "control" from useForm()
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('type_of_entity')}
                    fullWidth
                    {...field}
                    error={errors.typeEstablishment}
                    helperText={errors.typeEstablishment ? errors.typeEstablishment.message : ''}
                    style={{height: '100%'}}
                    disabled={!editmode}
                  >
                    <MenuItem value="">-{t('type_of_entity')}-</MenuItem>
                    <MenuItem value="Proprietorship">Proprietorship</MenuItem>
                    <MenuItem value="Partnership" >Partnership</MenuItem>
                    <MenuItem value="Pvt. Ltd. / Public Ltd." >Pvt. Ltd. / Public Ltd.</MenuItem>
                    <MenuItem value="Company" >Company</MenuItem>
                    <MenuItem value="Trust" >Trust</MenuItem>
                    <MenuItem value="LLP" >LLP</MenuItem>
                    <MenuItem value="Club" >Club</MenuItem>
                    <MenuItem value="NGO" >NGO</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('registered_business_address')} name="address"
                     {...register("address")}
                     error={errors.address}
                     helperText={errors.address ? errors.address.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('actual_business_address')} name="addressRes"
                     {...register("addressRes")}
                     error={errors.addressRes}
                     helperText={errors.addressRes ? errors.addressRes.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="Zip Code" name="pinCode"*/}
          {/*           {...register("pinCode")}*/}
          {/*           error={errors.pinCode}*/}
          {/*           helperText={errors.pinCode ? errors.pinCode.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="MCC" name="mccCode"*/}
          {/*           {...register("mccCode")}*/}
          {/*           error={errors.mccCode}*/}
          {/*           helperText={errors.mccCode ? errors.mccCode.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}

            {/*<FormControl fullWidth style={{height: '100%'}}>*/}
            {/*  <InputLabel id="demo-simple-select-label">Merchant Category Code</InputLabel>*/}
            {/*  <Controller*/}
            {/*    name="mccCode"*/}
            {/*    control={control} // make sure to get "control" from useForm()*/}
            {/*    defaultValue=""*/}
            {/*    render={({ field }) => (*/}
            {/*      <Select*/}
            {/*        labelId="demo-simple-select-label"*/}
            {/*        id="demo-simple-select"*/}
            {/*        label="Merchant Category Code"*/}
            {/*        fullWidth*/}
            {/*        {...field}*/}
            {/*        error={errors.mccCode}*/}
            {/*        helperText={errors.mccCode ? errors.mccCode.message : ''}*/}
            {/*        style={{height: '100%'}}*/}
            {/*        disabled={!editmode}*/}
            {/*      >*/}
            {/*        <MenuItem value=""> -Select Merchant Category Code- </MenuItem>*/}
            {/*        {requiredInfo?.mcclist && requiredInfo.mcclist.map((item, key) => (*/}
            {/*          <MenuItem key={key} value={item?.merchantCode}>{item?.merchantDescription}</MenuItem>*/}
            {/*        ))}*/}
            {/*      </Select>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</FormControl>*/}

            {/*<MDInput type="text" label="Merchant Category Code" name="merchantCode" value={merchant?.merchant?.merchantCode} fullWidth  readOnly InputLabelProps={{ shrink: true }} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="ABN" name="abn"*/}
          {/*           {...register("abn")}*/}
          {/*           error={errors.abn}*/}
          {/*           helperText={errors.abn ? errors.abn.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="ACN" name="acn"*/}
          {/*           {...register("acn")}*/}
          {/*           error={errors.acn}*/}
          {/*           helperText={errors.acn ? errors.acn.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          <Grid item xs={3}>
            <MDInput type="text" label={t('years_in_business')} name="establishmentYrs"
                     {...register("establishmentYrs")}
                     error={errors.establishmentYrs}
                     helperText={errors.establishmentYrs ? errors.establishmentYrs.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('sales_tax_no')} name="saleTaxNo"
                     {...register("saleTaxNo")}
                     error={errors.saleTaxNo}
                     helperText={errors.saleTaxNo ? errors.saleTaxNo.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('name_of_the_owner')} name="ownerName"
                     {...register("ownerName")}
                     error={errors.ownerName}
                     helperText={errors.ownerName ? errors.ownerName.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('authorized_person_company')} name="authorizedPerson"
                     {...register("authorizedPerson")}
                     error={errors.authorizedPerson}
                     helperText={errors.authorizedPerson ? errors.authorizedPerson.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('smsTablecolumns.mobileNo')} name="mobileNo"
                     {...register("mobileNo")}
                     error={errors.mobileNo}
                     onKeyDown={handleKeyDown}
                     helperText={errors.mobileNo ? errors.mobileNo.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('userTablecolumns.emailId')} name="emailId"
                     {...register("emailId")}
                     error={errors.emailId}
                     helperText={errors.emailId ? errors.emailId.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="FAX" name="faxNo"*/}
          {/*           {...register("faxNo")}*/}
          {/*           error={errors.faxNo}*/}
          {/*           helperText={errors.faxNo ? errors.faxNo.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          <Grid item xs={3}>
            <MDInput type="text" label={t('office_phone')} name="officeNo"
                     {...register("officeNo")}
                     error={errors.officeNo}
                     onKeyDown={handleKeyDown}
                     helperText={errors.officeNo ? errors.officeNo.message : ''}
                     fullWidth  disabled={!editmode} />
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="Tin No" name="tinNo"*/}
          {/*           {...register("tinNo")}*/}
          {/*           error={errors.tinNo}*/}
          {/*           helperText={errors.tinNo ? errors.tinNo.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="Group MID" name="groupMid"*/}
          {/*           {...register("groupMid")}*/}
          {/*           error={errors.groupMid}*/}
          {/*           helperText={errors.groupMid ? errors.groupMid.message : ''}*/}
          {/*           fullWidth disabled={type === "update"} />*/}
          {/*</Grid>*/}
          <Grid item xs={3}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="demo-simple-select-label">{t('reference_company_name')}</InputLabel>
              <Controller
                name="referenceId"
                control={control} // make sure to get "control" from useForm()
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('reference_company_name')}
                    fullWidth
                    {...field}
                    error={errors.referenceId}
                    helperText={errors.referenceId ? errors.referenceId.message : ''}
                    style={{height: '100%'}}
                    disabled={!editmode}
                  >
                    <MenuItem value=""> -{t('select_reference_company')}- </MenuItem>
                    {requiredInfo?.referenceCompany && requiredInfo.referenceCompany.map((item, key) => (
                      <MenuItem key={key} value={item?.referenceId}>{item?.referenceCompanyName}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="demo-simple-select-label">{t('master_type')}</InputLabel>
              <Controller
                name="microAtmStatus"
                control={control} // make sure to get "control" from useForm()
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('master_type')}
                    fullWidth
                    {...field}
                    error={!!errors.microAtmStatus}
                    helperText={errors.microAtmStatus ? errors.microAtmStatus.message : ''}
                    style={{height: '100%'}}
                    disabled={!editmode}
                  >
                    <MenuItem value=""> -{t('select_master_type')}- </MenuItem>
                    {requiredInfo?.microAtm && requiredInfo.microAtm.map((item, key) => (
                      <MenuItem key={key} value={item?.microAtmStatus}>{item?.description}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {/*<MDInput type="text" label="Master Type" name="microAtmStatus" value={merchant?.merchant?.microAtmStatus} fullWidth  readOnly InputLabelProps={{ shrink: true }} />*/}
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="Wallet Balance" name="walletBalance"*/}
          {/*           {...register("walletBalance")}*/}
          {/*           error={errors.walletBalance}*/}
          {/*           helperText={errors.walletBalance ? errors.walletBalance : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <MDInput type="text" label="Wallet Account No." name="walletAccountNo"*/}
          {/*           {...register("walletAccountNo")}*/}
          {/*           error={errors.walletAccountNo}*/}
          {/*           helperText={errors.walletAccountNo ? errors.walletAccountNo.message : ''}*/}
          {/*           fullWidth  disabled={!editmode} />*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
  );
}

MerchantOfficeDetails.defaultProps = {
  merchant: false,
  editmode: false,
  setMerchant: false,
  type: 'update',
  errors: {},
  register: false
}

MerchantOfficeDetails.propTypes = {
  merchant: PropTypes.any,
  editmode: PropTypes.bool,
  setMerchant: PropTypes.func,
  type: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.any
}

export default MerchantOfficeDetails;
