/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import NewUser from "../../users/newUser";
import DataTable from "../../../examples/Tables/DataTable";
import data from "../../dashboard/components/Projects/data";
import MDButton from "../../../components/MDButton";
import { useEffect, useState } from "react";
import NewTerminal from "../terminals/newTerminal";
import Icon from "@mui/material/Icon";
import PermissionControl from "../../../components/Utils/PermissionControl";


function MerchantTerminals(props) {
  const {merchant} = props;
  const [openConfigurator, setOpenConfigurator] = useState(false);
  const [terminals, setTerminals] = useState([]);
  const { terminalTablecolumns } = data();
  const [terminal, setTerminal] = useState({});

  function updateTerminalHandler(item) {
    setOpenConfigurator(true);
    setTerminal(item);
  }

  function handleConfirmDelete(item) {
    return;
  }

  useEffect(() => {
    const modifiedData = merchant.msisdn.map(item => {
      const action = (
          <PermissionControl permission={{ restrict: true, module: "Terminal", subModule: "Update" }}>
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => updateTerminalHandler(item)}>create</Icon>
              {/*<Icon fontSize="small" style={{cursor : 'pointer'}} onClick={() => handleConfirmDelete(item)}>delete</Icon>*/}
            </MDBox>
          </PermissionControl>
      );
      return { ...item, action };
    });
    setTerminals(modifiedData);
  }, [merchant]);

  const onTerminalCloseHandler = () => {
    setTerminal({});
  }

  function addNewTerminal() {
    setTerminal({});
    setOpenConfigurator(true);
  }

  return (
      <Grid mt={3} mb={3} padding={2} bgColor="white">
        <Grid container spacing={2} justifyContent="center">
          <Grid container justifyContent="flex-end">
            <PermissionControl permission={{ restrict: true, module: "Terminal", subModule: "Create" }}>
            <MDButton variant="gradient" onClick={() => addNewTerminal()} color="info" style={{marginBottom: "12px"}}>
              {t('add_terminal')}
            </MDButton>
            </PermissionControl>
          </Grid>
          <DataTable
            table={{ columns : terminalTablecolumns, rows : terminals }}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
            entriesPerPage={true}
            canSearch={true}
          />
        </Grid>
        <NewTerminal openConfigurator={openConfigurator} setOpenConfigurator={setOpenConfigurator} merchant={merchant} terminal={terminal} onTerminalCloseHandler={onTerminalCloseHandler} />
      </Grid>
  );
}

MerchantTerminals.defaultProps = {
  merchant: false
}

MerchantTerminals.propTypes = {
  merchant: PropTypes.any
}

export default MerchantTerminals;
