/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import {isEmpty} from "../../../components/Utils/common";

import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext } from "react-hook-form";
import { Controller } from 'react-hook-form';
import {useTranslation} from "react-i18next";


function MerchantEstablishmentDeclaration({merchant, editmode, setMerchant}) {
  const {t} = useTranslation();
  const { register, formState: { errors }, control } = useFormContext();
  return (
      <Grid mt={3} mb={3} padding={2} bgColor="white">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <FormControl fullWidth style={{height: '100%'}}>
              <InputLabel id="demo-simple-select-label">{t('type_of_pos_machine_required')}</InputLabel>
              <Controller
                name="posMachineType"
                control={control}  // make sure to get "control" from useForm()
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('type_of_pos_machine_required')}
                    error={!!errors.posMachineType}
                    helperText={errors.posMachineType ? errors.posMachineType.message : ''}
                    {...field}
                    fullWidth
                    style={{height: '100%'}}
                    disabled={!editmode}
                    name="posMachineType"
                  >
                    <MenuItem value=""> - {t('select_pos_machine_required')} -</MenuItem>
                    <MenuItem value="PSTN">PSTN</MenuItem>
                    <MenuItem value="GPRS Fixed">GPRS Fixed</MenuItem>
                    <MenuItem value="PC POS">PC POS</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('number_of_pos_required')} name="noPosRequired"
                     error={errors?.noPosRequired}
                     helperText={errors.noPosRequired ? errors.noPosRequired.message : ''}
                     {...register("noPosRequired")}
                     fullWidth  disabled={!editmode} />
          </Grid>
        </Grid>
      </Grid>
  );
}

MerchantEstablishmentDeclaration.defaultProps = {
  merchant: false,
  editmode: false,
  setMerchant: false
}

MerchantEstablishmentDeclaration.propTypes = {
  merchant: PropTypes.any,
  editmode: PropTypes.bool,
  setMerchant: PropTypes.func
}

export default MerchantEstablishmentDeclaration;
