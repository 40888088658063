import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { useMaterialUIController, setLoggedIn } from "context";
const ProtectedRoute = (props) => {
    const [controller, dispatch] = useMaterialUIController();
    const {
        isLoggedIn,
        token,
    } = controller;
    const navigate = useNavigate();
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        if (!token || token === 'undefined') {
            setLoggedIn(dispatch,false);
            return navigate('/login');
        }
        setLoggedIn(dispatch,true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                // eslint-disable-next-line react/prop-types
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;