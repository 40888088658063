import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import ConfiguratorRoot from "../../examples/Configurator/ConfiguratorRoot";
import PropTypes from "prop-types";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import useApi from "../../components/Utils/api";
import toast from "react-hot-toast";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

function NewUser({ openConfigurator, setOpenConfigurator }) {
  const [updatedUser, setUpdatedUser] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    emailId: '',
    dept: '',
    userId: '',
    countryCode: '263',
    role: ''
  });

  // Error states for validation
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [deptError, setDeptError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);

  const api = useApi();

  const newUserFormHandler = (form) => {
    form.preventDefault();
    if (!isValidUserId) return;

    let updateUserReq = updatedUser;

    api.post("/users/create", updateUserReq)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          toast.success("User successfully added.");
          setOpenConfigurator(false);
          setUpdatedUser({
            firstName: '',
            lastName: '',
            mobileNo: '',
            emailId: '',
            dept: '',
            userId: '',
            countryCode: process.env.REACT_APP_COUNTRY_CODE,
            role: ''
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error("You do not have permission to perform this action.", { id: "PermissionError" });
          return;
        }
        console.log(error.response);
        toast.error(error.response.data.body);
      });
  };

  const isValidUserId = /^[A-Za-z0-9]{6,10}$/.test(updatedUser?.userId);


  // Handle change and validation for first name
  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setUpdatedUser({ ...updatedUser, firstName: value });
    setFirstNameError(value.length > 20);
  };

  // Handle change and validation for last name
  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setUpdatedUser({ ...updatedUser, lastName: value });
    setLastNameError(value.length > 20);
  };

  // Handle change and validation for email
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUpdatedUser({ ...updatedUser, emailId: value });
    setEmailError(value.length > 50);
  };

  // Handle change and validation for department
  const handleDeptChange = (e) => {
    const value = e.target.value;
    setUpdatedUser({ ...updatedUser, dept: value });
    setDeptError(value.length > 20);
  };

  // Handle change and validation for mobile number (digits only, max length 20)
  const handleMobileNoChange = (e) => {
    const value = e.target.value;
    const digitsOnly = /^[0-9]*$/.test(value);
    setUpdatedUser({ ...updatedUser, mobileNo: value });
    setMobileNoError(!digitsOnly || value.length > 20);
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} configuratorwidth={500}>
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MDBox>
          <MDTypography variant="h5">New User</MDTypography>
          <MDTypography variant="body2" color="text">
            {updatedUser?.userId}
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(false)}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox component="form" role="form" onSubmit={(form) => newUserFormHandler(form)} style={{ maxWidth: '480px' }}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Username"
            name="username"
            onChange={(e) => setUpdatedUser({ ...updatedUser, userId: e.target.value.toUpperCase() })}
            value={updatedUser?.userId}
            pattern="^[A-Za-z0-9]+$"
            title="Please enter alphanumeric characters only."
            minLength={6}
            maxLength={10}
            success={isValidUserId}
            error={!isValidUserId}
            fullWidth
            required
          />
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" mb={2}>
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput
              type="text"
              label="First Name"
              name="first_name"
              onChange={handleFirstNameChange}
              value={updatedUser?.firstName}
              fullWidth
              required
              error={firstNameError}
              helperText={firstNameError ? "First Name cannot exceed 20 characters" : ""}
            />
          </MDBox>

          <MDBox flex={1} boxSizing={'border-box'}>
            <MDInput
              type="text"
              label="Last Name"
              name="last_name"
              onChange={handleLastNameChange}
              value={updatedUser?.lastName}
              fullWidth
              required
              error={lastNameError}
              helperText={lastNameError ? "Last Name cannot exceed 20 characters" : ""}
            />
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" mb={2}>
          <MDBox flex={4} boxSizing={'border-box'} mr={1}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              onChange={handleEmailChange}
              value={updatedUser?.emailId}
              fullWidth
              required
              error={emailError}
              helperText={emailError ? "Email cannot exceed 50 characters" : ""}
            />
          </MDBox>

            <MDBox flex={1} boxSizing={'border-box'}>
            <FormControl fullWidth style={{ height: '100%' }}>
              <InputLabel id="label-role">Select User Role</InputLabel>
              <Select
                  labelId="label-role"
                  id="label-role"
                  label="Select User Role"
                  fullWidth
                  style={{ height: '100%' }}
                  name="role"
                  onChange={(e) => setUpdatedUser({ ...updatedUser, role: e.target.value })}
                  value={updatedUser?.role}
              >
                <MenuItem value="">-Select user level-</MenuItem>
                <MenuItem value="MAKER">Admin</MenuItem>
                <MenuItem value="REPORTING_ADMIN">Reporting Admin</MenuItem>
                <MenuItem value="SETTLEMENT_ADMIN">Settlement Admin</MenuItem>
                <MenuItem value="ONBOARDING_ADMIN">Onboarding Admin</MenuItem>
                <MenuItem value="ONBOARDING_REPORTING_ADMIN">Onboarding & Reporting Admin</MenuItem>
                <MenuItem value="G_MAKER">Guest Admin</MenuItem>
              </Select>
            </FormControl>
            {/*<MDInput type="text" label="Role" name="role" value={user.role === "ADMIN" ? 'MAKER' : user.role === "SUPERADMIN" ? 'ADMIN' : ''} fullWidth disabled />*/}
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" mb={2}>
          <MDBox flex={1} boxSizing={'border-box'} mr={1}>
            <MDInput
              type="tel"
              label="Mobile No"
              name="mobileNo"
              onChange={handleMobileNoChange}
              value={updatedUser?.mobileNo}
              fullWidth
              required
              error={mobileNoError}
              helperText={mobileNoError ? "Mobile number must be digits only and max 20 characters" : ""}
            />
          </MDBox>

          <MDBox flex={1} boxSizing={'border-box'}>
            <MDInput
              type="text"
              label="Department"
              name="dept"
              onChange={handleDeptChange}
              value={updatedUser?.dept}
              fullWidth
              required
              error={deptError}
              helperText={deptError ? "Department cannot exceed 20 characters" : ""}
            />
          </MDBox>
        </MDBox>

        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" style={{ float: 'right' }}>
            Add
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

NewUser.defaultProps = {
  openConfigurator: false,
  setOpenConfigurator: false,
};

NewUser.propTypes = {
  openConfigurator: PropTypes.bool,
  setOpenConfigurator: PropTypes.func,
};

export default NewUser;
