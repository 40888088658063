/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import {isEmpty} from "../../../components/Utils/common";
import { useFormContext } from "react-hook-form";
import {FormHelperText, InputLabel} from "@mui/material";
import {Input} from "rsuite";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import MDTypography from "../../../components/MDTypography";
import Typography from "@mui/material/Typography";
import useApi from "../../../components/Utils/api";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";


function MerchantOtherDetails({merchant, editmode, setMerchant}) {
  const {t} = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  const api = useApi();
  const [files, setFiles] = useState(merchant.merchantFiles.length > 0 ? merchant.merchantFiles : []);

  const downloadFile = (file) => {
    api.get("merchant/file/"+file.id, {
      responseType: 'blob'
    })
    .then(response => {
      // Create a Blob from the file data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create an Object URL from the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;

      // Set the download attribute with a filename
      link.setAttribute('download', file.fileName); // Replace with actual filename and extension

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    })
    .catch(error => {
      console.log(error);
    });
  }

  const deleteFile = (file) => {
    console.log(merchant);
    api.delete("merchant/file/"+file.id)
        .then(response => {
          if (response.data.statusCodeValue === 200) {
            const fileIdToDelete = file.id;
            setFiles(prevFiles => prevFiles.filter(file => file.id !== fileIdToDelete));
            toast.success(t('file')+": "+file.fileName+" "+t('deleted_successfully'));
          }
        })
        .catch(error => {
          console.log(error);
        });

  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
    verticalAlign: 'middle',
    display: 'inline-flex'
  }));

  return (
      <Grid mt={3} mb={3} padding={2} bgColor="white">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <MDInput type="text" label={t('max_transaction_amount')} name="amountPerTrans"
                     error={errors?.amountPerTrans}
                     helperText={errors.amountPerTrans ? errors.amountPerTrans.message : ''}
                     {...register("amountPerTrans")}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('max_total_value_per_day')} name="allowedTotAmtPerDay"
                     error={errors?.allowedTotAmtPerDay}
                     helperText={errors.allowedTotAmtPerDay ? errors.allowedTotAmtPerDay.message : ''}
                     {...register("allowedTotAmtPerDay")}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('max_no_transactions_per_day')} name="allowedTransPerDay"
                     error={errors?.allowedTransPerDay}
                     helperText={errors.allowedTransPerDay ? errors.allowedTransPerDay.message : ''}
                     {...register("allowedTransPerDay")}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('bank_fee')} name="bankFee"
                     error={errors?.bankFee}
                     helperText={errors.bankFee ? errors.bankFee.message : ''}
                     {...register("bankFee")}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            <MDInput type="text" label={t('comission_fee')} name="issuerFee"
                     error={errors?.issuerFee}
                     helperText={errors.issuerFee ? errors.issuerFee.message : ''}
                     {...register("issuerFee")}
                     fullWidth  disabled={!editmode} />
          </Grid>
          <Grid item xs={3}>
            {/*<InputLabel htmlFor="fileInput">Upload File</InputLabel>*/}
            <TextField
                type="file"
                id="supportingDocuments"
                accept=".pdf, .png, .jpeg, .jpg, .xlsx"
                label={t('supporting_documents')}
                {...register("supportingDocuments")}
                fullWidth
                disabled={!editmode}
                error={errors?.supportingDocuments}
                helperText={errors.supportingDocuments ? errors.supportingDocuments[0].message : t('related_documents_message')}
                inputProps={{
                  multiple: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {merchant.merchantFiles.length > 0 ? (
                <Stack spacing={2}>
                  {merchant.merchantFiles.map((file, index) => <Item key={index}>
                    <AttachFileIcon sx={{ fontSize: '1rem !important' }} />
                    <Typography fontSize={14} style={{ width: '80%' }}>{file.fileName}</Typography>
                    <DownloadIcon sx={{ fontSize: '1rem !important', float: 'right', cursor: 'pointer' }} onClick={() => downloadFile(file)} />
                    <DeleteIcon sx={{ fontSize: '1rem !important', float: 'right', cursor: editmode ? 'pointer' : 'not-allowed' }} onClick={editmode ? () => deleteFile(file) : null} />
                  </Item> )}
                </Stack>
            ) : <></>}
            {/*{errors?.file && <FormHelperText error>{errors.file.message}</FormHelperText>}*/}
          </Grid>
        </Grid>
      </Grid>
  );
}

MerchantOtherDetails.defaultProps = {
  merchant: false,
  editmode: false,
  setMerchant: false
}

MerchantOtherDetails.propTypes = {
  merchant: PropTypes.any,
  editmode: PropTypes.bool,
  setMerchant: PropTypes.func
}

export default MerchantOtherDetails;
