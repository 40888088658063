/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";
import { useFormContext } from 'react-hook-form';

const MDInput = forwardRef(({ error, success, disabled, name, ...rest }, ref) => {
  const { watch } = useFormContext() || { watch: () => {} };
  const value = watch(name, '');

  return (
    <MDInputRoot
      {...rest}
      ref={ref}
      name={name}
      ownerState={{ error, success, disabled }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        shrink: value || value === 0 ? true : undefined
      }}
    />
  );
});

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};


export default MDInput;
